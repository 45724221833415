import { Injectable } from '@angular/core';
import { MessageService as PrimeMessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private messageService: PrimeMessageService) { }

  /**
   * Shows a success message
   * @param message
   * @param summary
   */
  success(message: string, summary = 'Success') {
    this.messageService.add({ severity: 'success', summary, detail: message });
  }

  /**
   * Shows an info message
   * @param message
   * @param summary
   */
  info(message: string, summary = 'Info') {
    this.messageService.add({ severity: 'info', summary, detail: message });
  }

  /**
   * Shows a warning message
   * @param message
   * @param summary
   */
  warn(message: string, summary = 'Warn') {
    this.messageService.add({ severity: 'warn', summary, detail: message });
  }

  /**
   * Shows an error message
   * @param message
   * @param summary
   */
  error(message: string, summary = 'Error') {
    this.messageService.add({ severity: 'error', summary, detail: message });
  }

  /**
   * Clears all messages
   */
  clear() {
    this.messageService.clear();
  }

}
