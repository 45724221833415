import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeService } from 'ngx-stripe';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { IPaymentMethod } from 'src/app/chatperk-core/interfaces';
import { PaymentMethodsService } from 'src/app/applications/dashboard/modules/space/services/payment-methods.service';
import { IUpdatePaymentMethodDto } from 'src/app/shared/payment-method/interfaces/payment-method.interfaces';

@Component({
  selector: 'app-update-payment-method',
  templateUrl: './update-payment-method.component.html',
  styleUrls: ['./update-payment-method.component.scss'],
})
export class UpdatePaymentMethodComponent implements OnInit {
  private destroy$ = new Subject<void>();
  paymentMethod!: IPaymentMethod;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentMethodsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    public stripe: StripeService
  ) {}

  ngOnInit(): void {
    this.loadResolvedData();
  }

  loadResolvedData() {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.paymentMethod = data['paymentMethod'];
    });
  }

  updatePaymentMethod(updates: IUpdatePaymentMethodDto) {
    this.paymentService.update(this.paymentMethod.id, updates).subscribe({
      next: () => {
        this.messageService.success(
          this.translateService.instant('paymentMethod.updatedSuccessfully'),
          this.translateService.instant('messages.done')
        );
        this.navigateToListPage();
      },
    });
  }

  deletePaymentMethod() {
    this.paymentService.delete(this.paymentMethod.id).subscribe({
      next: () => {
        this.messageService.success(
          this.translateService.instant('paymentMethod.deletedSuccessfully'),
          this.translateService.instant('messages.done')
        );
        this.navigateToListPage();
      },
    });
  }

  navigateToListPage() {
    this.router.navigateByUrl('/space/settings/billing');
  }
}
