import { Component, forwardRef, Host, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { CustomInput } from '../../../../../classes/custom-input';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import {SxwFormComponent} from "src/app/kernel/form/components/sxw-form/sxw-form.component";

@Component({
  selector: 'sxw-slider-input',
  templateUrl: './slider-input.component.html',
  styleUrls: ['./slider-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderInputComponent),
      multi: true,
    }
  ],
})
export class SliderInputComponent extends CustomInput<number> implements OnChanges {
  override value = 0;
  @Input() step = 1;
  @Input() minValue = 0;
  @Input() maxValue = 10;
  @Input() range = false;
  @Input() sliderClass = '';
  constructor(@Optional() @Host() public sxwForm?: SxwFormComponent) {
    super();
  }

  ngOnChanges(_: SimpleChanges) {
    if(this.value < this.minValue) {
      this.updateValue(this.minValue);
    }
    if(this.value > this.maxValue) {
      this.updateValue(this.maxValue);
    }
  }

  private updateValue(value: number) {
    this.value = value;
    this.onChange(this.value);
  }

  override writeValue(value: any) {
    if (!value) {
      this.value = 0;
      setTimeout(() => this.onChange(this.value));
    } else if (value <= this.maxValue && value >= this.minValue) {
      this.value = value;
    } else {
      this.value =
        Math.abs(value - this.minValue) < Math.abs(value - this.maxValue)
          ? this.minValue
          : this.maxValue;
    }
  }
}
