import { ValidatorsOptionsInterface } from "../../interfaces/validators-options.interface";

export function isAfterTime(
  value: string,
  time: string,
  options?: ValidatorsOptionsInterface
): Record<string, ValidatorsOptionsInterface> | null {
  if (!time || !value) {
    return null;
  }
  const timeAsNumber = parseInt(time.replace(":", ""));
  const valueAsNumber = parseInt(value.replace(":", ""));
  return valueAsNumber > timeAsNumber
    ? null
    : {
        isAfterTime: {
          message: options?.message || "isAfterTime",
          comparisonFieldName: options?.comparisonFieldName || "",
        },
      };
}
