import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { take, map } from 'rxjs';
import { GraphService } from '../../../../../../graphql/services/graph.service';

export function isIban(
  options: { swiftController: string },
  graph: GraphService
): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return graph
      .constructQuery(['valid', 'swiftCode'], 'validateIbanCode', {
        iban: control.value,
      })
      .pipe(
        take(1),
        map((data: any) => {
          const queryData = data.data.validateIbanCode;
          if (queryData.valid) {
            options.swiftController &&
              control.parent
                ?.get(options.swiftController)
                ?.setValue(queryData.swiftCode);
            return null;
          }
          options.swiftController && control.parent
                ?.get(options.swiftController)
                ?.setValue(null);
          return { isIban: 'isIban' };
        })
      );
  };
}
