<div class="progress-bar-container" [ngClass]="{thin}">
  <ng-container *ngIf="mode === 'indeterminate'">
    <div class="progress-bar indeterminate-progress-bar">
      <div class="indeterminate-animation"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="mode === 'determinate'" >
    <div class="progress-bar determinate-progress-bar"  [ngStyle]="{'width':  progress + '%'}"></div>
  </ng-container>

</div>
