import { Directive, OnDestroy, inject, Injector, Input } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
} from "@angular/forms";

@Directive()
export abstract class CustomInput<T = any>
  implements ControlValueAccessor, OnDestroy
{
  /**
   * Holds the current value of the slider
   */
  value!: T | undefined | null;
  @Input() readonly = false;
  @Input() disabled = false;

  private injector = inject(Injector);
  private _ngControl?: NgControl | null;
  private _outsourceControl?: AbstractControl | null;

  get ngControl() {
    return (
      this._ngControl ?? (this._ngControl = this.injector.get(NgControl, null))
    );
  }

  get control() {
    return this._outsourceControl ?? this.ngControl?.control;
  }

  set control(control) {
    this._outsourceControl = control;
  }

  get summaryValue(): any {
    if (this.value == undefined) {
      return "";
    }
    return `${this.value}`;
  }

  /**
   * Invoked when the model has been changed
   * **TODO:** refactor this to make it accept **T** generic that passed to **CustomInput**
   */
  onChange: <C = any>(_: C) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  /**
   * Writes a new value to the element.
   * @param value the value
   */
  writeValue(value: T): void {
    this.value = value;
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  ngOnDestroy(): void {
    setTimeout(() => this.control?.updateValueAndValidity());
  }
}
