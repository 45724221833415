<sxw-popup-wrapper></sxw-popup-wrapper>
<app-top-nav></app-top-nav>
<div class="dashboard_grid">
  <div class="side_nav_container">
    <app-sidenav [sideNavConfig]="sideNavConfig"></app-sidenav>
  </div>
  <div class="content" #content>
    <div class="body-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
