<sxw-side-dialog
  [autoZIndex]="false"
  [baseZIndex]="1000"
  outletName="popups"
  theme="sxw-popup-cards"
>
  <h2>{{ "paymentMethod.addTitle" | translate }}</h2>
  <app-add-payment-method-form
    [clientSecret]="clientSecret"
    (afterSubmitSuccess)="savePaymentMethod($event)"
    [defaultBillingInfo]="myBillingAccount"
  ></app-add-payment-method-form>
</sxw-side-dialog>
