<span class="{{ theme }} ">
  <label>{{ label | translate }}</label>
  <p-inputNumber
    #numberInput
    inputId="horizontal"
    decrementButtonClass="p-button-danger"
    incrementButtonClass="p-button-success"
    incrementButtonIcon="pi pi-plus"
    decrementButtonIcon="pi pi-minus"
    [maxFractionDigits]="maxFractionDigits"
    [placeholder]="placeholder | translate"
    [showButtons]="enableShowButtons"
    [buttonLayout]="enableButtonLayout"
    [locale]="localType"
    [mode]="modeType"
    [step]="stepValue"
    [min]="minValue"
    [max]="maxValue"
    [currency]="currencyType"
    [currencyDisplay]="currencyDisplay"
    [useGrouping]="useGrouping"
    [(ngModel)]="value"
    (onBlur)="onTouched()"
    (onInput)="onInputChanged($event)"
    [disabled]="disabled"
    [readonly]="readonly"
    [ngClass]="{
      'invalid-input':
        control && control.errors && (control.touched || sxwForm?.submitted)
    }"
    [suffix]="suffix | translate"
    [prefix]="prefix | translate"
    [minFractionDigits]="minFractionDigits"
    class="full-width-input"
    [maxlength]="maxLength"
  ></p-inputNumber>
  <sxw-error-message
    *ngIf="control && control.errors && (control.touched || sxwForm?.submitted)"
    [message]="control ? control.errors : null"
  ></sxw-error-message>
</span>
