<sxw-dropdown-input
  ngDefaultControl
  inputId="basic"
  [(ngModel)]="value"
  (onOverlayClosedEvent)="onTouched()"
  (onDropDownChange)="onValueChange($event)"
  [options]="time"
  [isFilter]="isFilter"
  [filterMatchMode]="filterMatchMode"
  [optionLabel]="'name'"
  [optionValue]="'value'"
  [theme]="theme"
  [ngClass]="{
    'invalid-input':
      control &&
      control.errors &&
      (control.touched || sxwForm?.submitted)
  }"
  styleClass="full-width-input"
  [placeholder]="placeholder"
  [readonly]="readonlyInput || readonly || disabled"
  [showClear]="showClear"
>
</sxw-dropdown-input>
<sxw-error-message
  *ngIf="
    control &&
    control.errors &&
    (control.touched || sxwForm?.submitted)
  "
  [message]="control ? control.errors : null"
></sxw-error-message>
