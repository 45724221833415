export const PAYMENT_METHOD_FULL_DATA_SHAPE = `
  id
  type
  isPrimary
  isActive
  billingAccount {
    id
    createdAt
    updatedAt
    firstName
    lastName
    orgName
    taxId
    email
    mobile
  }
  billingInfo {
    firstName
    lastName
    orgName
    taxId
    email
    mobile
    address {
      country
      city
      state
      zip
      line1
      line2
    }
  }
  card {
    brand
    last4
    expMonth
    expYear
    funding
    country
    fingerprint
    has3DSecureSupported
    source
  }
  paypal {
    payerId
    payerEmail
    country
  }
  externalRef {
    refId
    provider
  }
`;
