import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { PaymentMethodsService } from '../services/payment-methods.service';
import { map } from 'rxjs';

export const getAllPaymentMethodsResolver: ResolveFn<any> = (route) => {
  const router = inject(Router);
  const passedPaymentMethods =
    router.getCurrentNavigation()?.extras.state?.['paymentMethods'];

  if (passedPaymentMethods) return passedPaymentMethods;

  const paymentMethodService = inject(PaymentMethodsService);
  const options = route.data['getAllPaymentMethodsOptions'];
  const dataShape = options?.['dataShape'];
  return paymentMethodService
    .getAll(dataShape)
    .pipe(map((res) => res.data['allPaymentMethods']));
};
