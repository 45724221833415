import {AppInfoStateInterface} from "src/app/core/root-store/root.state";
import {createReducer, on} from "@ngrx/store";
import {appInitialActions} from "src/app/core/root-store/root.actions";

export const initialAppInfoState: AppInfoStateInterface = {
  version: '0.0.0',
  connected: -1
}

export const appInfoStateReducers = createReducer(
  initialAppInfoState,
  on(appInitialActions.appStarted, ((state, { version }) => ({...state, version: version}))),
  on(appInitialActions.appConnected, ((state) => ({...state, connected: 1}))),
  on(appInitialActions.appConnectionFailed, ((state) => ({...state, connected: 0})))
)
