import { LocalStorageService } from "src/app/kernel/tools/services/local-storage.service";
import {inject, Injectable} from "@angular/core";
import { BehaviorSubject, first, map } from "rxjs";
import { AuthUserModel } from "../models/AuthUser.model";
import {
  IUserTokenType,
  IUser,
  ILoginDto,
  IObtainOAuthOpenId,
  IAccessTokenPayload,
} from "../interfaces";
import { GraphService } from "src/app/kernel/graphql/services/graph.service";
import { Router } from "@angular/router";
import { ICustomerRegisterDTO, ICustomerRegisterOAuthDTO } from "../interfaces/customerRegister.interface";
import { IMessage } from "../interfaces/message.interface";
import { EAccessType, EOAuthTarget, EOtpReason, EUserType } from 'src/app/chatperk-core/enums';
import { GqlResponseInterface } from "src/app/kernel/graphql/interfaces/gql-response.interface";
import { USER_DATASHAPE, USER_TOKEN_DATASHAPE } from "../data-shape";
import {UsageOverviewService} from "src/app/shared/metrics-shared/services/usage-overview.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { SubscriptionService } from "src/app/shared/subscription-shared/services/subscription.service";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  public user$ = new BehaviorSubject<AuthUserModel | undefined>(undefined);
  public token$ = new BehaviorSubject<string | undefined>(undefined);

  userProfileShape: string[] = [USER_DATASHAPE];

  userTokenTypeSelection: string[] = [USER_TOKEN_DATASHAPE];

  rolesShape: string[] = [
    "data.id",
    "data.name",
    "data.code",
    "data.permissions"
  ];

  constructor(
    private graphService: GraphService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private usageOverviewService: UsageOverviewService,
    private jwtHelperService: JwtHelperService,
  ) {
    this.setRawUser(this.localStorageService.get<IUser>("user"));
    this.setToken(this.localStorageService.get<string>("token") ?? undefined);
  }

  storeRedirectURL(url: string) {
    this.localStorageService.set('redirectURL' , {url, timestamp: new Date().getTime()});
  }

  hasRedirectURL(): boolean {
    const redirectURL = this.localStorageService.get<{url: string, timestamp: number}>('redirectURL');
    return Boolean(redirectURL?.timestamp) && (new Date().getTime() < (redirectURL?.timestamp ?? 0) + 60 * 60 * 1000) ;
  }

  popRedirectURL(): string {
    const url = this.localStorageService.get<{url: string, timestamp: number}>('redirectURL') ;
    this.localStorageService.remove('redirectURL');
    return url?.url ?? '/';
  }

   /**
   * login action
   * @param ILoginDto
   */
  login(loginData:ILoginDto) {
    return this.graphService
      .constructMutation<{ login: IUserTokenType }>(
        "login",
        { credentials: "LoginCredentialsInput!" },
        { credentials: loginData },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.login }))
      );
  }

     /**
   * verify2fa action
   * @param otp : String
   */
    verify2FA(otp:string) {
      return this.graphService
        .constructMutation<{ verify2fa: IUserTokenType }>(
          "verify2fa",
          { otp: "String!" },
          { otp: otp },
          this.userTokenTypeSelection
        )
        .pipe(
          first(),
          map((res) => ({ ...res, data: res.data?.verify2fa }))
        );
    }

  /**
   * customer register action
   * @param ICustomerRegisterDTO
   */
  customerRegister(input: ICustomerRegisterDTO){
    return this.graphService
    .constructMutation<{ registerCompany: IMessage }>(
      "registerCustomer",
      { input: "RegisterCustomerInput" },
      { input },
      ["code","text"]
    )
    .pipe(
      first(),
      map((res) => ({ ...res, data: res.data?.registerCompany }))
    );
  }

  /**
   * activiate customer account action
   * @param string
   */
  activateCustomerAccount(secret: string){
    return this.graphService
      .constructMutation<{ activateCustomerAccount: IUserTokenType }>(
        "activateCustomerAccount",
        { secret: "String" },
        { secret },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.activateCustomerAccount }))
      );
  }

    /**
   * init Forget Password Flow action
   * @param string
   */
  initForgetPasswordAction(input: any){
    return this.graphService
      .constructMutation<{ initiateResetPassword: IMessage }>(
        "initiateResetPassword",
        { input: "InitiateResetPasswordInput"},
        { input },
        ["code","text"]
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.initiateResetPassword }))
      );
  }

    /**
   * init Forget Password Flow action
   * @param string
   */
  verifyResetPasswordAction(input: any){
    return this.graphService
      .constructMutation<{ verifyResetPassword: IUserTokenType }>(
        "verifyResetPassword",
        { input: "VerifyResetPasswordInput"},
        { input },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.verifyResetPassword }))
      );
  }

  /**
   * init Forget Password Flow action
   * @param string
   */
  confirmResetPasswordAction(password: string){
    return this.graphService
      .constructMutation<{ confirmResetPassword: IUserTokenType }>(
        "confirmResetPassword",
        { password: "String!" },
        { password },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.confirmResetPassword }))
      );
  }

  /**
   * init Oauth Request action
   * @param target
   */
  initiateOAuth(target: EOAuthTarget) {
    return this.graphService
      .constructMutation<{ initiateOAuth: { authUrl: string } }>(
        "initiateOAuth",
        { target: "EOAuthTarget!" },
        { target },
        ["authUrl"]
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.initiateOAuth }))
      );
  }

  /**
   * obtain OAuth Date action
   * @param target
   */
  obtainOAuthOpenId(input: { code: string; state: string; target: string }) {
    return this.graphService
      .constructMutation<{ obtainOAuthOpenId: IObtainOAuthOpenId }>(
        "obtainOAuthOpenId",
        { input: "ObtainOAuthOpenIdInput!" },
        { input },
        [
          "idToken",
          "openIdData.email",
          "openIdData.emailVerified",
          "openIdData.id",
          "openIdData.name",
          "openIdData.surname",
          "openIdData.profilePicture",
          "userIsExists",
        ]
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.obtainOAuthOpenId }))
      );
  }

  /**
   * Register customer with OAuth action
   * @param target
   */
  oAuthCustomerRegister(input: ICustomerRegisterOAuthDTO) {
    return this.graphService
      .constructMutation<{ oAuthRegisterCustomer: IUserTokenType }>(
        "oAuthRegisterCustomer",
        { input: "OAuthRegisterCustomerInput!" },
        { input },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.oAuthRegisterCustomer }))
      );
  }

  /**
   * login with OAuth action
   * @param target
   */
  oAuthLogin(input: { idToken: string; target: EOAuthTarget }) {
    return this.graphService
      .constructMutation<{ oAuthLogin: IUserTokenType }>(
        "oAuthLogin",
        { input: "OAuthCredentialsInput!" },
        { input },
        this.userTokenTypeSelection
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.oAuthLogin }))
      );
  }

    /**
   * Get My Profile action
   * @param target
   */
  getMyProfile() {
    return this.graphService
        .constructQuery<{ myAccount: IUser }>(this.userProfileShape, 'myAccount')
        .pipe(
            first(),
            map((res: any) => ({ ...res, data: res.data.myAccount }))
      );
  }

  /**
   * Sets the current authenticated user.
   * @param user
   */
  setUser(user: AuthUserModel | undefined): AuthService {
    this.user$.next(user);
    return this;
  }

  /**
   * Returns the current authenticated user.
   */
  getUser(): AuthUserModel | undefined {
    return this.user$.value;
  }

  /**
   * Sets the user auth token.
   * @param token
   */
  setToken(token: string | undefined): AuthService {
    this.token$.next(token);
    return this;
  }

  /**
   * Returns the user auth token.
   */
  getToken(): string | undefined {
    return this.token$.value;
  }

  /**
   * Returns the user auth token.
   */
  getParsedToken(): IAccessTokenPayload | undefined | null {
    return this.jwtHelperService.decodeToken<IAccessTokenPayload>(this.token$.value as string);
  }

  clearUser(): AuthService {
    this.localStorageService.remove("user").remove("token");
    this.setUser(undefined).setToken(undefined);
    return this;
  }

  logout(): AuthService {
    this.clearUser();
    return this;
  }

  setRawUser(user: IUser | null): AuthService {
    this.user$.next(user ? AuthUserModel.create(user) : undefined);
    return this;
  }

  updateUserAndToken(user: IUser | null, token: string) {
    this.setToken(token);
    this.localStorageService.set("token", token);
    this.updateUser(user)
  }

  updateUser(user: IUser | null) {
    this.localStorageService.set("user", user);
    if (user && this.getCurrentTokenType() == EAccessType.FAT && user.activeSpace) {
      this.subscriptionService.loadActiveSubscription().subscribe();
      this.usageOverviewService.loadUsageOverview().subscribe()
    }
    this.setRawUser(user);
  }

  getRoles(){
    return this.graphService
    .constructQuery<GqlResponseInterface<any>>(this.rolesShape, 'customerRoles')
    .pipe(map(v => v.data['customerRoles']['data'])
  );
  }

  updateEmail(email: any) {
    return this.graphService
      .constructMutation<{ updateMyEmail: IMessage }>(
        'updateMyEmail',
        { email: 'String' },
        { email },
        ["code","text"]
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.updateMyEmail }))
      );
  }

  updateMobile(mobile: any) {
    return this.graphService
      .constructMutation<{ updateMyMobile: IMessage }>(
        'updateMyMobile',
        { mobile: 'String' },
        { mobile },
        ["code","text"]
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.updateMyMobile }))
      );
  }

  resendOtp(reason: EOtpReason) {
    return this.graphService
      .constructMutation<{ resendOtp: { text: string; code: number } }>(
        "resendOtp",
        { reason: "EOtpReason!" },
        { reason: reason },
        ["text", "code"]
      )
      .pipe(
        first(),
        map((res) => ({ ...res, data: res.data?.resendOtp }))
      );
  }

  verifyContactMethod(otp: string) {
    return this.graphService.constructMutation<{verifyContactMethod: IUser}>(
      'verifyContactMethod',
      {otp: 'String!'},
      {otp},
      [USER_DATASHAPE]
    )
  }

  updateMyPassword(passwords: {currentPassword: string, newPassword: string}) {
    return this.graphService.constructMutation<{updateMyPassword: IUser}>(
      'updateMyPassword',
      {passwords: 'UpdatePasswordInput!'},
      {
        passwords
      },
      [USER_DATASHAPE]
    )
  }

  enable2FA() {
    return this.graphService.constructMutation<{ enable2fa:  { text: string; code: number } }>(
      'enable2fa',
      {},
      {},
      ["text", "code"]
    ).pipe(
      first(),
      map((res) => ({ ...res, data: res.data?.enable2fa }))
    );
  }

  disable2FA() {
    return this.graphService.constructMutation<{ disable2fa: IUser }>(
      'disable2fa',
      {},
      {},
      [USER_DATASHAPE]
    ).pipe(
      first(),
      map((res) => ({ ...res, data: res.data?.disable2fa }))
    );
  }

  verifyEnable2FA(otp: string) {
    return this.graphService.constructMutation<{verifyEnable2fa: IUser}>(
      'verifyEnable2fa',
      {otp: 'String!'},
      {otp},
      [USER_DATASHAPE]
    ).pipe(
      first(),
      map((res) => ({ ...res, data: res.data?.verifyEnable2fa }))
    );
  }

  getCurrentTokenType(){
    let parsedToken = this.jwtHelperService.decodeToken(
      this.getToken() as string
    ) as IAccessTokenPayload;
    return parsedToken?.accessType;
  }

}
