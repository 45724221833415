import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { CircularProgressNumberComponent } from './components/circular-progress-number/circular-progress-number.component';

@NgModule({
  declarations: [ProgressBarComponent, CircularProgressNumberComponent],
  imports: [CommonModule, ProgressBarModule],
  exports: [ProgressBarComponent, CircularProgressNumberComponent],
})
export class SpinnerModule {}
