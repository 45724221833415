import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[sxwRestrictInput]',
})
export class RestrictInputDirective {
  @Input() sxwRestrictInput!: string;
  private restrictions!: RegExp;

  constructor(private el: ElementRef, private formControl: NgControl) {}
  ngOnInit() {
    if (this.sxwRestrictInput) {
      this.restrictions = new RegExp(this.sxwRestrictInput, 'g');
    }
  }
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (this.restrictions && !!event.key.trim().match(this.restrictions)?.length) {
      event.preventDefault();
    }
  }
  // Handle copy and paste operation
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData!.getData('text');
    // Get the previous value of the input
    const prevValue = (event.target as HTMLInputElement).value;
    if (
      this.restrictions &&
      // (this.restrictions.test(pastedText.trim()) || /\s/g.test(pastedText))
      !!pastedText.trim().match(this.restrictions)?.length
    ) {
      event.preventDefault();
      // Set the form control value and keep the previously santazied value for better user experience
      this.formControl.control?.setValue(
        // prevValue + pastedText.replace(/\s/g, '')
        prevValue + pastedText.replace(this.restrictions, '')
      );
    }
  }
}
