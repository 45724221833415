import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ValidatorsOptionsInterface } from "../../../validators/interfaces/validators-options.interface";

@Component({
  selector: "sxw-error-message",
  templateUrl: "./form-error-message.component.html",
  styleUrls: ["./form-error-message.component.scss"],
})
export class FormErrorMessageComponent implements OnChanges {
  @Input() message: any;
  comparisonFieldName = "";
  @Input() customStyles!: { [key: string]: string };
  @Input() theme: string = 'sxw-theme';
  /**
   * If the message is an array, we join it with a new line
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    const message: ValidatorsOptionsInterface | string =
      changes["message"]?.currentValue;
    const messageValue = Object.values(message)[0];
    if (message && typeof messageValue === "object") {
      this.message = `errors.validation.${
        messageValue?.message || Object.values(message)[0]
      }`;
      this.comparisonFieldName = messageValue?.comparisonFieldName || "";
    } else {
      this.message = `errors.validation.${Object.values(message)[0]}`;
    }
  }
}
