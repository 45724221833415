<div class="saved-payment-section">
  <sxw-radio
    *ngIf="paymentMethods?.length && control; else notSelectableTemp"
    labelKey="id"
    [items]="paymentMethods"
    [ngModel]="value"
    (ngModelChange)="handleSelectionChange($event)"
  >
    <ng-template let-paymentMethod let-isSelected="isSelected">
      <div class="item selectable-item">
        <app-payment-method-item
          [paymentMethod]="paymentMethod"
          [showViewButton]="showViewButton"
          [viewButtonIcon]="viewButtonIcon"
          (onViewPaymentMethod)="viewPaymentMethod($event)"
        >
        </app-payment-method-item>
        <span class="radio-btn" [class.selected]="isSelected"></span>
      </div>
    </ng-template>
  </sxw-radio>

  <ng-template #notSelectableTemp>
    <app-payment-method-item
      *ngFor="let paymentMethod of paymentMethods; let i = index"
      [paymentMethod]="paymentMethod"
      [showViewButton]="showViewButton"
      [viewButtonIcon]="viewButtonIcon"
      (onViewPaymentMethod)="viewPaymentMethod($event)"
      class="item"
    >
    </app-payment-method-item>
  </ng-template>

  <p *ngIf="!paymentMethods?.length">
    {{ "paymentMethod.noAvailablePaymentMethods" | translate }}
  </p>
</div>

<button
  *ngIf="showAddButton"
  (click)="openAddPaymentMethod()"
  class="save-button"
>
  {{ addButtonText || "paymentMethod.buttons.addNew" | translate }}
</button>
