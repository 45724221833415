import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import { TranslationService } from '../translation.service';
import {TranslationHttpLoader} from "src/app/kernel/translations/helpers/TranslationHttpLoader";
import {LocalStorageService} from "src/app/kernel/tools/services/local-storage.service";
import { TranslationLoader } from '../helpers/TranslationLoader';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationModule.getLoader,
        deps: [HttpClient],
      },
      extend: true,
    }),
  ],
  providers: [
    TranslationService,
    {
      provide: TranslationLoader,
      useExisting: TranslateLoader
    }
  ],
  exports: [TranslateModule],
})
export class TranslationModule {

  static LOADER: TranslationHttpLoader;
  public static TRANSLATION_PATHS: string[] = [];


  constructor(localStorageService: LocalStorageService, protected translationService: TranslationService) {
    translationService.setDefaultLanguage(localStorageService.get('lang') ?? 'en')
  }

  public static getLoader(http: HttpClient) {
    if (!TranslationModule.LOADER) {
      TranslationModule.LOADER = new TranslationHttpLoader(http, [])
    }
    TranslationModule.LOADER.setResources(TranslationModule.TRANSLATION_PATHS);
    return TranslationModule.LOADER;
  }

  public static forRoot(
    path: string | string[]
  ): ModuleWithProviders<TranslationModule> {
    TranslationModule.TRANSLATION_PATHS = TranslationModule.TRANSLATION_PATHS.concat(Array.isArray(path) ? path : [path]);
    return {
      ngModule: TranslationModule,
    };
  }


}
