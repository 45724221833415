import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable, of, switchMap } from 'rxjs';
import * as validators from '../../helpers/custom-async-validators'
import { GraphService } from '../../../../../../graphql/services/graph.service';

@Directive({
  selector: '[asyncValidations]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting:AsyncValidatorDirective,multi: true }
  ]
})
export class AsyncValidatorDirective implements OnChanges, AsyncValidator {
  @Input('asyncValidations') validations: any;
  private control: AbstractControl | undefined;
  constructor(private graph: GraphService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['validations']) {
      this.control?.updateValueAndValidity();
    }
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>;
  validate(control: AbstractControl): ValidationErrors | null;
  validate(control: AbstractControl):
    Promise<ValidationErrors | null> | Observable<ValidationErrors | null> | ValidationErrors | null
  {
    if(!this.control) { this.control = control; }
    return of(control.value).pipe(
      switchMap(_value => {
        for (let v in this.validations) {
          if(!control.value || this.validations[v].active === false) { return of(null); }
          return (validators as any)[v]({ ...this.validations[v].options }, this.graph)(control)
        }
        return of(null)
      })
    )
  }
}
