/**
 * Represents Graphql Pagination Input
 */
import {GraphqlBaseInput} from "src/app/kernel/graphql/models/graphql-base.input";

export class PaginationGraphqlInput extends GraphqlBaseInput{

  page = 1 ;
  rpp = 10 ;

  /**
   * @param page
   * @param rpp
   */
  constructor(page = 1, rpp = 10) {
    super();
    this.page = page;
    this.rpp = rpp;
  }

}
