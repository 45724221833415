import validator from "validator";

export function isMobile(str: string , locale: any, options: any) {
  if(!validator.isMobilePhoneLocales.includes(locale)) {
    return true;
  }

  const valid = validator.isMobilePhone(str, locale, {...options});
  return valid ? null : ({isMobile: {message: 'isMobilePhone'}})
}
