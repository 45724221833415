import {FormControl, FormGroup} from "@angular/forms";
import {AssistantInterface} from "src/app/shared/assistant-shared/interfaces/assistant.interface";
import { IPersonalInfoType, IUser } from "src/app/chatperk-core/interfaces";
import { AuthUserModel } from "src/app/chatperk-core/models/AuthUser.model";

interface UpdatePersonalInfoFormGroupInterface {
  name: FormControl<string | null>,
  surname: FormControl<string | null>,
}

export class UpdatePersonalInfoFormModel {

  public formGroup: FormGroup<UpdatePersonalInfoFormGroupInterface>;

  constructor(user: AuthUserModel) {
    this.formGroup = this.initForm(user);
  }

  initForm(user: AuthUserModel) {
    return new FormGroup({
      name: new FormControl(user.name),
      surname: new FormControl(user.surname),
    })
  }
}
