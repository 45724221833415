/**
 * Represents Graphql Sort Input
 */
import {GraphqlBaseInput} from "src/app/kernel/graphql/models/graphql-base.input";

export class SortGraphqlInput extends GraphqlBaseInput{

  field: string | undefined = ``;
  order: number | undefined = 1 ;

  /**
   * @param field
   * @param order
   */
  constructor(field: string, order = 1) {
    super();
    this.field = field;
    this.order = !field ? undefined : order;
  }

}
