import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpaceService } from '../../services/space.service';
import { first } from 'rxjs';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { MutationResult } from 'apollo-angular';
import { ISpaceType, IUser } from 'src/app/chatperk-core/interfaces';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';

@Component({
  selector: 'app-invite-space',
  templateUrl: './invite-space.component.html',
  styleUrls: ['./invite-space.component.scss']
})
export class InviteSpaceComponent {
  spaceId!: string;
  secret!: string;

  constructor(
    private route: ActivatedRoute,
    private spaceService: SpaceService,
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {    
    // Extract spaceId and secret from the URL
    this.spaceId = this.route.snapshot.paramMap.get('id')!;
    this.secret = this.route.snapshot.queryParamMap.get('secret')!;

    if(!this.authService.getUser()){
      this.messageService.error('You must login to join the space', 'Error');
      this.router.navigate(['/auth/login'], {
        queryParams: { spaceId: this.spaceId, secret: this.secret }
      });
      return;
    }
    
    // If both spaceId and secret exist, verify the invite
    if (this.spaceId && this.secret) {
      this.verifyInvite(this.spaceId, this.secret);
    } else {
      this.messageService.error('Invalid invite link', 'Error');
      this.router.navigate([`/assistants`]);
    }
  }

  verifyInvite(spaceId: string, secret: string): void {
    this.spaceService.verifyInvitedMember(spaceId, secret).pipe(first())
      .subscribe({
        next: (res: any) => {
          this.messageService.success('Invite verification successful!', 'Done 🤩');
          this.spaceService.changeActiveSpace(this.spaceId).subscribe({
            next: (result: any) => {
              // Explicitly handle the result as MutationResult<ISpaceType>
              if (result?.data) {
                this.handleChangeSpaceReqSuccess(result.data); // Only pass `result.data` which should be ISpaceType
              } else if (result?.errors) {
                this.messageService.error('Failed to change space', 'Error');
              }
            },
            error: (err: any) => {
              this.messageService.error('Failed to change space', 'Error');
            }
          });
        },
        error: (err: any) => {
          this.messageService.error('Invite verification failed', 'Error');
          this.router.navigate([`/assistants`]);
        }
      });
  }
  

  handleChangeSpaceReqSuccess({ data }: MutationResult<ISpaceType>) {
    this.updateUserProfile()
  }

  updateUserProfile(){
    this.authService.getMyProfile().subscribe({
      next: this.handleProfileReqSuccess.bind(this)
    });
  }

  handleProfileReqSuccess(res : any){
    this.authService.updateUser(res.data as IUser);
    this.router.navigate([`/space/view/general`]);
  }
}
