<span class="input-style {{theme}}">
  <div
    class="switch-input flex-row-start"
    (click)="clicked()"
    [ngClass]="{
      'invalid-input':
        control &&
        control.errors &&
        (control.touched || sxwForm?.submitted),
      active: value,
      disabled: readonly || disabled
    }"
  >
    <div class="switch-ball"></div>
  </div>
  <sxw-error-message
    *ngIf="
      control &&
      control.errors &&
      (control.touched || sxwForm?.submitted)
    "
    [message]="control ? control.errors : null"
  ></sxw-error-message>
</span>
