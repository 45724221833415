import { Injectable } from '@angular/core';
import { GraphService } from 'src/app/kernel/graphql/services/graph.service';
import { GqlResponseInterface } from 'src/app/kernel/graphql/interfaces/gql-response.interface';
import { GraphqlBaseInput } from 'src/app/kernel/graphql/models/graphql-base.input';
import {
  IInvoice,
  IPreviewInvoice,
  IPreviewInvoiceDtoInput,
} from 'src/app/chatperk-core/interfaces/invoice.interfaces';
import {
  PREVIEW_INVOICE_DATA_SHAPE,
  VIEW_INVOICE_DATA_SHAPE,
} from '../datashape/invoice.datashape';

@Injectable()
export class InvoiceService {
  constructor(private graphService: GraphService) {}

  previewInvoice(
    input: IPreviewInvoiceDtoInput,
    dataShape: string = PREVIEW_INVOICE_DATA_SHAPE
  ) {
    const gqlInput = new GraphqlBaseInput();
    gqlInput.addParams({ input }, { input: 'PreviewInvoiceInput!' });

    return this.graphService.constructQuery<
      GqlResponseInterface<IPreviewInvoice>
    >([dataShape], 'previewInvoice', gqlInput);
  }

  getById(id: string, dataShape = VIEW_INVOICE_DATA_SHAPE) {
    const gqlInput = new GraphqlBaseInput();
    gqlInput.addParams({ id }, { id: 'ID!' });

    return this.graphService.constructQuery<GqlResponseInterface<IInvoice>>(
      [dataShape],
      'invoice',
      gqlInput
    );
  }
}
