import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy, OnInit,
  Output, SimpleChanges,
} from "@angular/core";
import { CustomInput } from "src/app/kernel/form/classes/custom-input";
import {
  accessDeep,
  omitDeep,
  constructDeep,
  compareDeep,
} from "src/app/kernel/shared/helpers/object.helper";
import {
  Observable,
  Subject,
  Subscription,
  finalize,
  map,
  takeUntil,
} from "rxjs";
import { QueryWithFiltersInterface } from "src/app/kernel/graphql/interfaces/query.interface";
import { TranslationService } from "src/app/kernel/translations/translation.service";
import { HttpHeaders } from "@angular/common/http";
import {
  FilterInput,
  FiltersGraphqlInput,
  ListingGraphqlInput,
  PaginationGraphqlInput,
  SortGraphqlInput,
} from "../../graphql/models";
import { GraphService } from "../../graphql/services/graph.service";
import { DropdownFilterOptions } from "primeng/dropdown";

@Directive()
export abstract class BaseDropdown<T = any>
  extends CustomInput<T>
  implements OnDestroy, OnInit
{
  @Input() options: any[] = [];
  @Input() override value: any = undefined;
  @Input() optionValue = "";
  @Input() optionLabel = "";
  @Input() formateOptionLabel?: (item: any) => string;
  @Input() optionIdentifier = "";
  @Input() getDataMethod?: (filter: string, page?: number) => Observable<any[]>;
  @Input() query?: QueryWithFiltersInterface;
  @Input() group: boolean = false;
  @Input() optionGroupChildren!: string;
  @Input() optionGroupLabel!: string;
  @Input() nullable: boolean = true;
  @Input() showClear = true;
  @Input() virtualScroll = false;

  /**
   * showClear initially depends on an external value (SHOW_CLEAR_CASH) but can be overridden based on the component's
   * state (disabled, loading).
   * We need to know the initial value of SHOW_CLEAR_CASH to calculate the final visibility
   * of the "Clear" button.
   * @private
   */
  private SHOW_CLEAR_CASH = true;
  /**
   * Need to know the value of readonly that come from outside, and cash it, and when loading is false set readonly to
   * READONLY_CASH
   * @private
   */
  private READONLY_CASH = false;

  @Input() strictQuery = true;
  @Input() filterBy = "";
  @Input() queryFilterBy = "";
  @Input() maxItems = 20;
  @Input() defaultValue!: number;
  @Output() onDropDownChange = new EventEmitter<any>();
  @Output() loadingStateChange = new EventEmitter<boolean>();

  isLoadingData = false;
  protected isFirstTime: boolean = true;
  protected _loadDataSubscription: Subscription | null | undefined;
  protected _locked = false;
  // _page and _allPagesLoaded are used for pagination in loading options
  protected _page = 1;
  protected _allPagesLoaded = false;
  protected _filterTerm = "";
  protected _filterDebounceTimer: any;
  protected _unsubscribe = new Subject<void>();
  private _summaryValue?: string[] | string;

  override get summaryValue() {
    return this._summaryValue;
  }

  protected constructor(
    protected graph: GraphService,
    protected translationService: TranslationService,
  ) {
    super();
  }

  /**
   * Prepares the dropdown options
   * places the label of the option in __optionLabel after deepAccess and translating it
   * places the groupChildren under __children after preparing them also.
   * @param data
   * @param label
   * @param optionGroupChildren
   * @param optionGroupLabel
   */
  protected prepareOptions(
    data: any[],
    label?: string,
    optionGroupChildren?: string,
    optionGroupLabel?: string,
  ): any[] {
    return data?.map((item) => {
      const labelVal =  this.formateOptionLabel ? this.formateOptionLabel(item) : accessDeep(item, label ?? "label") || " ";

      const asyncValue = this.translationService
        .waitUntilTranslationLoaded(".*")
        .pipe(map(_ => typeof labelVal === "string" ? this.translationService.instant(labelVal) : labelVal));

      const value = typeof labelVal === "string" ? this.translationService.instant(labelVal) : labelVal;
      Object.defineProperty(item, "__optionLabelAsync", {value: asyncValue, writable: true,});
      Object.defineProperty(item, "__optionLabel", { value, writable: true });

      // checks if there is group children it will prepare them also.
      if (optionGroupChildren && Array.isArray(accessDeep(item, optionGroupChildren))) {
        Object.defineProperty(item, "__children", {
          value: this.prepareOptions(accessDeep(item, optionGroupChildren), optionGroupLabel),
          writable: true,
        });
      }


      return item;
    });
  }

  /**
   * Sets the dropdown options after preparing them.
   * @param options
   */
  setOptions(options: any[]) {
    this.options = this.removeDuplicateOptions(
      this.prepareOptions(
        options,
        this.optionLabel,
        this.optionGroupChildren,
        this.optionGroupLabel,
      ),
    );
  }

  /**
   * Adds options after preparing them.
   * @param options
   */
  addOptions(options: any[]) {
    const _options = this.prepareOptions(
      options,
      this.optionLabel,
      this.optionGroupChildren,
      this.optionGroupLabel,
    );
    this.options = this.removeDuplicateOptions(this.options.concat(_options));
    return _options;
  }

  /**
   * Removes the duplicated options.
   * NOTE: it keeps the first value it found, therefore make sure the selected option comes first.
   * @param options
   */
  removeDuplicateOptions(options: any[]): any[] {
    const seenObjects = new Set<string>();
    return options?.filter(option => {
      const optionID = this.createOptionIdentifier(option);
      return seenObjects.has(optionID) ? false : (seenObjects.add(optionID), true);
    });
  }

  /**
   * Creates a unique option identifier
   * @param option
   */
  createOptionIdentifier(option: any) {
    if (this.optionIdentifier) {
      return option[this.optionIdentifier]
    }
    return JSON.stringify(this.prepareOutputValue(option))
  }

  /**
   * Extract the prepared label from the option.
   * @param option
   */
  protected getPreparedLabel(option: any) {
    return option?.__optionLabel;
  }

  /**
   * Extract the selected options from the onChangeHandler event.
   * @param event
   */
  protected getChangeEventValue(event: any) {
    return event.value;
  }

  /**
   * Handles the dropdown value was changed by the user.
   * @param event
   */
  protected onChangeHandler(event: any) {
    const value = this.getChangeEventValue(event);
    this.value = value;
    this.syncSummaryValue();
    const prepared = this.prepareOutputValue(value)
    this.onChange(prepared);
    this.onDropDownChange.emit(prepared);
  }

  /**
   * Resets the dropdown options.
   */
  clearOptions() {
    this.options = [];
  }

  onClearHandler(event: any) {
    this.onTouched();
    this.updateFilter({original: event.original, filter: ''})
  }

  protected updateFilter(event: any) {
    this._filterTerm = event?.filter || event?.query || '';
    clearTimeout(this._filterDebounceTimer);
    this._filterDebounceTimer = setTimeout(() => this.loadData(), 300);
  }

  /**
   * Sets the dropdown value after preparing it if the dropdown options are ready,
   * it stores the value in the _nextValue if the dropdown is not ready.
   * @param value
   */
  setValue(value: any) {
    let _val = this.prepareInputValue(value);
    // if the value was not found in the options, and the value is an object,
    // then convert that object to an option and add it to the options list
    let options;
    if ((!_val || (Array.isArray(_val) && !_val.length)) && value && typeof value === "object") {
      options = this.addOptions(value && Array.isArray(value) ? value : [value],);
    } else if (Array.isArray(_val) && Array.isArray(value) && _val.length < value.length) {
      let difference = value.filter(
        (v) => !_val.find(
          // TODO: use createOptionIdentifier instead
          (_v: any) => compareDeep(this.prepareOutputValue(v), this.prepareOutputValue(_v),),
        ),
      );
      _val = _val.concat(this.addOptions(difference));
    }
    this.value = _val && (!Array.isArray(_val) || _val.length) ? _val : Array.isArray(value) ? options : options?.[0];
    this.syncSummaryValue();
    setTimeout(() => (this._locked = false), 700);
  }

  /**
   * Returns true if there is a difference between the control value and the prepared output of the dropdown
   * @param value
   * @param prepared
   */
  shouldTriggerControlValueChange(value: any, prepared: any): boolean {
    value = (Array.isArray(value) ? value[0] : value) ?? {};
    prepared = (Array.isArray(prepared) ? prepared[0] : prepared) ?? {};
    if (typeof value === typeof prepared) {
      return typeof value === "object"
        ? Object.keys(value).length !== Object.keys(prepared).length
        : value !== prepared;
    }
    return true;
  }

  /**
   * Writes a new value to the element.
   * @param value the value
   */
  override async writeValue(value: any): Promise<void> {
    if (value) {
      this._locked = true;
    }
    // if the value is falsy or it's an empty object or an empty array, then set the value to null or the first option.
    if (
      !value ||
      (typeof value === "object" && !Object.keys(value).length) ||
      (Array.isArray(value) && !value.length)
    )
      this.setValue(
        !this.nullable && this.options?.length ? this.options[0] : null,
      );
    // if there is a value, then set it.
    else this.setValue(value);

    /**
     * If the developer sets a value to the autocomplete-select but there is no options loaded yet,
     * If there is a query config but no query is currently pending, then call load data in order to load the options.
     */
    if (
      value &&
      (this.query || this.getDataMethod) &&
      (!this._loadDataSubscription || this._loadDataSubscription.closed) &&
      !this.options
    ) {
      await this.loadData();
    }

    const prepared = this.prepareOutputValue(value);
    if (this.shouldTriggerControlValueChange(value, prepared)) {
      setTimeout(() => this.onChange(prepared));
    }
  }

  protected setShowClearState() {
    this.showClear = this.SHOW_CLEAR_CASH && !this.disabled && !this.readonly;
  }

  /**
   * Tries to find the option that outputs the same value, or sets the value as is.
   * @param value
   * @param options
   * @param optionGroupChildren
   */
  protected prepareInputValue(
    value: any,
    options: any[] = this.options,
    optionGroupChildren: string = this.optionGroupChildren,
  ): any {
    if (!value) return null;
    if (Array.isArray(value))
      return value
        .map((val: any) =>
          this.prepareInputValue(val, options, optionGroupChildren),
        )
        .filter((i) => !!i);

    return this.findOptionMatchesValue(value, options, optionGroupChildren);
  }

  /**
   * Finds an option that matches to the input value and returns it.
   * It also handles the case where the selected option is within a group.
   * @param value
   * @param keys
   * @param options
   * @param optionGroupChildren
   */
  protected findOptionMatchesValue(
    value: any,
    options: any[],
    optionGroupChildren?: string,
  ): any {
    for (let i = 0; i < options.length; ++i) {
      let option = options[i];
      if (optionGroupChildren && option[optionGroupChildren]) {
        const result = this.findOptionMatchesValue(
          value,
          option[optionGroupChildren],
          optionGroupChildren,
        );
        if (result) return result;
      }
      if (this.createOptionIdentifier(option) === this.createOptionIdentifier(value)) {
        options[i] = this.prepareOptions([
          {...option, ...(typeof value === 'object' ? value : {})}
        ], this.optionLabel)[0];
        return options[i]
      }
    }
  }

  /**
   * Checks if any of the query params is empty.
   * @protected
   */
  protected isQueryValid() {
    // if there is no query or query params then the query has nothing that can be wrong,
    // if there is any param that has no value then the query is not valid.
    return (
      !this.query ||
      !this.query.params ||
      !Object.values(this.query.params).some(
        (param: any) =>
          param.value === null ||
          param.value === undefined ||
          (Array.isArray(param.value) && !param.value.length),
      )
    );
  }

  /**
   * Uses optionValue to create the output value.
   * @param value
   */
  protected prepareOutputValue(value: any): any {
    if (!(typeof value === "object" && value)) {
      return value;
    }

    if (!this.optionValue) {
      // return omitDeep(cloneDeep(value ?? {}), "__typename");
      return omitDeep(value ?? {}, "__typename");
    }

    if (Array.isArray(value)) {
      return value.map((val: any) => this.prepareOutputValue(val));
    }
    const keys = this.optionValue.split(",");
    if (keys.length === 1) {
      return accessDeep(value, this.optionValue);
    }
    return constructDeep(value, keys);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////

  //  TODO: refactor this, it is important.
  syncSummaryValue(): any {
    let summaryValue: string[] | string | undefined;
    if (!this.optionLabel && !this.optionValue) {
      summaryValue = this.value;
    } else if (this.value && typeof this.value === "string") {
      summaryValue = this.parseSummaryValueFromString();
    } else if (
      this.value &&
      typeof this.value === "object" &&
      !Array.isArray(this.value)
    ) {
      summaryValue = this.parseSummaryValueFromObject();
    } else if (this.value && Array.isArray(this.value)) {
      summaryValue = this.parseSummaryValueFromArray();
    }
    this._summaryValue = summaryValue;
  }

  private parseSummaryValueFromString(): any {
    return this.getPreparedLabel(this.value);
  }

  private parseSummaryValueFromArray(): any[] | undefined {
    if (!Array.isArray(this.value)) return;
    return this.value.map((value) => this.getPreparedLabel(value));
  }

  private parseSummaryValueFromObject(): any {
    return this.getPreparedLabel(this.value);
  }

  // =================== Load Data Methods { =================== //
  /**
   * Loads the options using the valid load options method.
   * @param reset
   */
  loadData(reset = true): Promise<any> {
    // Return a resolved promise with undefined if the load data method is unavailable or the strictQuery flag is
    //  enabled, but the query parameters are not all valid.
    if (!this.getLoadDataMethod() || !(this.strictQuery ? this.isQueryValid() : true)) {
      return Promise.resolve(undefined);
    }


    this.publishLoadingState(true);
    // If the data will be reset, then reset the pagination.
    if (reset) {
      this.resetPagination();
    }
    // unsubscribe if there is any ongoing request.
    this._loadDataSubscription?.unsubscribe();
    return new Promise((resolve, reject) => {
      this._loadDataSubscription = this.getLoadDataMethod()
        ?.pipe(finalize(() => this.publishLoadingState(false)))
        .subscribe({
          next: (data) => {
            this.handleLoadDataResponse(data, reset);
            this.handleNullableValue();
            this.isFirstTime = false;
            return resolve(this.options);
          },
          error: reject,
        });
    });
  }

  handleNullableValue() {
    if (this.nullable || !this.isFirstTime) {
      return;
    }
    this.setValue(this.options[0]);
    this.onDropDownChange.emit(this.prepareOutputValue(this.value));
  }

  /**
   * Handle loading state, and disable dropdown while loading
   * @param state
   * @protected
   */
  protected publishLoadingState(state: boolean) {
    this.isLoadingData = state;
    this.loadingStateChange.emit(state);
    this.readonly = state;
  }

  /**
   * Resets the pagination
   * @protected
   */
  protected resetPagination() {
    this._page = 1;
    this._allPagesLoaded = false;
  }

  /**
   * Reloads the data.
   */
  reloadData(): Promise<any> {
    return this.loadData();
  }

  /**
   * Handles the loadData response
   * @param data
   * @param reset
   * @protected
   */
  protected handleLoadDataResponse(data: any[], reset: boolean) {
    return reset ? this.setOptions(this.addValueOptions(data ?? [])) : this.addOptions(data);
  }

  /**
   * Makes sure that the value of the dropdown is presented in the options by adding it if it is not.
   * @param data
   * @protected
   */
  protected addValueOptions(data: any) {
    if (!this.value || (Array.isArray(this.value) && !this.value.length)) {
      return data;
    }
    return [
      ...(Array.isArray(this.value) ? this.value : [this.value]),
      ...data,
    ];
  }

  /**
   * Returns an Observable that retrieves the dropdown options based on the provided query or the specified
   * getDataMethod. Returns undefined if neither a query nor a valid getDataMethod is provided.
   * @protected
   */
  protected getLoadDataMethod(): Observable<any> | undefined {
    return this.query
      ? this.loadDataUsingQuery()
      : typeof this.getDataMethod === "function"
      ? this.loadDataUsingGetDataMethod()
      : undefined;
  }

  /**
   * Loads data using load method
   * @protected
   */
  protected loadDataUsingGetDataMethod(): Observable<any> | undefined {
    return this.getDataMethod?.(this._filterTerm, this._page).pipe(
      takeUntil(this._unsubscribe),
      map((data) => {
        this._allPagesLoaded = data && !data.length;
        this.virtualScroll = data?.length > 7;
        return data;
      }),
    );
  }

  /**
   * Loads data using graphql query
   * @protected
   */
  protected loadDataUsingQuery(): Observable<any> {
    return this.graph
      .constructListingQuery(
        this.query?.select ?? [],
        this.query?.name ?? "",
        {...this.getListingGraphqlInput(), ...(this.query?.additionalParams ?? {})},
        this.query?.additionalParamsDef ?? {},
        new HttpHeaders().set("ignore_http_loader", "ignore_http_loader"),
      )
      .pipe(
        takeUntil(this._unsubscribe),
        map((data: any) => {
          const result = data?.data?.[this.query?.name ?? ""];
          this._allPagesLoaded =
            (result?.data && !result.data.length) ||
            result?.pagination.total <= this.maxItems;
          this.virtualScroll = result?.pagination.total > 7;
          return result?.data;
        }),
      );
  }

  /**
   * Returns the listing graphql input.
   * @private
   */
  private getListingGraphqlInput(): ListingGraphqlInput {
    return ListingGraphqlInput.wrap(
      this.getListingGraphqlFilters(),
      new PaginationGraphqlInput(this._page, this.maxItems),
      this.getListingGraphqlSort(),
    );
  }

  /**
   * Returns the graphql sorting input or null if it is not needed.
   * @protected
   */
  protected getListingGraphqlSort() {
    if (!(this.query?.sort?.field || this.optionLabel)) return null;
    return new SortGraphqlInput(
      this.query?.sort?.field ?? this.optionLabel,
      this.query?.sort?.order ?? 1
    );
  }

  /**
   * Returns the graphql filters input or null if it is not needed
   * @protected
   */
  protected getListingGraphqlFilters() {
    const filterBy = this.queryFilterBy || this.filterBy || this.optionLabel || "name";
    const filterInputs = this._filterTerm
      ? [new FilterInput(filterBy, "LIKE", this._filterTerm)]
      : [];
    const params = filterInputs.concat(
      (this.query?.params || [])
        .filter(
          (p) =>
            (p.value || typeof p.value === "number") &&
            (!Array.isArray(p.value) || p.value?.length !== 0) &&
            (typeof p.value !== "object" ||
              (Array.isArray(p.value) &&
                p.value.every((av) => typeof av !== "object"))),
        )
        .map((p) => {
          return new FilterInput(
            p.field,
            p.option ?? "IS",
            Array.isArray(p.value) ? p.value.join(",") : p.value,
          );
        }),
    );

    if (!params?.length) return undefined;

    return new FiltersGraphqlInput(
      params,
      this.query?.filtersCondition || "AND",
    );
  }

  /**
   * if dropdown loading, it will be readonly so user can't edit it.
   * @private
   */
  private loadingMonitor() {
    this.loadingStateChange.pipe(takeUntil(this._unsubscribe)).subscribe(loading => {
      this.readonly = loading ? true : this.READONLY_CASH;
      this.setShowClearState();
    })
  }
  // =================== } Load Data Methods =================== //

  // =================== Angular Lifecycle hooks { =================== //

  ngOnInit() {
    this.loadingMonitor();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['showClear']) {
      this.SHOW_CLEAR_CASH = changes['showClear'].currentValue;
    }
    if(changes['readonly']) {
      this.READONLY_CASH = changes['readonly'].currentValue;
      this.setShowClearState();
    }
  }

  /**
   * Angular lifecycle hook
   */
  override ngOnDestroy() {
    super.ngOnDestroy();
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
  // =================== } Angular Lifecycle hooks =================== //
}
