import { CanActivateFn } from "@angular/router";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { EAccessType } from "../enums/EAccessType";

const accessDefaultRoutesMap: Record<EAccessType, string[]> = {
  [EAccessType.FAT]: ["/"],
  [EAccessType.TFA]: ["/"],
  [EAccessType.ConfirmResetPassword]: ["/auth/confirm-reset-password"],
  [EAccessType.NONE]: ["/"]
};

/**
 * Extracts the value of a given key from an object and ensures it is returned as an array.
 * - If the value is already an array, it is returned as is. Otherwise, the value is wrapped in an array.
 * This utility simplifies code for extracting `allowedAccessTokens` and `allowedTypes`.
 * @param data
 * @param key
 */
const extractDataAsArray =<T = string>(data: {[key: string]: string | string[]}, key: string): T[] | undefined => {
  return data[key] ? <T[]>(Array.isArray(data[key]) ? data[key] : [data[key]]) : undefined;
}

export const accessTokenGuard: CanActivateFn = async (route, state) => {

  const authService = inject(AuthService);
  const router = inject(Router);

  const allowedAccessToken = extractDataAsArray<EAccessType>(route.data, "allowedAccessToken");
  const allowedTypes = extractDataAsArray(route.data, "allowedTypes");


  const token = authService.getParsedToken();
  const user = authService.getUser();

  // If the route does not require access token or the user access token is included in the allowed tokens
  if (!allowedAccessToken || (token && allowedAccessToken.includes(token.accessType))) {
    // allow user access if user type is allowed, deny it otherwise
    return !allowedTypes || allowedTypes.includes(user?.type ?? '_NONE') ;
  }

  // If the route is only accessible via none authenticated users then return true if there was no token.
  if (allowedAccessToken?.includes(EAccessType.NONE) && !token) {
    return true ;
  }

  const target = token ? accessDefaultRoutesMap[token.accessType] : ['auth/login'];
  if (state.url !== target.join('/')) {
    authService.storeRedirectURL(state.url);
  }
  // If token exists then navigate to the suitable
  await router.navigate(target);
  return false

};
