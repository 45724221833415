import { toDate } from "src/app/kernel/shared/helpers/date-helper/date.helper";
import { ValidatorsOptionsInterface } from "../../interfaces/validators-options.interface";

export function isBeforeDateTime(
  str: string,
  options?: ValidatorsOptionsInterface & {
    time: string;
    comparsionDate: Date | number;
    comparsionTime: string;
  }
): Record<string, ValidatorsOptionsInterface> | null {
  if (!options?.comparsionDate || !str) {
    return null;
  }
  const comparison = new Date(toDate(options.comparsionDate));
  const original = new Date(toDate(str));
  if (!options?.time)
    throw new Error("time is required in isBeforeDateTime options");
  if (!options?.comparsionTime)
    throw new Error("comparsionTime is required in isBeforeDateTime options");
  const [newHours, newMinutes] = options!.time.split(":").map(Number);

  original.setHours(newHours);
  original.setMinutes(newMinutes);
  const [comparisonNewHours, comparisonNewMinutes] = options!.comparsionTime
    .split(":")
    .map(Number);
  comparison.setHours(comparisonNewHours);
  comparison.setMinutes(comparisonNewMinutes);
  return original && comparison && original < comparison
    ? null
    : {
        isBeforeDate: {
          message: options?.message || "isBeforeDate",
          comparisonFieldName: options?.comparisonFieldName || "",
        },
      };
}
