export const BILLING_ACCOUNT_FORM_DATA_SHAPE = `
  firstName
  lastName
  orgName
  taxId
  email
  mobile
  address {
    country
    city
    state
    zip
    line1
    line2
  }
`;
