<sxw-form
  [class.one-field-per-row]="oneFieldPerRow"
  [class.prevent-inputs-padding]="oneFieldPerRow"
  [formGroup]="formModel.formGroup"
  [mutationConfig]="$any(mutationConfig)"
  (onMutationComplete)="handleMutationComplete($event)"
  (onMutationError)="handleMutationError($event)"
  #formElement
>
  <div class="fields-row">
    <div class="field-input">
      <label class="input-label">
        {{ "billingAccount.form.firstName" | translate }}
      </label>
      <sxw-text-input
        id="firstName"
        formControlName="firstName"
        [validations]="{ isNotEmpty: {} }"
      ></sxw-text-input>
    </div>
    <div class="field-input">
      <label class="input-label">
        {{ "billingAccount.form.lastName" | translate }}
      </label>
      <sxw-text-input
        id="lastName"
        formControlName="lastName"
        [validations]="{ isNotEmpty: {} }"
      ></sxw-text-input>
    </div>
  </div>
  <ng-container *ngIf="separateCompanyInfo">
    <div class="fields-row">
      <div class="field-input">
        <label class="input-label">
          {{ "billingAccount.form.email" | translate }}
        </label>
        <sxw-text-input id="email" formControlName="email"></sxw-text-input>
      </div>
      <div class="field-input"></div>
    </div>

    <div class="input-group switch">
      <sxw-radio-input
        [value]="enableCompanyInfo"
        (change)="toggleCompanyInfo($event)"
      ></sxw-radio-input>
      <!-- I am a organization/company -->
      <label>{{ "billingAccount.form.companyInfoSwitch" | translate }}</label>
    </div>
  </ng-container>

  <ng-container *ngIf="enableCompanyInfo">
    <div class="fields-row">
      <div class="field-input">
        <label class="input-label">
          {{ "billingAccount.form.orgName" | translate }}
        </label>
        <sxw-text-input id="orgName" formControlName="orgName"></sxw-text-input>
      </div>
      <div class="field-input">
        <label class="input-label">
          {{ "billingAccount.form.taxId" | translate }}
        </label>
        <sxw-text-input id="taxId" formControlName="taxId"></sxw-text-input>
      </div>
    </div>

    <ng-container formGroupName="address">
      <p class="description">
        {{ "billingAccount.form.descriptions.addressFields" | translate }}
      </p>
      <div class="fields-row">
        <div class="field-input">
          <label class="input-label">
            {{ "billingAccount.form.country" | translate }}
          </label>
          <sxw-dropdown-input
            id="country"
            formControlName="country"
            optionLabel="name"
            optionValue="isoCode"
            [options]="countries"
            [showClear]="false"
          ></sxw-dropdown-input>
        </div>
        <div class="field-input">
          <label class="input-label">
            {{ "billingAccount.form.state" | translate }}
          </label>
          <sxw-dropdown-input
            id="state"
            formControlName="state"
            optionLabel="name"
            optionValue="isoCode"
            [options]="states"
            [showClear]="false"
          ></sxw-dropdown-input>
        </div>
      </div>
      <div class="fields-row">
        <div class="field-input">
          <label class="input-label">
            {{ "billingAccount.form.line1" | translate }}
          </label>
          <sxw-text-input id="line1" formControlName="line1"></sxw-text-input>
        </div>
        <div class="field-input">
          <label class="input-label">
            {{ "billingAccount.form.line2" | translate }}
          </label>
          <sxw-text-input id="line2" formControlName="line2"></sxw-text-input>
        </div>
      </div>
      <div class="fields-row">
        <div class="field-input">
          <label class="input-label">
            {{ "billingAccount.form.city" | translate }}
          </label>
          <sxw-dropdown-input
            id="city"
            formControlName="city"
            optionLabel="name"
            optionValue="name"
            [options]="cities"
            [showClear]="false"
          ></sxw-dropdown-input>
        </div>
        <div class="field-input">
          <label class="input-label">
            {{ "billingAccount.form.zip" | translate }}
          </label>
          <sxw-text-input id="zip" formControlName="zip"></sxw-text-input>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!separateCompanyInfo">
    <p class="description">
      {{ "billingAccount.form.descriptions.emailField" | translate }}
    </p>
    <div class="fields-row">
      <div class="field-input">
        <label class="input-label">
          {{ "billingAccount.form.email" | translate }}
        </label>
        <sxw-text-input id="email" formControlName="email"></sxw-text-input>
      </div>
      <div class="field-input"></div>
    </div>
  </ng-container>
</sxw-form>
