import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { ISideNavConfig } from 'src/app/layout/sidenav/interfaces/ISideNavConfig';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';

@Component({
  selector: 'app-dashboard-full-layout',
  templateUrl: './dashboard-full-layout.component.html',
  styleUrls: ['./dashboard-full-layout.component.scss'],
})
export class DashboardFullLayoutComponent {
  @ViewChild('content') contentEleRef?: ElementRef<HTMLDivElement>;

  constructor(public authService: AuthService) {}

  scrollTo(options?: ScrollToOptions) {
    this.contentEleRef?.nativeElement?.scrollTo(options);
  }

  scrollBy(options?: ScrollToOptions) {
    this.contentEleRef?.nativeElement?.scrollBy(options);
  }

  sideNavConfig: ISideNavConfig = {
    items: [
      {
        title: '',
        items: [
          {
            label: 'sidenav.assistant',
            icon: 'chat-assistant',
            routerLink: {
              path: [
                `/${this.authService.getUser()?.activeSpace?.code}`,
                'assistants',
              ],
            },
            default: true,
          },
          {
            label: 'sidenav.analytics',
            icon: 'bar-chart',
            soon: true,
            routerLink: { path: '/dashboard/statistics' },
          },
          {
            label: 'sidenav.settings',
            icon: 'settings',
            routerLink: { path: '/space/settings' },
          },
        ],
      },
    ],
  };
}
