import {FactoryProvider, ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from "@angular/common/http";
import {ApolloModule, APOLLO_OPTIONS} from "apollo-angular";
import {HttpBatchLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {GraphService} from "src/app/kernel/graphql/services/graph.service";
import {QueryConstructorService} from "src/app/kernel/graphql/services/query-constructor.service";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ApolloModule,
  ],
  providers: [
    GraphService,
    QueryConstructorService
  ]
})

export class GraphqlModule {


  public static on(uri: string): ModuleWithProviders<GraphqlModule> {
      return { ngModule: GraphqlModule,  providers: [ GraphqlModule.createApolloProvider(uri) ] };
  }

  /**
   * Returns apollo provider
   * @param uri
   * @private
   */
  private static createApolloProvider (uri: string): FactoryProvider {

    return {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpBatchLink) => ({
        cache: new InMemoryCache({addTypename: false,}),
        link: httpLink.create({ uri,  batchInterval: 20 }),
        defaultOptions: {
          watchQuery: { fetchPolicy: 'no-cache',  errorPolicy: 'all', },
          query: { fetchPolicy: 'no-cache',  errorPolicy: 'all', },
        }
      }),
      deps: [HttpBatchLink],
    };
  }


}
