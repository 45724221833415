import {Component, EventEmitter, forwardRef, Host, Input, Optional, Output, ViewChild} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { CustomInput } from "src/app/kernel/form/classes/custom-input";
import { SxwFormComponent } from "src/app/kernel/form/components/sxw-form/sxw-form.component";
import { InputNumber } from "primeng/inputnumber";

@Component({
  selector: "sxw-number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent extends CustomInput<number> {
  @Input() stepValue = 1;
  @Input() label!: string;
  @Input() maxValue = 100000000;
  @Input() minValue = 0;
  @Input() modeType = "decimal"; // decimal, currency...
  @Input() localType = 'it-IT'; // en-IN, de-DE, jp-JP...
  @Input() currencyType = "EUR"; // USD, EUR, INR, CNY, JPY...
  @Input() currencyDisplay = "symbol"; // symbol, code
  @Input() enableShowButtons = false;
  @Input() enableButtonLayout = "horizontal"; // horizontal, vertical
  @Input() spinnerModeType = "horizontal"; // horizontal, vertical
  @Input() useGrouping = true;
  @Input() placeholder = "";
  @Input() suffix = "";
  @Input() prefix = "";
  @Input() maxLength!: number;
  @Input() minFractionDigits = 0;
  @Input() maxFractionDigits!: number;
  @Input() override value: number | undefined | null;
  @Input() theme = "sxw-theme";

  @Output() change = new EventEmitter();

  @ViewChild('numberInput', { static: true, read: InputNumber }) numberInput?: InputNumber;

  constructor(@Optional() @Host() public sxwForm?: SxwFormComponent) {
    super();
  }

  onInputChanged(event: any) {
    this.onTouched();
    this.onChange(
      event.value === null
        ? null
        : Math.min(Math.max(event.value, this.minValue), this.maxValue)
    );
    this.change.emit(event.value);
  }

  override get summaryValue(): string {
    if(this.value === undefined || this.value === null) { return '' } // when value is equal to 0, it's true
    return this.modeType === 'decimal' ? `${this.value}` : `${this.numberInput?.prefixChar} ${this.value}`
  }
}
