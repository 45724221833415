import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormMutationInterface } from 'src/app/kernel/form/interfaces/form-mutation.interface';
import { SxwFormComponent } from 'src/app/kernel/form/components/sxw-form/sxw-form.component';
import { CountryLockupService } from 'src/app/kernel/tools/services/country-lockup.service';
import { CityLockupService } from 'src/app/kernel/tools/services/city-lockup.service';
import { StateLockupService } from 'src/app/kernel/tools/services/state-lockup.service';
import { ICity, ICountry, IState } from 'country-state-city';
import { Subject, takeUntil } from 'rxjs';
import { IBillingAccount } from 'src/app/chatperk-core/interfaces';
import { BillingAccountFormModel } from '../../models/billing-account-form.model';

@Component({
  selector: 'app-billing-account-form',
  templateUrl: './billing-account-form.component.html',
  styleUrls: ['./billing-account-form.component.scss'],
})
export class BillingAccountFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  private destroy$ = new Subject<void>();
  @Input() mutationConfig?: FormMutationInterface;
  @Input() billingAccount?: Partial<IBillingAccount>;
  @Input() oneFieldPerRow = false;
  @Input() separateCompanyInfo = false;
  @Input() formModel = new BillingAccountFormModel();
  @Output() onMutationComplete = new EventEmitter<any>();
  @Output() onMutationError = new EventEmitter<any>();
  @ViewChild('formElement') formElement!: SxwFormComponent;

  public countries: ICountry[] = [];
  public states: IState[] = [];
  public cities: ICity[] = [];
  public enableCompanyInfo = true;

  constructor(
    public countryLockupService: CountryLockupService,
    public cityLockupService: CityLockupService,
    public stateLockupService: StateLockupService
  ) {
    this.loadCountries();
  }

  ngOnInit() {
    this.observeModelChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['billingAccount']) {
      if (this.billingAccount) this.formModel.patchValue(this.billingAccount);
      else this.formModel.resetValue();
      this.loadStatesByCountryCode(this.billingAccount?.address?.country);
    }

    if (changes['formModel']) {
      this.loadStatesByCountryCode(this.formModel.value?.address?.country);
      this.observeModelChanges();
    }

    if (changes['formModel'] || changes['billingAccount']) {
      console.log(this.formModel.value);
      this.enableCompanyInfo =
        !!Object.values(this.formModel.value.address ?? {}).some((v) => !!v) ||
        !!this.formModel.value.taxId ||
        !!this.formModel.value.orgName;
    }
  }

  observeModelChanges() {
    this.observeCountryChanges();
    this.observeStateChanges();
  }

  handleMutationComplete(response: any) {
    this.onMutationComplete.emit(response);
  }

  handleMutationError(response: any) {
    this.onMutationError.emit(response);
  }

  submitForm() {
    this.formElement.submit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private observeCountryChanges() {
    this.formModel
      .countryChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((country) => {
        this.loadStatesByCountryCode(country);
      });
  }

  private observeStateChanges() {
    this.formModel
      .stateChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.loadCitiesByCountryAndStateCode(
          this.formModel.value.address?.country,
          state
        );
      });
  }

  private loadCountries() {
    this.countries = this.countryLockupService.getAll();
  }

  private loadStatesByCountryCode(countryCode?: string | null) {
    const currentState = this.formModel.value.address?.state;

    this.states = countryCode
      ? this.stateLockupService.getAllByCountry(countryCode)
      : [];

    if (currentState) {
      if (
        !countryCode ||
        !this.stateLockupService.getOneByCodeAndCountry(
          currentState,
          countryCode
        )
      )
        this.formModel.formGroup.get('address.state')?.reset();

      const maybeUpdatedState = this.formModel.value.address?.state;
      this.loadCitiesByCountryAndStateCode(countryCode, maybeUpdatedState);
    }
  }

  private loadCitiesByCountryAndStateCode(
    countryCode?: string | null,
    stateCode?: string | null
  ) {
    const currentCity = this.formModel.value.address?.city;

    this.cities =
      countryCode && stateCode
        ? this.cityLockupService.getAllByCountryAndState(countryCode, stateCode)
        : [];

    if (!currentCity) return;

    if (
      !(countryCode && stateCode) ||
      !this.cities.some((city) => city.name === currentCity)
    )
      this.formModel.formGroup.get('address.city')?.reset();
  }

  toggleCompanyInfo(enabled: boolean) {
    console.log(enabled);
    this.enableCompanyInfo = enabled;
    this.formModel.toggleCompanyInfo(enabled);
  }
}
