<div class="sxw-theme">
  <p>{{ value }}</p>
  <p-slider
    [(ngModel)]="value"
    [min]="minValue"
    [max]="maxValue"
    [range]="range"
    [step]="step"
    [styleClass]="sliderClass"
    (onChange)="onTouched()"
    (onSlideEnd)="onChange(value)"
    [disabled]="readonly || disabled"
  >
  </p-slider>
  <sxw-error-message
    *ngIf="
      control &&
      control.errors &&
      (control.touched || sxwForm?.submitted)
    "
    [message]="control ? control.errors : null"
  ></sxw-error-message>
</div>
