export const SPACE_BASE_DATASHAPE = `
    code
    id
    name
    users {
      role {
        permissions
      }
      user {
        id
      }
    }
`;