import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { map, of } from 'rxjs';
import { InvoiceService } from '../services/invoice.service';

export const getInvoiceResolver: (
  getIdCb: (route: ActivatedRouteSnapshot) => string | null
) => ResolveFn<any> = (getIdCb) => (route) => {
  const router = inject(Router);
  const passedInvoice =
    router.getCurrentNavigation()?.extras.state?.['invoice'];

  if (passedInvoice) return passedInvoice;

  const invoiceService = inject(InvoiceService);
  const options = route.data['getInvoiceOptions'];
  const dataShape = options?.['dataShape'];
  const id = getIdCb(route);

  return id
    ? invoiceService
        .getById(id, dataShape)
        .pipe(map((res) => res.data['invoice']))
    : of(null);
};
