<div class="my-3 p-5px">
  <ngx-stripe-elements [elementsOptions]="elementsOptions">
    <app-stripe-express-checkout
      [options]="expressCheckoutOptions"
      [elementsOptions]="elementsOptions"
    ></app-stripe-express-checkout>
    <ngx-stripe-payment [options]="paymentElementOptions"> </ngx-stripe-payment>
  </ngx-stripe-elements>

  <div class="mt-20px" [formGroup]="addPaymentFormGroup">
    <h3>{{ "paymentMethod.form.billingDetailsTitle" | translate }}</h3>
    <app-billing-account-form
      [oneFieldPerRow]="true"
      [separateCompanyInfo]="true"
      [formModel]="billingAccountModel"
      [billingAccount]="defaultBillingInfo"
    ></app-billing-account-form>
    <div class="input-group switch">
      <sxw-radio-input formControlName="isPrimary"></sxw-radio-input>
      <label>{{ "paymentMethod.form.setAsPrimary" | translate }}</label>
    </div>
  </div>
</div>

<button
  (click)="confirmProcess()"
  [disabled]="isLoading"
  class="mt-10px save-button"
>
  {{ submitLabel | translate }}
</button>
