
import { Directive, Input } from '@angular/core';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: '[sxwTooltip]',
  exportAs: 'sxwTooltip',
})
export class SxwTooltipDirective extends Tooltip {
  @Input('sxwTooltip') override text: string = '';
}
