import {CountriesIso} from "../enums/countries-iso";
import { Country } from 'src/app/kernel/form/interfaces/country';
import { MobileIso } from '../enums/mobile-iso';

export const countries: Country[] = [
  {
    name: 'United Arab Emirates',
    iso: CountriesIso.UAE,
    countryIso: MobileIso.UAE,
    code: '+971',
    placeHolder: '50 123 4567',
  },
  {
    name: 'Italy',
    iso: CountriesIso.Italy,
    countryIso: MobileIso.Italy,
    code: '+39',
    placeHolder: '06 9165 8888',
  },
  {
    name: 'United Kingdom',
    iso: CountriesIso.UnitedKingdom,
    countryIso: MobileIso.UnitedKingdom,
    code: '+44',
    placeHolder: '203 808 5555',
  },
  {
    name: 'Egypt',
    iso: CountriesIso.Egypt,
    countryIso: MobileIso.Egypt,
    code: '+20',
    placeHolder: '10 010 1111',
  },
];
