<sxw-side-dialog
  outletName="popups"
  [routeName]="routeName"
  theme="sxw-popup-cards"
>
  <h3 class="side-title">{{ "passwordSide.changePassword" | translate }}</h3>

  <ng-container [formGroup]="resetPasswordFormGroup">
    <div class="input-group">
      <label>{{ "passwordSide.currentPassword" | translate }}</label>
      <sxw-password-input
        #oldPassword
        theme="sxw-theme"
        [placeHolder]="'passwordSide.currentPassword' | translate"
        [validations]="{ isNotEmpty: {} }"
        formControlName="currentPassword"
      ></sxw-password-input>
    </div>
    <div class="input-group">
      <label>{{ "passwordSide.newPassword" | translate }}</label>
      <sxw-password-input
        #newPassword
        theme="sxw-theme"
        [placeHolder]="'passwordSide.newPassword' | translate"
        [validations]="{ isNotEmpty: {}, isStrongPassword: {} }"
        formControlName="password"
      ></sxw-password-input>
    </div>
    <div class="input-group">
      <label>{{ "passwordSide.confirmPassword" | translate }}</label>
      <sxw-password-input
        theme="sxw-theme"
        [placeHolder]="'passwordSide.confirmPassword' | translate"
        [validations]="{
          isNotEmpty: {},
          isStrongPassword: {},
          isConfirmPassword: {
            options: newPassword.value,
            errorOptions: {
              comparisonFieldName: 'newPassword' | translate
            }
          }
        }"
        formControlName="confirmPassword"
      ></sxw-password-input>
    </div>

    <button 
      class="save-button mt-20px" 
      (click)="updatePassword()"
      >{{"actions.saveChanges" | translate}}
    </button>

  </ng-container>
</sxw-side-dialog>
