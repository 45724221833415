import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core'
import { Observable, filter, take } from "rxjs";
import { TranslationLoader } from "./helpers/TranslationLoader";
import {LocalStorageService} from "src/app/kernel/tools/services/local-storage.service";

@Injectable()
export class TranslationService {
  private readonly LANGUAGE_STORAGE_KEY = 'language';

  readonly defaultLang = this.translate.getDefaultLang();

  constructor(
    private translate: TranslateService,
    private translationLoader: TranslationLoader,
    private localStorageService: LocalStorageService
  ) {}

  getUserLang(): string {
    return this.localStorageService.get(this.LANGUAGE_STORAGE_KEY) || this.defaultLang;
  }

  getCurrentLang():string {
    return this.translate.currentLang || this.defaultLang;
  }

  saveUserLang(language: string): void {
    this.use(language);
    this.setDefaultLanguage(language);
    this.localStorageService.set(this.LANGUAGE_STORAGE_KEY, language);
  }

  instant(key: string | string[], interpolateParams?: Object | undefined): any {
    return this.translate.instant(key, interpolateParams);
  }

  setDefaultLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
  }

  getTranslateValue(value: string): Observable<string> {
    return this.translate.get(value);
  }

  use(lang: string): Observable<any> {
    return this.translate.use(lang);
  }

  getLoadedKeys(){
    return this.translationLoader.getLoadedKeys()
  }

  waitUntilTranslationLoaded(pathRegex: string | RegExp) {
    return this.getLoadedKeys()
      .pipe(filter(res => !!res.match(pathRegex)));
  }

  /**
   * Runs (after) Translations files loaded correctly
   */
  waitUntilTranslationsEmits() {
    return this.translationLoader.emitTranslations();
  }
}
