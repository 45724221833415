import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { map, take } from "rxjs";
import { GraphService } from "../../../../../../graphql/services/graph.service";
import {
  FilterInput,
  FiltersGraphqlInput,
  ListingGraphqlInput,
  PaginationGraphqlInput,
} from "../../../../../../graphql/models";
import { HttpHeaders } from "@angular/common/http";

export function isUnique(
  options: {
    query: string;
    match: string | any[];  // to match filters when we have custom field and value config  or string
    errorKey?: string;
    self?: string;
    otherFields: {
      name: string;
      value: string;
    }[]
  },
  graph: GraphService
): AsyncValidatorFn {
  return (control: AbstractControl) => {
    const filterArray: any[] = getMatchFilters(options, control.value);
    const otherFieldsFilters:any[] = prepareOtherFields(options.otherFields);

    return graph
      .constructWatchListQuery(
        ["id"],
        options.query,
        ListingGraphqlInput.wrap(
          new FiltersGraphqlInput(filterArray.concat(otherFieldsFilters)),
          new PaginationGraphqlInput(1, 1),
          null
        ),
        new HttpHeaders()
          .set("ignore_http_loader", "ignore_http_loader")
          .set("op-val", options.self ?? "")
          .set("op", "unique-check"),
      )
      .pipe(
        take(1),
        map((data: any) => {
          const queryData: any[] = data.data[options.query]?.data;
          if (!queryData || !queryData.length) {
            return null;
          }
          return { isUnique: options.errorKey || "isUnique" };
        })
      );
  };
}
// filled filter array depends on the type
function getMatchFilters(options: any, controlValue: any): any[] {

  const filterValue = (value: any): any => { // prepare filter value
    if(typeof value === 'string') {
      return value.trim();
    }

    if(Array.isArray(value)) {
      return value.map(v => filterValue(v));
    }

    return value;
  }

  if(typeof options.match === 'string') {
    let optionType = Array.isArray(controlValue) ? "IN" : "IS";
    return [new FilterInput(options.match, optionType,  filterValue(controlValue) || "")];
  }

  return options.match?.map((option: any) => new FilterInput(option.field, option.option || "IS", filterValue(option.value) || ""));
}

function prepareOtherFields(
  otherFields: {
    name: string;
    value: string;
  }[]
) {
  if(!Array.isArray(otherFields)){
    return [];
  }
  const filtersArray = [];
  for(const field of otherFields){
    let optionType = Array.isArray(field.value) ? "IN" : "IS";
    const filterInput = new FilterInput(field.name,optionType,field.value);
    filtersArray.push(filterInput)
  }

  return filtersArray;
}