import {FormControl, FormGroup} from "@angular/forms";
import { IBaseSpace } from "src/app/chatperk-core/interfaces";

interface CreateSpaceFormGroupInterface {
    name: FormControl<string | null>,
    code: FormControl<string | null>,
}
  
export class CreateSpaceFormModel {

  public formGroup: FormGroup<CreateSpaceFormGroupInterface>;

  constructor(space: IBaseSpace) {
    this.formGroup = this.initForm(space);
  }

  initForm(space: IBaseSpace) {
    return new FormGroup({
      name: new FormControl<string>(space.name),
      code: new FormControl<string>(space.code),
    })
  }

}
