import { Component } from '@angular/core';
import { SpaceService } from 'src/app/applications/dashboard/modules/space/services/space.service';
import {Router} from "@angular/router";
import {MessageService} from "src/app/kernel/tools/message/service/message.service";
import {FormMutationInterface} from "src/app/kernel/form/interfaces/form-mutation.interface";
import { CreateSpaceFormModel } from '../../models/create-space-form.model';
import { IBaseSpace, ISpaceType, IUser } from 'src/app/chatperk-core/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { SPACE_FULL_DATASHAPE } from '../../datashape/space-full-datashape';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';
import { USER_DATASHAPE } from 'src/app/chatperk-core/data-shape';

@Component({
  selector: 'app-add-space',
  templateUrl: './add-space.component.html',
  styleUrls: ['./add-space.component.scss']
})
export class AddSpaceComponent {

  formModel = new CreateSpaceFormModel(<IBaseSpace>{});

  constructor(
    private spaceService : SpaceService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private authService : AuthService
  ){}

  mutationConfig: FormMutationInterface = {
    name: 'createSpace',
    paramName: 'spaceInput',
    paramType: 'SpaceInput!',
    select: [USER_DATASHAPE]
  }

  handleMutationComplete(res: any) {
    const updatedUser = res.data.createSpace as IUser
    this.authService.updateUser(updatedUser)
    this.router.navigate([this.authService.getUser()?.activeSpace.code, 'assistants'])
  }

  handleMutationError(error: any) {
    const msg = this.translateService.instant('errors.something_went_wrong');
    const summary = this.translateService.instant('errors.summary');
    this.messageService.error(msg, summary);
  }

  private sanitizeName(name: string): string {
    return name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  }

  private updateCodeBasedOnName(name: string): void {
    const codeControl = this.formModel.formGroup.controls.code
    codeControl.setValue(this.sanitizeName(name), { emitEvent: false });
  }

  onNameChange(name: string): void {
    this.updateCodeBasedOnName(name);
  }
}
