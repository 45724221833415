import {
  AfterContentInit,
  AfterContentChecked,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ElementRef,
  HostBinding,
  Output,
  EventEmitter,
  inject,
} from "@angular/core";
import { SxwStepComponent } from "src/app/kernel/form/modules/tools/form-stepper/components/sxw-step/sxw-step.component";
import { Subject, filter, fromEvent, map, takeUntil } from "rxjs";
import { SxwFormComponent } from "src/app/kernel/form/components/sxw-form/sxw-form.component";
import { LocalStorageService } from "src/app/kernel/tools/services/local-storage.service";

@Component({
  selector: "sxw-form-stepper",
  templateUrl: "./form-stepper.component.html",
  styleUrls: ["./form-stepper.component.scss"],
})
export class FormStepperComponent implements AfterContentInit, AfterContentChecked, OnDestroy {
  @ViewChild("contentContainer") contentContainerRef?: ElementRef;
  @ContentChildren(SxwStepComponent, { descendants: true }) public steps!: QueryList<SxwStepComponent>;
  @Input() showControllers = true;
  afterContentChecked$ = new Subject<void>();
  currentStep?: SxwStepComponent;
  currentStepIndex = 0;
  lastStepIndex = 999;
  parent?: SxwFormComponent; 
 
  @Output() onMove: EventEmitter<any> = new EventEmitter<any>();
  
  private _unSubscribe = new Subject<void>();
  private preventScrollEndObserver = false;
  private scrollEnd$ = fromEvent(window, "scrollend", { capture: true });
  @Input() scrollableOffset = 20;
  @Input() @HostBinding("class.scrollable") scrollableMode = false;
  @Input("scrollContainer") _scrollContainer?: HTMLElement;
  @Input() showStepName = true;



  get scrollContainer(): HTMLElement | undefined{
    return this._scrollContainer || this.contentContainerRef?.nativeElement;
  }

  ngAfterContentInit() {
    this.steps.forEach((step) => {
      step.setParent(this);
      step.visited = false;
    });
    this.steps.changes.pipe(takeUntil(this._unSubscribe)).subscribe({
      next: (steps: SxwStepComponent[]) => {
        steps.forEach((step) => step.setParent(this));
        this.uploadLastStepIndex();
      },
    });
    this.uploadLastStepIndex();
    this.setCurrentStep(this.currentStepIndex);
    this.observeScrollEnd();
  }

  ngAfterContentChecked(){
    let prevIsEnabled = true;
    this.steps.forEach((step) => {
      if(step.hidden) return;
      if(step.moveEnabled !== prevIsEnabled){
        step.setMoveEnabled(prevIsEnabled);
      }
      prevIsEnabled &&= step.valid(false);
    });
  }

  injectParent(parent: SxwFormComponent) {
    this.parent = parent;
  }

  uploadLastStepIndex() {
    let i = this.steps.length - 1;
    while (i > -1) {
      if (!this.steps.toArray()[i]?.hidden) {
        break;
      }
      i--;
    }
    this.lastStepIndex = i;
  }

  setCurrentStep(index: number) {
    const previousStepName = this.currentStep?.stepName;
    this.currentStep = this.steps?.toArray()?.[index];
    this.steps.forEach((step) => step.setActive(false));
    this.currentStep?.setActive(true);
    this.onMove.emit({
      previousStep: previousStepName,
      currentStep: this.currentStep.stepName
    });
  }

  observeScrollEnd() {
    this.scrollEnd$
      .pipe(
        filter(
          (e) =>
            this.scrollableMode &&
            !this.preventScrollEndObserver &&
            e.isTrusted &&
            e.target instanceof HTMLElement &&
            e.target.contains(this.scrollContainer ?? null)
        ),
        map(()=>
          this.steps
            .toArray()
            .reverse()
            .findIndex(
              (step) => step.element.offsetTop <= this.scrollContainer!.scrollTop + this.scrollableOffset
            )
        ),
        takeUntil(this._unSubscribe)
      )
      .subscribe((reversedStepIndex) => {
        const stepIndex = reversedStepIndex == -1 ? 0 : this.steps.length - reversedStepIndex - 1; 
        if(stepIndex !== -1 && this.currentStepIndex !== stepIndex){
          this.setCurrentStep(stepIndex);
          this.currentStepIndex = stepIndex;
          this.currentStep?.setVisited();
        }
      });
  }

  move(movment: number): void {
    let stepIndex = this.currentStepIndex + movment;
    if (stepIndex < 0) stepIndex = 0;
    else if (stepIndex >= this.steps.length) stepIndex = this.steps.length - 1;

    const step = this.steps.get(stepIndex);
    if (!step?.moveEnabled && !step?.hidden) {
      this.currentStep?.valid();
      return;
    }

    for(let i = this.currentStepIndex; i < stepIndex; i++) {
      this.steps.get(i)?.setDone(true);
    }

    this.currentStepIndex = stepIndex;
    if (step.hidden) return this.move(movment);
    this.setCurrentStep(this.currentStepIndex);
    this.currentStep?.setVisited();
    if (this.scrollableMode && this.currentStep) {
      this.scrollToStep(this.currentStep)
    } else {
      this.scrollTo({ top:0, left:0, behavior: "auto" });
    }
  }

  scrollTo(options: ScrollToOptions) {
    const containerEle = this.scrollContainer;
    if(!containerEle) return;
    options.behavior ??= "smooth"
    containerEle.scrollTo(options)
  }

  scrollToStep(step: SxwStepComponent){
    this.scrollToElement(step.element)
  }

  scrollToElement(ele: HTMLElement){
    if(!this.scrollContainer) return;
    this.preventScrollEndObserver = true;
    this.scrollTo({ top: ele.offsetTop - this.scrollableOffset });
    setTimeout(()=> this.preventScrollEndObserver = false, 1000)
  }

  ngOnDestroy() {
    this._unSubscribe.next();
    this._unSubscribe.complete();
  }
}
