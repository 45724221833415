export class GraphqlBaseInput {

  __paramsDef!: any ;

  addParamsDef(paramsDef: any): GraphqlBaseInput {
    this.__paramsDef = Object.assign(this.__paramsDef ?? {}, paramsDef) ;
    return this ;
  }

  addParams(params: any, paramsDef: any): GraphqlBaseInput {
    this.addParamsDef(paramsDef);
    return Object.assign(this, params);
  }

}


