import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IPaymentMethod } from 'src/app/chatperk-core/interfaces';
import {
  IUpdatePaymentMethodDto,
  IUpdatePaymentMethodFormControls,
} from '../../interfaces/payment-method.interfaces';
import { BillingAccountFormModel } from 'src/app/shared/billing-account/models/billing-account-form.model';

@Component({
  selector: 'app-update-payment-method-form',
  templateUrl: './update-payment-method-form.component.html',
  styleUrls: ['./update-payment-method-form.component.scss'],
})
export class UpdatePaymentMethodFormComponent implements OnChanges {
  @Input() paymentMethod!: IPaymentMethod;
  @Input() billingAccountModel = new BillingAccountFormModel();
  @Output() onUpdatePaymentMethod = new EventEmitter<IUpdatePaymentMethodDto>();
  @Output() onDeletePaymentMethod = new EventEmitter();

  public formGroup!: FormGroup;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['paymentMethod']) this.initFormGroup();
  }

  initFormGroup() {
    this.billingAccountModel.patchValue(this.paymentMethod.billingInfo ?? {});
    this.formGroup = new FormGroup<IUpdatePaymentMethodFormControls>({
      billingInfo: this.billingAccountModel.formGroup,
      isPrimary: new FormControl(!!this.paymentMethod?.isPrimary, {
        nonNullable: true,
      }),
    });
  }

  updatePaymentMethod() {
    this.onUpdatePaymentMethod.emit(this.formGroup.value);
  }

  deletePaymentMethod() {
    this.onDeletePaymentMethod.emit();
  }
}
