import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { PaymentMethodsService } from '../services/payment-methods.service';
import { map, of } from 'rxjs';

export const getPaymentMethodResolver: (
  getIdCb: (route: ActivatedRouteSnapshot) => string | null
) => ResolveFn<any> = (getIdCb) => (route) => {
  const router = inject(Router);
  const passedPaymentMethod =
    router.getCurrentNavigation()?.extras.state?.['paymentMethod'];

  if (passedPaymentMethod) return passedPaymentMethod;

  const paymentMethodService = inject(PaymentMethodsService);
  const options = route.data['getPaymentMethodOptions'];
  const dataShape = options?.['dataShape'];
  const id = getIdCb(route);

  // const id = (route.paramMap.get('id') ||
  //   route.queryParamMap.get('paymentMethodId'))!;

  return id
    ? paymentMethodService
        .getById(id, dataShape)
        .pipe(map((res) => res.data['paymentMethod']))
    : of(null);
};
