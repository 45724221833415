export enum EFeatureKey {
  MessageCredit = "messageCredit",
  ChatAssistant = "chatAssistant",
  CharacterAssistant = "characterAssistant",
  TrainingLinks = "trainingLinks",
  SpaceMember = "spaceMember",
  ViewChatHistory = "viewChatHistory",
  UnlimitedEmbed = "unlimitedEmbed",
  ApiAccess = "apiAccess",
  Integrations = "integrations",
  LatestModels = "latestModels",
  AnalyticsDashboard = "analyticsDashboard",
  RemoveLicense = "removeLicense",
  CustomDomains = "customDomains",
}
