export const SPACE_FULL_DATASHAPE = `
    code
    createdAt
    id
    isActive
    name
    updatedAt
    createdBy {
      email
      id
      personalInfo {
        name
        surname
      }
    }
    users {
      role {
        permissions
        code
        name
        id
      }
      user {
        id
        email
        personalInfo {
          name
          surname
        }
      }
      createdAt
    }
`;