export const USER_DATASHAPE = `
    id
    email
    mobile
    type
    isActive
    hasVerifiedMobile
    hasVerifiedEmail
    personalInfo {
      name
      surname
    }
    createdAt
    updatedAt
    is2FAEnabled
    roles {
      id
      name
      code
      permissions
    }
    activeSpace {
      id
      name
      code
      users {
        role {
          id  
          code
          name
          permissions
        }
        user{
          id
          email
          personalInfo {
            name
            surname
          }
        }
      }
    }
`;