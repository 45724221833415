import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
    {
        path: 'auth',
        loadChildren: () =>
          import('./modules/customer-auth/customer-auth.module').then(
            (m) => m.CustomerAuthModule
          ),
      },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
