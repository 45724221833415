<div class="{{ theme }}">
  <p-calendar
    appendTo="body"
    [baseZIndex]="9999"
    [minDate]="minDate"
    [maxDate]="maxDate"
    inputId="basic"
    [(ngModel)]="dateValue"
    (onSelect)="onTouched(); select.emit(_value)"
    (onBlur)="onTouched()"
    (ngModelChange)="onChange(_value)"
    (onInput)="onType()"
    (keydown.enter)="_value && select.emit(_value)"
    [dateFormat]="dateFormat"
    [readonlyInput]="readonlyInput || readonly"
    class="full-width-input"
    [disabled]="disabled || readonly"
    [ngClass]="{
      'invalid-input':
        control &&
        control.errors &&
        (control.touched || sxwForm?.submitted)
    }"
    styleClass="full-width-input"
    [showIcon]="showInputIcon"
    placeholder="{{ placeholder | translate }}"
  >
  </p-calendar>
  <ng-container [ngTemplateOutlet]="timeIcon ? haveIcon : notHaveIcon">
  </ng-container>
  <ng-template #haveIcon>
    <sxw-virtual-container [icon]="icon" height="100%">
      <label class="time-picker-label" *ngIf="timePickerLabel">{{
        timePickerLabel | translate
      }}</label>
      <sxw-dropdown-input
        *ngIf="showTimePicker"
        inputId="basic"
        [readonly]="readonlyInput || readonly || disabled"
        [theme]="timePickerTheme"
        [(ngModel)]="timeValue"
        placeholder="{{ timePickerPlaceholder | translate }}"
        (blur)="onTouched()"
        (ngModelChange)="onChange(_value)"
        ngDefaultControl
        [options]="time"
        [isFilter]="true"
        [optionLabel]="'name'"
        [optionValue]="'value'"
        [readonly]="readonly"
        [disabled]="disabled"
      >
      </sxw-dropdown-input>
    </sxw-virtual-container>
  </ng-template>
  <ng-template #notHaveIcon>
    <div class="time-picker-container">
      <label class="time-picker-label" *ngIf="timePickerLabel">{{
        timePickerLabel | translate
      }}</label>
      <sxw-dropdown-input
        *ngIf="showTimePicker"
        [readonly]="readonlyInput || readonly || disabled"
        inputId="basic"
        [theme]="timePickerTheme"
        [(ngModel)]="timeValue"
        placeholder="{{ timePickerPlaceholder | translate }}"
        (ngModelChange)="onChange(_value)"
        (blur)="onTouched()"
        ngDefaultControl
        [options]="time"
        [isFilter]="true"
        [optionLabel]="'name'"
        [optionValue]="'value'"
        [readonly]="readonly"
        [disabled]="disabled"
      >
      </sxw-dropdown-input>
    </div>
  </ng-template>

  <sxw-error-message
    [customStyles]="{
      'margin-bottom': '0'
    }"
    *ngIf="
      control &&
      control.errors &&
      (control.touched || sxwForm?.submitted)
    "
    [message]="control ? control.errors : null"
  ></sxw-error-message>
</div>
