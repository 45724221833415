import { toDate } from '../../../../../../shared/helpers/date-helper/date.helper';
import { ValidatorsOptionsInterface } from "../../interfaces/validators-options.interface";

export function isConfirmPassword(newPassword: string, oldPassword: string, options?: ValidatorsOptionsInterface):
  Record<string, ValidatorsOptionsInterface> | null {
    if(!newPassword || !oldPassword) return null;

    return newPassword && oldPassword && newPassword !== oldPassword ?
    {
      isConfirmPassword: {
        message: options?.message || 'isConfirmPassword',
        comparisonFieldName: options?.comparisonFieldName || ''
      }
    } : null;
}
