<div class="error-page-container">
  <div class="page-center">
    <img class="error-image" src="/assets/images/illustrations/access_denied.svg">
    <!-- TODO: Use translation.   -->
    <h1 class="error-code">403</h1>
    <div class="error-message">You dont have permission to access this data.</div>
    <div class="back-home-button">
      <sxw-button [routerLink]="['/']" label="Back to Home"></sxw-button>
    </div>
  </div>
</div>
