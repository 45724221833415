import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';
import { SIDE_DIALOG_TOKEN } from 'src/app/kernel/popups/side-popup/tokens/side-dialog.token';
import { ISideDialog } from 'src/app/kernel/popups/side-popup/types/side-dialog.interface';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { catchError, finalize, map, switchMap, takeWhile, tap, timer } from 'rxjs';
import { EOtpReason } from 'src/app/chatperk-core/enums';
import { IUser } from 'src/app/chatperk-core/interfaces';



@Component({
  selector: 'app-two-factor-edit',
  templateUrl: './two-factor-edit.component.html',
  styleUrls: ['./two-factor-edit.component.scss']
})
export class TwoFactorEditComponent {
  routeName!: string;
  otpControl = new FormControl<string | null>(null);

  isVerifyView = false;
  disabled = false;
  resendLoading = false;
  resendLabel = this.translateService.instant('mobileSide.resendTheCode');
  verifyLoading = false;
  user = this.authService.getUser();

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.routeName = '/account';
  }

  resendOtp() {
    console.log('resendOtp');
    this.resendLoading = true;
    
    // Call authService to resend OTP
    this.authService.resendOtp(EOtpReason.TwoFA).pipe(
      tap(() => {        
        this.disabled = true;
        this.resendLoading = false;
        // Initialize the resend label
        this.resendLabel = this.translateService.instant('mobileSide.resendTheCode') + `(0:10)`;
        // Show success message
        this.messageService.success('The verification OTP has been sent successfully!', 'Done');
      }),
      switchMap(() => {
        return timer(0, 1000).pipe(
          map(i => 300  - i), 
          takeWhile(val => val >= 0, true),
          tap(count => {
            const minutes = Math.floor(count / 60);
            const seconds = count % 60;
            this.resendLabel = this.translateService.instant('mobileSide.resendTheCode') + `(${minutes}:${seconds < 10 ? '0' + seconds : seconds})`;
          }),
          finalize(() => {            
            // Reset label and enable the button after the countdown finishes
            this.resendLabel = this.translateService.instant('mobileSide.resendTheCode');
            this.disabled = false;
          })
        );
      }),
      catchError((error) => {
        // Handle the error case here, showing an error message
        this.resendLoading = false;
        this.messageService.error('Failed to resend the OTP, please try again.', 'Error');
        return [];
      })
    ).subscribe();
  }

  verifyOtp() {
    if(this.otpControl.invalid) return;
    this.verifyLoading = true;
    return this.authService.verifyEnable2FA(this.otpControl.value!).pipe(
      tap(({data}) => this.handleSuccessResponse(data))
    )
    .pipe(finalize(() => this.verifyLoading = false))
    .subscribe({
      next: () => {
       
      },
    });
  }
  

  handleSuccessResponse(data?: IUser) {
    this.authService.updateUser(data!);
    this.messageService.success('The Two Factor Authentication activated successfully!', 'Done');
    this.router.navigateByUrl(this.routeName).then(() => {
      window.location.reload();
  });
  }

  handleReqErrors(err: any) {
    const msg = this.translateService.instant('errors.something_went_wrong');
    const summary = this.translateService.instant('errors.summary');
    this.messageService.error(msg, summary);
  }


  goToViewPage() {
    this.router.navigateByUrl(this.routeName);
  }
}
