import { Component } from '@angular/core';
import { ISideNavConfig} from "src/app/layout/sidenav/interfaces/ISideNavConfig";

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent {
  // sideNavConfig: ISideNavConfig = {
  //   items: [
  //     {
  //       title: '',
  //       items: [
  //         { label: 'sidenav.assistant', icon: 'chat-assistant', routerLink: { path: '/assistants' } },
  //         { label: 'sidenav.analytics', icon: 'bar-chart', routerLink: { path: '/dashboard/statistics' } },
  //         { label: 'sidenav.settings', icon: 'settings', routerLink: { path: '/dashboard/passengers' } },

  //       ]
  //     },
  //   ]
  // }
}