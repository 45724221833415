import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input() theme = 'sxw-theme';
  @Input() value: number | null = null;
  @Input() showValue = true;
  @Input() inlineStyle: Record<string, string> | null = null;
  @Input() styleClass = 'progress-loading';
  @Input() unit = '%';
  @Input() mode = 'indeterminate';

}
