import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAuthorizedComponent } from 'src/app/core/error-pages/not-authorized/not-authorized.component';

const routes: Routes = [
  {
    path: '403',
    component: NotAuthorizedComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }),
  ],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
