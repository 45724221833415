import {Component, forwardRef, Host, Input, Optional} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { CustomInput } from "src/app/kernel/form/classes/custom-input";
import {SxwFormComponent} from "src/app/kernel/form/components/sxw-form/sxw-form.component";

@Component({
  selector: "sxw-password-input",
  templateUrl: "./password-input.component.html",
  styleUrls: ["./password-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    }
  ],
})
export class PasswordInputComponent extends CustomInput<string> {
  @Input() label!: string;
  @Input() placeHolder = "enterPassword";
  @Input() toggleMask = true;
  @Input() feedback = false;
  @Input() override readonly = false;
  @Input() theme = "sxw-theme";
  constructor(@Optional() @Host() public sxwForm?: SxwFormComponent) {
    super();
  }

  override get summaryValue(): any {
    return '*'.repeat(this.value?.length || 0)
  }
}
