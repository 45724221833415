import { Component, OnInit,HostListener,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MutationResult } from 'apollo-angular';
import { SpaceService } from 'src/app/applications/dashboard/modules/space/services/space.service';
import { ISpaceType, IUser } from 'src/app/chatperk-core/interfaces';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';



@Component({
  selector: 'app-space-dropdown',
  templateUrl: './space-dropdown.component.html',
  styleUrls: ['./space-dropdown.component.scss']
})
export class SpaceDropdownComponent implements OnInit {

  spaces: ISpaceType[] = [];
  selectedSpace: ISpaceType | null = null;
  showDropdown: boolean = false;

  //Listing Data
  searchQuery: string = '';
  searchSubject: Subject<string> = new Subject<string>();
  page: number = 1;

  //Infinity scroll attrs
  scrollDistance = 2;
  throttle = 300;


  constructor(
    private authService: AuthService,
    private spaceService: SpaceService,
    private router : Router,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private messageService: MessageService
  ){}

  ngOnInit() {
    this.getSpacesData()
    this.selectedSpace = this.authService.getUser()!.activeSpace ?? null
    this.searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(query => {
        this.page = 1;
        this.spaces=[]
        this.getSpacesData()
      });
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  getSpacesData(){
    const filters = []
    if(this.searchQuery != ''){
      const filter = {
        field:"name",
        option:"LIKE",
        value:this.searchQuery,
      }
      filters.push(filter)
    }
    this.spaceService.getUserSpaces(this.page,filters).subscribe({
      next: this.handleGetSpaceReqSuccess.bind(this),
      error: this.handleSpaceeReqErrors.bind(this),
    });
  }

  handleGetSpaceReqSuccess(data: any){
    const res = data.data as ISpaceType[];
    this.spaces = this.spaces.concat(res);
  }

  handleSpaceeReqErrors(err: any){
  }

  onInputChanged(query: string): void {
    this.searchSubject.next(query);
  }

  onScroll(): void {
    this.page++;
    this.getSpacesData();
  }

  selectSpace(space: ISpaceType): void {
    if(this.selectedSpace?.id != space?.id){
      this.selectedSpace = space;
      this.showDropdown = false;
      this.changeTheSpace()
    }
  }

  changeTheSpace() {
    this.spaceService.changeActiveSpace(this.selectedSpace!.id).subscribe({
      next: this.handleChangeSpaceReqSuccess.bind(this),
      error: this.handleChangeSpaceReqErrors.bind(this),
    });
  }

  handleChangeSpaceReqSuccess({ data }: MutationResult<IUser>) {
    this.authService.updateUser(data as IUser);
    this.router.navigate([data?.activeSpace.code, 'assistants'],)
  }

  handleChangeSpaceReqErrors(err: any) {
    const msg = this.translateService.instant('errors.something_went_wrong');
    const summary = this.translateService.instant('errors.summary');
    this.messageService.error(msg, summary);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showDropdown = false ;
    }
  }
}
