import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { CustomInput } from '../../../../../classes/custom-input';

@Directive({
  selector: '[sxwFormLabel]',
})
export class FormLabelDirective implements AfterContentInit {
  public labelElement!: HTMLLabelElement;

  @Input('sxwFormLabel') input?: CustomInput | any;
  @Input() groupName?: string;
  constructor(private el: ElementRef<HTMLLabelElement>) {}

  ngAfterContentInit(): void {
    this.labelElement = this.el.nativeElement;
  }
}
