import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  resetPasswordFormGroup!: FormGroup;
  routeName!: string;
  user = this.authService.getUser();

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.routeName = `/account`;
  }

  updatePassword() {
    if (!this.resetPasswordFormGroup.valid) {
      return this.resetPasswordFormGroup.markAllAsTouched();
    }
    const value = this.resetPasswordFormGroup.value;
    this.authService
      .updateMyPassword({
        currentPassword: value.currentPassword,
        newPassword: value.password,
      })
      .subscribe({
        next: (_) => {
          this.goToViewPage();
          const msg = this.translateService.instant('passwordSide.passwordResetUpdated');
          const summary = this.translateService.instant('success');
          this.messageService.success(msg, summary);
        },
        error: this.handleResolverError.bind(this),
      });
  }

  initForm() {
    this.resetPasswordFormGroup = new FormGroup({
      password: new FormControl(),
      currentPassword: new FormControl(),
      confirmPassword: new FormControl(),
    });
  }

  handleResolverError(error: any) {
    const msg = this.translateService.instant('errors.something_went_wrong');
    const summary = this.translateService.instant('errors.summary');
    this.messageService.error(msg, summary);
  }

  goToViewPage() {
    this.router.navigateByUrl(this.routeName);
  }
}
