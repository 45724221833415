import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Icon } from '../../enums/icon.enum';

@Component({
  selector: 'sxw-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnInit {
  @Input() name: Icon | string | null | undefined = '';
  @Input() fillColor = '#000';
  @Input() width: string = '24';
  @Input() height: string = '24';
  @Input() ngStyle: any = {
    display: 'flex',
    width: '100%',
    height: '100%',
  }

  svgIcon: string | SafeHtml = '';
  constructor(
  ) {}

  ngOnInit(): void {
    this.initSvgIcon();
  }
  ngOnChanges(): void {
    this.initSvgIcon();
  }

  private initSvgIcon(): void {
    if (!this.name) {
      this.svgIcon = '';
      return;
    }
    /**
     * This string represents the path to the svg and the last segment represents the id of the selected svg
     * Please note that when adding a new svg we need to set id attribute on the svg element
     */
    this.svgIcon = `assets/images/icons/${this.name}.svg#${this.name}`;
  }
}
