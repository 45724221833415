import validator from 'validator';

export function isVAT(value: string, options: any) {

  const vatMatchers: any = {
    // Chile: 8 or 9 characters(Last one can be K)
    CL: /^(CL)?\d{8}-[0-9K]$/,
    // Ireland: 8 or 9 characters. Includes one or two letters (last, or second and last, or last 2)
    IE: /^(IE)?(?=.{8,9}$)(?:[^a-zA-Z]*[a-zA-Z]){1,2}[^a-zA-Z]*$/
  }

  const validVAT = options in vatMatchers ? vatMatchers[options].test(value): validator.isVAT(value, options);
  return validVAT ? null : ({isVAT: {message: 'isVAT'}});
}
