<div class="my-3 p-5px" [formGroup]="formGroup">
  <div class="saved-payment-section">
    <app-payment-method-item
      [paymentMethod]="paymentMethod"
      [showViewButton]="false"
    ></app-payment-method-item>
  </div>

  <h3>{{ "paymentMethod.form.billingDetailsTitle" | translate }}</h3>
  <app-billing-account-form
    [oneFieldPerRow]="true"
    [separateCompanyInfo]="true"
    [formModel]="billingAccountModel"
  ></app-billing-account-form>
  <div class="input-group switch">
    <sxw-radio-input formControlName="isPrimary"></sxw-radio-input>
    <label>{{ "paymentMethod.form.setAsPrimary" | translate }}</label>
  </div>
</div>

<div class="d-flex mt-10px gap-2px">
  <button (click)="updatePaymentMethod()" class="save-button update-button">
    {{ "paymentMethod.buttons.update" | translate }}
  </button>

  <button (click)="deletePaymentMethod()" class="save-button delete-button">
    {{ "paymentMethod.buttons.remove" | translate }}
  </button>
</div>
