import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IPaymentMethod } from 'src/app/chatperk-core/interfaces';
import { CustomInput } from 'src/app/kernel/form/classes/custom-input';

@Component({
  selector: 'app-payment-methods-list[paymentMethods]',
  templateUrl: './payment-methods-list.component.html',
  styleUrls: ['./payment-methods-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentMethodsListComponent),
      multi: true,
    },
  ],
})
export class PaymentMethodsListComponent extends CustomInput<IPaymentMethod> {
  @Input() paymentMethods!: IPaymentMethod[];
  @Input() showAddButton?: boolean;
  @Input() addButtonText?: string;
  @Input() showViewButton?: boolean;
  @Input() viewButtonIcon: string = 'pi pi-pencil';
  @Output() onSelectedChange = new EventEmitter<
    IPaymentMethod | null | undefined
  >();
  @Output() onViewPaymentMethod = new EventEmitter<IPaymentMethod>();
  @Output() onOpenAddPaymentMethod = new EventEmitter<void>();

  viewPaymentMethod(paymentMethod: IPaymentMethod) {
    this.onViewPaymentMethod.emit(paymentMethod);
  }

  openAddPaymentMethod() {
    this.onOpenAddPaymentMethod.emit();
  }

  handleSelectionChange(paymentMethod: IPaymentMethod) {
    this.onChange(paymentMethod);
    this.writeValue(paymentMethod);
    this.onSelectedChange.emit(paymentMethod);
  }
}
