
export function isLandlineNumber(value: string, options: any) {
  const landlineMatchers: any = {
    // 'it-IT': /^(?:\+39)?(?:\d{2,3}-?)?\d{3,5}-?\d{4}$/,
    'it-IT': /^(?:\+39)?(0)(\d{5,10})$/,
    'en-GB': /^(?:\+44|0)(?:(?:20[3-7]|23[5-9]|24[0-9]|12[1-9]|13[0-9]|14[1-8]|15[0-9]|16[1-6]|17[1-9]|18[1-9]|19[0-3])\d{7}|(?:(?:1(?:3[0-2]|47|61|98)|2(?:03|07|09|24|50|62|80)|3(?:03|25|32|38|44|54|61|70|85)|5(?:05|25|38|48|55|63|76)|7(?:02|13|24|33|48|56|67|77|84)|8(?:23|42|52|63|72|84)|9(?:02|19|31|42|54|62|74))\d{6}))$/,
    'ar-EG': /^(?:\+20|0)(?:(?:10|11|12|13|14|15|16|17|18|19|2|3|4|5|6|8)\d{8}|(?:2|3|4|5|6)\d{7})$/
  }
  const test = options in landlineMatchers && landlineMatchers[options].test(value);
  return test ? null : ({ isLandlineNumber: { message: 'isLandlineNumber' } })  
}
