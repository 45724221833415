/**
 * Represents Graphql Single Filter Input
 */
import {GraphqlBaseInput} from "src/app/kernel/graphql/models/graphql-base.input";

export class FilterInput {

  field = ``
  option = ``
  value = ``

  /**
   * @param field
   * @param option
   * @param value
   */
  constructor(field: string, option: string, value: string) {
    this.field = field;
    this.option = option
    this.value = value
  }

}

/**
 * Represents Graphql Filters Input
 */
export class FiltersGraphqlInput extends GraphqlBaseInput {

  filters: FilterInput[] = [];
  condition = `SELECT`

  /**
   * @param filters
   * @param condition
   */
  constructor(filters: FilterInput[] | undefined, condition = 'AND') {
    super();
    this.filters = Array.isArray(filters) ? filters.map(f => { f.value = `${f.value}`; return f; }) : [];
    this.condition = condition ?? 'AND';
  }

  wrap() {
    return {filters: this.filters, condition: this.condition ?? 'SELECT'};
  }


}
