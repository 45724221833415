import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { ProfileService } from '../../services/profile.service';
// import { ICompany } from 'src/app/vogo-core/interfaces/company.interfaces';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';
import { MutationResult } from 'apollo-angular';
import { IUser } from 'src/app/chatperk-core/interfaces';
import { SIDE_DIALOG_TOKEN } from 'src/app/kernel/popups/side-popup/tokens/side-dialog.token';
import { ISideDialog } from 'src/app/kernel/popups/side-popup/types/side-dialog.interface';
import { EOtpReason } from 'src/app/chatperk-core/enums';
import { catchError, finalize, map, switchMap, takeWhile, tap, timer } from 'rxjs';

@Component({
  selector: 'app-email-edit',
  templateUrl: './email-edit.component.html',
  styleUrls: ['./email-edit.component.scss'],
})
export class EmailEditComponent implements OnInit{
  routeName!: string;
  formControl!: FormControl;
  otpControl = new FormControl<string | null>(null);

  @ViewChild(SIDE_DIALOG_TOKEN) sideDialog?: ISideDialog
  msg = this.translateService.instant('errors.notAuthorized');
  emailLoading = false;
  isVerifyView = false;
  disabled = false;
  resendLoading = false;
  resendLabel = this.translateService.instant('emailSide.resendTheCode');
  verifyLoading = false;
  user = this.authService.getUser();

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService
  ){}

  ngOnInit(): void {
    
    this.formControl = new FormControl({value:this.authService.getUser()?.email,disabled:false});
    this.routeName = '/account';
  }

  updateEmail() {
    
    if (!this.formControl.valid) {
      return this.formControl.markAllAsTouched();
    }
    if (this.authService.getUser()?.email === this.formControl.value) {
      return this.goToViewPage();
    }
    this.authService
      .updateEmail(
       this.formControl.value
      )
      .subscribe({
        next: this.handleChangeEmailResponse.bind(this),
      });
  }

  handleChangeEmailResponse({ data }: MutationResult<any>) {
    this.messageService.success('The verification OTP has been sent successfully!', 'Done');
    this.isVerifyView = true;

  }

  resendOtp() {
    console.log('resendOtp');
    this.resendLoading = true;
    this.authService.resendOtp(EOtpReason.VerifyEmail).pipe(
      tap(() => {        
        this.disabled = true;
        this.resendLoading = false;
        // Initialize the resend label
        this.resendLabel = this.translateService.instant('emailSide.resendTheCode') + `(0:10)`;
        console.log(this.resendLabel);
        // Show success message
        this.messageService.success('The verification OTP has been sent successfully!', 'Done');
      }),
      switchMap(() => {
        return timer(0, 1000).pipe(
          map(i => 300  - i),
          takeWhile(val => val >= 0, true),
          tap(count => {
            const minutes = Math.floor(count / 60);
            const seconds = count % 60;
            this.resendLabel = this.translateService.instant('emailSide.resendTheCode') + `(${minutes}:${seconds < 10 ? '0' + seconds : seconds})`;
          }),
          finalize(() => {            
            // Reset label and enable the button after the countdown finishes
            this.resendLabel = this.translateService.instant('emailSide.resendTheCode');
            this.disabled = false;
          })
        );
      }),
      catchError((error) => {
        // Handle the error case here, showing an error message
        this.resendLoading = false;
        this.messageService.error('Failed to resend the OTP, please try again.', 'Error');
        return [];
      })
    ).subscribe();
  }

  verifyOtp() {
    if(this.otpControl.invalid) return;
    this.verifyLoading = true;
    return this.authService.verifyContactMethod(this.otpControl.value!).pipe(
      tap(({data}) => this.handleSuccessResponse(data?.verifyContactMethod))
    )
    .pipe(finalize(() => this.verifyLoading = false))
    .subscribe({
      next: () => {
        this.sideDialog?.hideSidebar()
      },
    });
  }

  handleSuccessResponse(data?: IUser) {
    this.authService.updateUser(data!);
    this.messageService.success('The email changed successfully!', 'Done');
    this.router.navigateByUrl(this.routeName).then(() => {
      window.location.reload();
  });
  }

  goToViewPage() {
    this.router.navigateByUrl(this.routeName);
  }
}
