import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SpaceService } from '../services/space.service';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';

export const spaceResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const spaceService = inject(SpaceService);
  const authService = inject(AuthService);
  const _unsubscribeAll = new Subject<any>();
  const router = inject(Router);

  const dataShape = route.data['dataShape'];

  return new Observable((observer) => {
    const spaceId = authService.getUser()!.activeSpace?.id;
    spaceService.getSpaceById(spaceId, dataShape).pipe(takeUntil(_unsubscribeAll))
      .subscribe({
        next: (result: any) => {
          if(!result) {
            router.navigate(['/space/add']);
          }          
          observer.next(result);
          observer.complete();
        },
        error: (error: any) => {  router.navigate(['/space/add']);}
      });
    return () => {
      _unsubscribeAll.next(true);
      _unsubscribeAll.complete();
    };
  });
};
