import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import {FormGroup, ValidationErrors, ɵFormGroupRawValue, ɵFormGroupValue} from "@angular/forms";
import { FormMutationInterface } from "src/app/kernel/form/interfaces/form-mutation.interface";
import { GraphService } from "../../../graphql/services/graph.service";
import { Observable, Subject, takeUntil } from "rxjs";
import { MutationResult } from "apollo-angular/types";
import { FormStepperComponent } from "../../modules/tools/form-stepper/components/form-stepper/form-stepper.component";

@Component({
  selector: "sxw-form",
  templateUrl: "./sxw-form.component.html",
  styleUrls: ["./sxw-form.component.scss"],
})
export class SxwFormComponent implements OnDestroy {
  @Input() mutationConfig!: FormMutationInterface;
  @Input() formGroup: FormGroup = new FormGroup<any>({});
  @Input() formatData!: (data: any) => any;
  @Input() theme = "sxw-theme";
  @Input() editMode: boolean = true;
  @Input() extraParamsValues: Record<string, unknown> = {};
  @Input() validateFn?: (touch: boolean) => boolean; // TODO: it should return validation errors instead of boolean

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMutationComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMutationError: EventEmitter<any> = new EventEmitter<any>();
  @Output() onValidationError: EventEmitter<ValidationErrors | null> = new EventEmitter<ValidationErrors | null>();

  @ContentChild(FormStepperComponent) private stepper!: FormStepperComponent;

  submitted = false;

  private _unsubscribe = new Subject<void>();

  @Input() loading = false;

  constructor(private graphService: GraphService) {}

  ngAfterViewInit() {
    this.stepper?.injectParent(this);
  }

  /**
   * Resets the form
   */
  reset() {
    this.formGroup.reset();
    this.formGroup.markAsUntouched();
    this.formGroup.markAsPristine();
    this.submitted = false;
  }

  /**
   * Patches the value of the FormGroup.
   * It accepts an object with control names as keys, and does its best to match the values to the correct controls in the group.
   * @param values
   */
  patchValue(
    value: ɵFormGroupValue<any>,
    options?: { onlySelf?: boolean; emitEvent?: boolean }
  ): void {
    this.formGroup.patchValue({ ...value }, options);
  }

  /**
   * Sets the value of the FormGroup.
   * It accepts an object that matches the structure of the group, with control names as keys.
   * @param values
   */
  setValue(
    value: ɵFormGroupRawValue<any>,
    options?: { onlySelf?: boolean; emitEvent?: boolean }
  ): void {
    this.formGroup.setValue({ ...value }, options);
  }

  /**
   * Mark all controls as touched and return the validation result.
   */
  validate(touch = false) {
    if (touch) {
      this.formGroup.markAllAsTouched();
    }
    const hasValidAdditionalValidation = !this.validateFn || this.validateFn(touch);
    return this.formGroup.valid && hasValidAdditionalValidation;
  }

  /**
   * Called when the form is submitted
   */
  submit(): void {
    if (!this.validate(true)) {
      return this.formIsInvalid();
    }

    const formData = this.getFormData();
    this.onSubmit.emit(formData);
    this.submitted = true;
    this.handleMutation(formData);
  }

  /**
   * if there is a format function on the data it will call it, or it will return the formData
   */
  getFormData() {
    if (typeof this.formatData === "function") {
      return this.formatData(this.formGroup.value);
    }
    return { ...this.formGroup.value };
  }

  /**
   * Called when the form is invalid
   */
  formIsInvalid(): void {
    this.onValidationError.emit(this.formGroup.errors)
  }

  /**
   * Handles sending mutation request using graph service
   */
  handleMutation(formData: any): void {
    if (!this.mutationConfig?.name) return;
    this.loading = true;
    this.sendMutationRequest(formData)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe({
        next: (response) => {
          this.onMutationComplete.emit(response);
          this.loading = false;
        },
        error: (error) => {
          this.onMutationError.emit(error);
          this.loading = false;
        },
      });
  }

  /**
   * Sends mutation request to graph service
   */
  private sendMutationRequest(formData: any): Observable<MutationResult<any>> {
    return this.graphService
      .constructMutation(
        this.mutationConfig.name,
        {
          [this.mutationConfig.paramName]: this.mutationConfig.paramType,
          ...this.mutationConfig.extraParams,
        },
        {
          [this.mutationConfig.paramName]: formData,
          ...this.evaluateExtraParamsValues(),
        },
        this.mutationConfig.select ?? ["id"]
      )
      .pipe(takeUntil(this._unsubscribe));
  }

  /**
   * Evaluate extra params functions
   */
  private evaluateExtraParamsValues() {
    return Object.fromEntries(
      Object.entries(this.extraParamsValues).map(([key, value]) => [
        key,
        typeof value === "function" ? value() : value,
      ])
    );
  }

  /**
   * Marks the form as not submitted.
   */
  markAsNotSubmitted() {
    this.submitted = false;
  }

  /**
   * Angular lifecycle hook
   */
  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
