import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IBillingAccountDto,
  IBillingAccountFormControls,
  IBillingAddressControls,
} from 'src/app/chatperk-core/interfaces';

export class BillingAccountFormModel {
  public formGroup: FormGroup<IBillingAccountFormControls> =
    this.createFormGroup();

  public patchValue(billingAccount: Partial<IBillingAccountDto>) {
    this.formGroup.patchValue(billingAccount);
  }

  public resetValue() {
    this.formGroup.reset();
  }

  private createFormGroup(): FormGroup<IBillingAccountFormControls> {
    return new FormGroup<IBillingAccountFormControls>({
      firstName: new FormControl('', {
        nonNullable: true,
      }),
      lastName: new FormControl('', {
        nonNullable: true,
      }),
      orgName: new FormControl(),
      taxId: new FormControl(),
      email: new FormControl(),
      mobile: new FormControl(),
      address: new FormGroup<IBillingAddressControls>({
        country: new FormControl(),
        city: new FormControl(),
        state: new FormControl(),
        zip: new FormControl(),
        line1: new FormControl(),
        line2: new FormControl(),
      }),
    });
  }

  countryChange() {
    return this.formGroup.controls.address.controls.country.valueChanges;
  }

  stateChange() {
    return this.formGroup.controls.address.controls.state.valueChanges;
  }

  cityChange() {
    return this.formGroup.controls.address.controls.city.valueChanges;
  }

  toggleCompanyInfo(enabled: boolean) {
    if (enabled) {
      this.formGroup.controls.orgName.enable();
      this.formGroup.controls.taxId.enable();
      this.formGroup.controls.address.enable();
    } else {
      this.formGroup.controls.orgName.disable();
      this.formGroup.controls.taxId.disable();
      this.formGroup.controls.address.disable();
    }
  }

  get value(): IBillingAccountDto {
    return this.formGroup.value as IBillingAccountDto;
  }
}
