import { Component } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'sxw-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  constructor(public config: DynamicDialogConfig, private ref: DynamicDialogRef) { }

  accept() {
    this.ref.close(true);
  }

  reject() {
    this.ref.close(false);
  }

}
