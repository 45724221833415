import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ValidatorService } from '../../services/validate.service';
import { IDynamicFormValidator } from 'src/app/kernel/dynamic-form/interfaces/validator.interface';
import { IDynamicFormValidation } from 'src/app/kernel/dynamic-form/interfaces';


@Directive({
  selector: '[validations]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidatorDirective, multi: true }],
})
export class ValidatorDirective 
  implements OnChanges, Validator, IDynamicFormValidator {

    /**
     * Contain all validation options
     */
    @Input('validations') validations: any = {};
    private control: AbstractControl | undefined;

    constructor(private validatorService: ValidatorService) {
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes['validations']) {
        this.control?.updateValueAndValidity();
      }
    }

    useValidations(validations: IDynamicFormValidation): void {
      this.validations = validations
    }

  /**
     * Use with Reactive Form to add custom validations
     * @param control
     * @returns
     */
    validate(control: AbstractControl): ValidationErrors | null {
      if(!this.control) { this.control = control }
      return this.validatorService.validatorHandler(control, this.validations)
    }
}
