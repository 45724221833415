import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { accessTokenGuard } from 'src/app/chatperk-core/guards/access-token.guard';
import { EAccessType, EUserType } from 'src/app/chatperk-core/enums';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { EmailEditComponent } from './side-popups/email-edit/email-edit.component';
import { DashboardLayoutComponent } from 'src/app/layout/dashboard-layout/component/dashboard-layout.component';
import { fetchMyProfileResolver } from './resolvers/fetch-myprofile.resolver';
import { CommonModule } from '@angular/common';
import { MobileEditComponent } from './side-popups/mobile-edit/mobile-edit.component';
import { ResetPasswordComponent } from './side-popups/reset-password/reset-password.component';
import { TwoFactorEditComponent } from './side-popups/two-factor-edit/two-factor-edit.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        component: AccountSettingsComponent,
        canActivate: [accessTokenGuard],
        resolve: {data: fetchMyProfileResolver},
        data: {
          allowedAccessToken: [EAccessType.FAT],
          allowedTypes: [EUserType.Customer]
        },
        children: [
          {
            path: 'email',
            outlet: 'popups',
            component: EmailEditComponent,
            canActivate: [accessTokenGuard],
            data: {
              allowedAccessToken: EAccessType.FAT,
              allowedTypes: [EUserType.Customer],
            },
          },
          {
            path: 'mobile',
            outlet: 'popups',
            component: MobileEditComponent,
            canActivate: [accessTokenGuard],
            data: {
              allowedAccessToken: EAccessType.FAT,
              allowedTypes: [EUserType.Customer],
            },
          },
          {
            path: 'reset-password',
            outlet: 'popups',
            component: ResetPasswordComponent,
            canActivate: [accessTokenGuard],
            data: {
              allowedAccessToken: EAccessType.FAT,
              allowedTypes: [EUserType.Customer],
            },
          },
          {
            path: '2fa',
            outlet: 'popups',
            component: TwoFactorEditComponent,
            canActivate: [accessTokenGuard],
            data: {
              allowedAccessToken: EAccessType.FAT,
              allowedTypes: [EUserType.Customer],
            },
          },
        ]
      }
    ]
  }

];

@NgModule({
  imports: [CommonModule,RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountSettingsRoutingModule { }
