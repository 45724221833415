import {Injectable} from '@angular/core';
import {GraphService} from "src/app/kernel/graphql/services/graph.service";
import {Observable} from "rxjs";
import {ApolloQueryResult} from "@apollo/client/core";
import {gql} from "apollo-angular";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private graphService: GraphService) {
  }

  testConnection(): Observable<ApolloQueryResult<string>> {
    return this.graphService.query({query: gql`query { ping }`});
  }

}
