import { toDate } from '../../../../../../shared/helpers/date-helper/date.helper';
import { ValidatorsOptionsInterface } from "../../interfaces/validators-options.interface";

export function isBeforeDate(str: string, date = String(new Date()), options?: ValidatorsOptionsInterface):
  Record<string, ValidatorsOptionsInterface> | null {
  if(!date || !str) { return null; }
  const comparison = toDate(date);
  const original = toDate(str);
  return (original && comparison && original < comparison) ? null : {
    isBeforeDate: {
      message: options?.message || 'isBeforeDate',
      comparisonFieldName: options?.comparisonFieldName || ''
    }
  };
}
