import { Injectable } from '@angular/core';
import { IPaymentIntent } from 'src/app/chatperk-core/interfaces/invoice.interfaces';
import { StripeService } from 'ngx-stripe';
import { PopupService } from 'src/app/kernel/popups/popup/modules/confirmation-dialog/services/confirmation-popup/popup.service';
import { from, map, of, switchMap } from 'rxjs';
import { PaymentIntent } from '@stripe/stripe-js';
import { IAmount, IPaymentMethod } from 'src/app/chatperk-core/interfaces';
import { CurrencyPipe } from '@angular/common';
import { EPaymentMethodType } from 'src/app/chatperk-core/enums/EPaymentMethodType';
import { environment } from 'src/environments/environment';
import { EPaymentsProvider } from 'src/app/chatperk-core/enums/EPaymentsProvider';
import { EPaymentIntentStatus } from 'src/app/chatperk-core/enums/EPaymentIntentStatus';

export const PAYMENT_INTENT_STATUS_MAP = {
  ['canceled']: EPaymentIntentStatus.Canceled,
  ['processing']: EPaymentIntentStatus.Processing,
  ['requires_action']: EPaymentIntentStatus.RequiresAction,
  ['requires_capture']: EPaymentIntentStatus.RequiresCapture,
  ['requires_confirmation']: EPaymentIntentStatus.RequiresConfirmation,
  ['requires_payment_method']: EPaymentIntentStatus.RequiresPaymentMethod,
  ['succeeded']: EPaymentIntentStatus.Succeeded,
};

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(
    private stripeService: StripeService,
    private currencyPipe: CurrencyPipe,
    private confirmationPopup: PopupService
  ) {}

  confirmPayment(
    paymentIntent: IPaymentIntent,
    invoiceId: string,
    showConfirmation = false
  ) {
    const confirmation = showConfirmation
      ? this.showConfirmPaymentPopup(
          paymentIntent.amount,
          paymentIntent.paymentMethod!
        )
      : of(true);

    return confirmation.pipe(
      switchMap((confirmed) =>
        confirmed
          ? this.stripeService.confirmPayment({
              clientSecret: paymentIntent.clientSecret,
              confirmParams: {
                payment_method: paymentIntent.paymentMethod?.externalRef?.refId,
                return_url: `${environment.STRIPE_REDIRECT_URL}/${invoiceId}`,
              },
              redirect: 'if_required',
            })
          : of(null)
      ),
      map((result) => {
        if (!result) return result;
        const stripePaymentIntent =
          result!.paymentIntent || result!.error?.payment_intent;

        const updatedPaymentIntent =
          stripePaymentIntent &&
          this.mapPaymentIntentFromStripe(stripePaymentIntent);

        const paymentMethodRefId =
          stripePaymentIntent &&
          (typeof stripePaymentIntent.payment_method == 'string'
            ? stripePaymentIntent.payment_method
            : stripePaymentIntent.payment_method?.id);

        if (
          updatedPaymentIntent &&
          paymentMethodRefId === paymentIntent.paymentMethod?.externalRef.refId
        ) {
          updatedPaymentIntent.paymentMethod = paymentIntent.paymentMethod;
        }

        return {
          paymentIntent: updatedPaymentIntent,
          paymentMethodRefId:
            stripePaymentIntent &&
            (typeof stripePaymentIntent.payment_method == 'string'
              ? stripePaymentIntent.payment_method
              : stripePaymentIntent.payment_method?.id),
          error: result!.error,
        };
      })
    );
  }

  showConfirmPaymentPopup(amount: IAmount, paymentMethod: IPaymentMethod) {
    return from(
      this.confirmationPopup.confirm({
        message: this.showConfirmPaymentPopupMessage(amount, paymentMethod),
      })
    );
  }

  mapPaymentIntentFromStripe(
    stripePaymentIntent: PaymentIntent
  ): IPaymentIntent {
    return {
      externalRef: {
        refId: stripePaymentIntent.id,
        provider: EPaymentsProvider.Stripe,
      },
      amount: {
        amount: stripePaymentIntent.amount / 100,
        currency: stripePaymentIntent.currency.toUpperCase(),
      },
      status: PAYMENT_INTENT_STATUS_MAP[stripePaymentIntent.status],
      description: stripePaymentIntent.description ?? undefined,
      clientSecret: stripePaymentIntent.client_secret ?? '',
      lastPaymentError: stripePaymentIntent.last_payment_error
        ? {
            code: stripePaymentIntent.last_payment_error.code,
            declineCode: stripePaymentIntent.last_payment_error.decline_code,
            message: stripePaymentIntent.last_payment_error.message,
          }
        : undefined,
    };
  }

  private showConfirmPaymentPopupMessage(
    amount: IAmount,
    paymentMethod: IPaymentMethod
  ) {
    return paymentMethod.type === EPaymentMethodType.Card
      ? `Are you sure you want to pay ${this.currencyPipe.transform(
          amount.amount,
          amount.currency,
          'symbol',
          '1.0-2'
        )} with your ${paymentMethod.card?.brand} ${
          paymentMethod.card?.funding
        } card ending ${paymentMethod.card?.last4}`
      : `Are you sure you want to pay ${this.currencyPipe.transform(
          amount.amount,
          amount.currency,
          'symbol',
          '1.0-2'
        )} with your paypal account "${
          paymentMethod.paypal?.payerEmail || paymentMethod.paypal?.payerId
        }"`;
  }
}
