import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse, HttpResponse,
} from '@angular/common/http';
import {Observable, tap, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {MessageService} from "src/app/kernel/tools/message/service/message.service";
import {AuthService} from "src/app/kernel/tools/services/auth.service";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService, 
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  handleError(code: number, message: any) {
    message = this.translateService.instant(message);
    switch (code) {
      case 401: this.authService.logout(); this.messageService.clear(); this.messageService.error(message); break;
      default: this.messageService.clear(); this.messageService.error(message); break;
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (!(event instanceof HttpResponse)) { return ; }
        this.forEachError(event, (error) => {
          if (request.headers.get('ignore_error_notification')) {
            return ;
          }
          this.handleError(error.code, error.data?.messageKey || error.message ||  "errors.something_went_wrong")
        })
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleError(error.status, "errors.something_went_wrong")
        return throwError(() => error);
      })
    );
  }

  forEachError(event: HttpResponse<any>, cb: (error: any) => void) {
    const data = Array.isArray(event.body) ? event.body : [event.body];
    data.forEach((record: any) => {
      if (!Array.isArray(record?.errors)) return;
      record.errors.forEach((error: any) => {
        if (error?.extensions?.formatted) {
          cb(error?.extensions?.formatted);
        }
      })
    })
  }

}
