import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: 'integration-redirect',
    children: [
      {
        path: 'slack',
        loadChildren: () => import('./modules/slack-redirect/slack-redirect.module').then(m => m.SlackRedirectModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IntegrationRedirectRoutingModule {}
