import {Component} from '@angular/core';

@Component({
  selector: 'sxw-popup-wrapper',
  templateUrl: './popup-wrapper.component.html',
  styleUrls: ['./popup-wrapper.component.scss']
})
export class PopupWrapperComponent {

}
