<div class="mobile-number {{theme}}">
  <!-- Country Dropdown { -->
  <sxw-dropdown-input
    [showClear]="false"
    [options]="!query ? options : []"
    [query]="query"
    (onDropDownChange)="handleCountryChange($event)"
    [(ngModel)]="countryValue"
    [filterBy]="'name,code'"
    [readonly]="readonly || disabled"
    [appendTo]="null"
    [optionValue]="'name,code,countryIso,iso'"
    *ngIf="showCountryDropdown">
    <ng-template let-country>
      <div class="country-item">
        <div class="country-item__left-side">
          <img class="country-item__img" src="assets/images/countries/{{ country?.iso | lowercase }}.svg"
               [alt]="country?.iso" />
          <span>{{ country?.name | translate}}</span>
        </div>
        <div class="country-item__code code-direction">
          {{ country?.code }}
        </div>
      </div>
    </ng-template>
    <ng-template #selectedContent>
      <div class="country-item">
        <div class="country-item__left-side">
          <img class="country-item__img" src="assets/images/countries/{{ countryValue?.iso | lowercase }}.svg"
               [alt]="countryValue.iso" />
          <span *ngIf="showCountryName">{{ countryValue?.name }}</span>
        </div>
        <div *ngIf="showCountryCode" class="country-item__code">{{ countryValue?.code }}</div>
      </div>

    </ng-template>
  </sxw-dropdown-input>
  <!-- } Country Dropdown  -->
  <!-- Mobile Number Input { -->
  <div class="input-container" [ngClass]="{'with-country-select': showCountryDropdown}">
    <span #codeValue class="input-container__code code-direction"   [hidden]="!countryValue && !showCountryDropdown">
       {{ countryValue?.code }} 
    </span>
    <input #inputValue type="text" class="input-container__input full-width-input" pInputText placeholder="placeholder"
      [(ngModel)]="numberValue" [pKeyFilter]="'int'" (focus)="isMobileInputTouched = true" (blur)="onTouched()"
      [style.padding-inline-start]="'70px'"
      (input)="onTouched()" (ngModelChange)="onInputChange()" [readonly]="readonly || disabled" [ngClass]="{
        'invalid-input':
          control &&
          control.errors &&
          (control.touched || formGroupDirective?.submitted)
      }" />
    <sxw-error-message *ngIf="
        control &&
        control.errors &&
        (control.touched || formGroupDirective?.submitted)
      " [message]="control ? control.errors : null"></sxw-error-message>
  </div>
  <!-- } Mobile Number Input -->
</div>
