import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "sxw-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() type = "button";
  @Input() label = "";
  @Input() class = "";
  @Input() icon = "";
  @Input() iconPos: "left" | "right" = "left";
  @Input() iconWidth:string = "20";
  @Input() iconHeight:string = "20";
  @Input() isLoading = false;
  @Input() disabled = false;
  @Input() badge = "";
  @Input() badgeClass = "";
  @Input() theme = "sxw-theme";
  @Input() leftIconFill!: string;
  @Input() rightIconFill!: string;

  @Output() onClick = new EventEmitter<any>();

  click(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
