import {Injectable} from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, map, of, switchMap} from "rxjs";
import {appInitialActions} from "src/app/core/root-store/root.actions";
import {AppService} from "src/app/core/services/app.service";


@Injectable()
export class RootEffects {

  constructor(
    private actions$: Actions,
    private appService: AppService
  ) {}

  testConnection$ = createEffect(
    () => this.actions$.pipe(
      ofType(appInitialActions.appStarted),
      switchMap(() => this.appService.testConnection().pipe(
        map((_result) => appInitialActions.appConnected()),
        catchError( (_error) => of(appInitialActions.appConnectionFailed()))
      ))
    ),
  );

}
