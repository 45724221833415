import { ReplaySubject, catchError, forkJoin, map, Observable, of, shareReplay, tap, finalize, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { TranslationLoader } from "./TranslationLoader";

export class TranslationHttpLoader implements TranslationLoader {
  private loadedKeys$ = new ReplaySubject<string>();
  // Should run when all translation loaded once
  private emitLoadedResources = new Subject<void>();
  protected loaded: { [key: string]: any } = {};

  constructor(
    protected http: HttpClient,
    protected resources: string[],
    protected prefix = 'json',
  ) {}

  /**
   * Set the array of resources
   * @param resources
   */
  setResources(resources: string[]) {
    this.resources = resources;
  }

  /**
   * Get the translation for a specific language
   * @param lang
   */
  getTranslation(lang: string): Observable<any> {
    return forkJoin(this.resources.map(resource => this.prepareResource(resource, lang))).pipe(
      // Combine the translations into a single object
      map(trans => {
        return Object.assign({}, ...trans);
      }),
      finalize(() => {
        this.resources.forEach(key => this.loadedKeys$.next(key));
        this.emitLoadedResources.next();
      })
    );
  }

  getLoadedKeys(){
    return this.loadedKeys$.asObservable();
  }

  emitTranslations() {
    return this.emitLoadedResources;
  }

  /**
   * Prepare resource for translation
   * @param resource
   * @param lang
   * @protected
   */
  protected prepareResource(resource: string, lang: string): Observable<any> {
    const key = `${resource}${lang}`;

    // Check if the resource is already loaded
    if (this.isResourceLoaded(key)) {
      return this.prepareLoadedResource(key);
    }

    // Load the resource for the first time
    return this.prepareNewResource(key);
  }

  /**
   * Prepare a resource that was not loaded yet.
   * @param key
   * @protected
   */
  protected prepareNewResource(key: string) {
    return this.createLoadRequest(key).pipe(
      // Add the loaded resource to the cache
      map((item: any) => this.addResource(key, item))
    );
  }

  /**
   * Prepare a resource that is already loaded
   * @param key
   * @protected
   */
  protected prepareLoadedResource(key: string): Observable<any> {
    const cachedResource = this.getResource(key);
    if (cachedResource instanceof Observable) {
      return cachedResource;
    }
    return of(cachedResource);
  }

  /**
   * Create a request to load the resource
   * @param key
   * @protected
   */
  protected createLoadRequest(key: string) {
    return this.http.get(`${key}.${this.prefix}`).pipe(
      // Handle errors by returning an empty object
      catchError((e) => of({})),
      // Share the loaded resource to avoid making multiple requests
      shareReplay(1)
    );
  }

  /**
   * Add a resource to the cache
   * @param key
   * @param resource
   * @protected
   */
  protected addResource(key: string, resource: any): any {
    return (this.loaded[key] = resource);
  }

  /**
   * Check if a resource is already loaded
   * @param key
   * @protected
   */
  protected isResourceLoaded(key: string): boolean {
    return Boolean(this.loaded[key]);
  }

  /**
   * Get a resource from the cache
   * @param key
   * @protected
   */
  protected getResource(key: string): any {
    return this.loaded[key] ?? null;
  }
}
