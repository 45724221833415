import { Injectable, NgZone } from "@angular/core";
import { City, ICity } from "country-state-city";

@Injectable({
  providedIn: "root",
})
export class CityLockupService {
  constructor(private ngZone: NgZone) {}

  getAll() {
    return this.ngZone.runOutsideAngular(() => City.getAllCities());
  }

  getAllByCountry(countryCode: string) {
    return this.ngZone.runOutsideAngular(
      () => City.getCitiesOfCountry(countryCode) ?? []
    );
  }

  getAllByCountryAndState(countryCode: string, stateCode: string) {
    return this.ngZone.runOutsideAngular(
      () => City.getCitiesOfState(countryCode, stateCode) ?? []
    );
  }

  sortByStateAndName(cities: ICity[]) {
    return this.ngZone.runOutsideAngular(
      () => City.sortByStateAndName(cities) ?? []
    );
  }
}
