import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Optional,
} from "@angular/core";
import {
  AbstractControl,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { countries } from "src/app/kernel/shared/data/countries";
import { SelectBoxEventInterface } from "src/app/kernel/form/interfaces/select-box-event.interface";

import { CustomInput } from "src/app/kernel/form/classes/custom-input";
import { Country } from "src/app/kernel/form/interfaces/country";
import { ValidatorService } from "../../../../tools/validators/services/validate.service";
import { QueryWithFiltersInterface } from "../../../../../../graphql/interfaces/query.interface";
import phone from "phone";

@Component({
  selector: "sxw-mobile-number",
  templateUrl: "./mobile-number.component.html",
  styleUrls: ["./mobile-number.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobileNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => MobileNumberComponent),
    },
  ],
})
export class MobileNumberComponent
  extends CustomInput<string>
  implements OnInit, Validator {
  @Input() override readonly = false;
  @Input() showCountryDropdown = true;
  @Input() defaultCountry = "";
  @Input() theme = "sxw-theme";
  @Input() options: any[] = countries;
  @Input() query!: QueryWithFiltersInterface;
  @Input() showCountryCode: boolean = false;
  @Input() showCountryName: boolean = false;
  @Input() countryDetailsMode: boolean = false; // enable the new output logic for mobile number details
  @Input() acceptLandline: boolean = false; // enable the user to insert land line number
  @Input() onlyLandline: boolean = false; // enable the user to insert land line number

  placeholder: string = '1234567890';
  isMobileInputTouched = false;

  countryValue: any = countries[0];

  numberValue = "";

  get _value() {
    if (!this.showCountryDropdown && !this.defaultCountry) {
      `${this.numberValue}`;
    }
    if(this.countryDetailsMode) {
      return this.getDetailedValueObject();
    }
    return this.numberValue ? `${this.countryValue.code}${this.numberValue}` : "";
  }

  set _value(value: any) {
    if (!value) {
      return;
    }
    // if the output value is object of type Country ( all details )
    if(this.countryDetailsMode && typeof value === 'object') {
      this.countryValue = value?.numberValue ? {...value}: {...this.countryValue};
      this.numberValue = value?.numberValue;
    } else {
      // process string value for the default mode
      this.extractCountryAndPhoneNumber(value);
    }

    this.value = this._value; // to make summary work correctly;
  }

  override get summaryValue(): any {
    const template = `
        <img
            class="mobile-flag-summary" width="30px"
            src="assets/images/countries/${this.countryValue?.iso.toLowerCase()}.svg"
        /> ${this.countryValue.code ?? ''}
    `;

    if (!this.value || (typeof this.value === 'object' && !(this.value as any)['numberValue'])) {
      // check if the value is empty in all modes
      return template;
    }

    return `${template} ${this.numberValue}`;
  }

  constructor(
    private validatorService: ValidatorService,
    @Optional() public formGroupDirective?: FormGroupDirective,
  ) {
    super();
  }

  /**
   * Life cycle hooks
   */
  ngOnInit(): void {
    if (this.defaultCountry) {
      this.countryValue =
        this.options?.find(
          (country: Country) => country.countryIso === this.defaultCountry
        ) ?? this.countryValue;
    }
    this.placeholder = this.countryValue?.placeHolder ?? this.placeholder;
  }

  /**
   * Handle country select box change.
   * @param _
   */
  handleCountryChange(_: SelectBoxEventInterface<Country>) {
    this.placeholder = this.countryValue?.placeHolder ?? '';
    this.numberValue = "";
    this._value = "";
    this.onTouched?.();
    this.onInputChange();
  }

  /**
   * Validate mobile number
   * @param control
   */
  validate(_control: AbstractControl): ValidationErrors | null {
    // Do not validate if no value was entered.
    if (!this.numberValue) {
      return null;
    }

    const validationSpecs =
      this.showCountryDropdown || this.defaultCountry
        ? { options: this.countryValue?.countryIso }
        : {};
    let validation: any = { isMobile: validationSpecs };

    if (this.onlyLandline) {
      validation = { isLandlineNumber: validationSpecs };
    } else if (this.acceptLandline) {
      validation = { $or: [{ isLandlineNumber: validationSpecs }, validation] };
    }

    return this.validatorService.validate(this.numberValue, validation);
  }

  /**
   * Writes a new value to the element.
   * @param value
   */
  override writeValue(value: string): void {
    this._value = value;
  }

  /**
   * Extract the country and phone number values.
   * @param value
   */
  extractCountryAndPhoneNumber(value: string) {
    if (!value) {
      return;
    }
    // TODO: remove this in the future
    // Check if the number value matches the old format
    if (/^[a-z]{2}-[A-Z]{2}\+\d+$/.test(value)) {
      return this.oldExtractCountryAndPhoneNumber(value)
    }
    const data = phone(value);
    this.numberValue = value.indexOf('+') === -1 ? value : data.phoneNumber?.substring(data.countryCode?.length) ?? '';

    // set country && number value when the input is a static data //
    this.countryValue = this.options?.find((country: Country) => country.iso === data.countryIso2) ?? this.countryValue;
  }

  /**
   * @deprecated ("Please remove this method when you are sure that there is no more numbers using the old format")
   * @param value
   */
  oldExtractCountryAndPhoneNumber(value: string) {
    const splitted = value.split("+");
    if (splitted.length < 2) {
      this.numberValue = splitted[0];
      return;
    }
    // set country && number value when the input is a static data //
    this.countryValue =
      this.options?.find(
        (country: Country) => country.countryIso === splitted[0]
      ) ?? this.countryValue;
    this.numberValue = splitted[1];
  }


  /**
   * Returns an object that contains the detailed phone number value.
   */
  getDetailedValueObject() {
    return this.numberValue ? {
        name: this.countryValue?.name,
        code: this.countryValue?.code,
        iso: this.countryValue?.iso,
        countryIso: this.countryValue?.countryIso,
        numberValue: this.numberValue
    } : {};
  }

  onInputChange() {
    this.value = this._value; // to make summary work correctly
    this.onChange(this._value);
  }
}
