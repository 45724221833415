import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { ButtonModule as ButtonPrimeModule } from 'primeng/button';
import { CreateButtonComponent } from './components/create-button/create-button.component'
import {TranslationModule} from "../../../translations/modules/translation.module";
import {SvgIconModule} from "../../../tools/svg-icon/svg-icon.module";



@NgModule({
  declarations: [ButtonComponent, CreateButtonComponent],
    imports: [
        CommonModule,
        ButtonPrimeModule,
        TranslationModule,
        SvgIconModule
    ],
  exports: [ButtonComponent, CreateButtonComponent]
})
export class ButtonModule { }
