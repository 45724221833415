import { mergeOptions } from '../validators-utils/merge-options';

const default_date_options = {
  format: 'dd/mm/yyyy',
  delimiters: ['/', '-'],
  strictMode: false,
};

function isValidFormat(format: string) {
  return /(^(y{4}|y{2})[.\/-](m{1,2})[.\/-](d{1,2})$)|(^(m{1,2})[.\/-](d{1,2})[.\/-]((y{4}|y{2})$))|(^(d{1,2})[.\/-](m{1,2})[.\/-]((y{4}|y{2})$))/gi
    .test(format);
}

function zip(date: any, format: string) {
  const zippedArr = [],
    len = Math.min(date.length, format.length);
  for (let i = 0; i < len; i++) {
    zippedArr.push([date[i], format[i]]);
  }
  return zippedArr;
}

export function isDate(input: any, options: any = {}) {
  const _options = mergeOptions(options, default_date_options)
  let _input = input;
  // Convert Date to formatted string
  if(typeof _input !== 'string') {
    _input = Intl.DateTimeFormat(
      'it-IT',
      {day: '2-digit', month: '2-digit', year: 'numeric'}
    ).format(_input);
  }

  if (typeof _input === 'string' && isValidFormat(_options.format)) {
    const formatDelimiter = _options.delimiters.find((delimiter: any) => _options.format.indexOf(delimiter) !== -1);
    const dateDelimiter = _options.strictMode
      ? formatDelimiter
      : _options.delimiters.find((delimiter: any) => _input.indexOf(delimiter) !== -1);
    const dateAndFormat = zip(
      _input.split(dateDelimiter),
      _options.format.toLowerCase().split(formatDelimiter)
    );
    const dateObj: any = {};

    for (const [dateWord, formatWord] of dateAndFormat) {
      if (dateWord.length !== formatWord.length) {
        return { isDate: 'isDate' };
      }
      dateObj[formatWord.charAt(0)] = dateWord;
    }

    return new Date(`${dateObj.m}/${dateObj.d}/${dateObj.y}`).getDate() === +dateObj.d ?
      null : { isDate: 'isDate' };
  }

  if (!_options.strictMode) {
    return Object.prototype.toString.call(_input) === '[object Date]' && isFinite(_input) ? null : { isDate: 'isDate' };
  }
  return { isDate: 'isDate' };
}

