import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appInfoStateReducers } from 'src/app/core/root-store/root.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GraphqlModule } from 'src/app/kernel/graphql/graphql.module';
import { environment } from 'src/environments/environment';
import { RootEffects } from 'src/app/core/root-store/root.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopupWrapperModule } from '../kernel/popups/popup-wrapper/popup-wrapper.module';
import { SpinnerModule } from '../kernel/shared/modules/spinner/spinner.module';
import { direction } from './enums/routeDirection';
import { TranslationModule } from 'src/app/kernel/translations/modules/translation.module';
import { NotAuthorizedComponent } from './error-pages/not-authorized/not-authorized.component';
import { ButtonModule } from 'src/app/kernel/shared/modules/button/button.module';
import { RouterModule } from '@angular/router';
import { ErrorInterceptor } from 'src/app/core/interceptors/ErrorInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [NotAuthorizedComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // ===================== Core Imports { =====================
    GraphqlModule.on(environment.graphql_endpoint),
    // ===================== } Core Imports =====================

    // =================== STATE MANAGEMENT { ===================
    StoreModule.forRoot({ appInfo: appInfoStateReducers }),
    EffectsModule.forRoot([RootEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
    }),
    // =================== } STATE MANAGEMENT ===================
    TranslationModule.forRoot('/assets/i18n/root/'),
    PopupWrapperModule,
    SpinnerModule,
    ButtonModule,
    RouterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  exports: [PopupWrapperModule, SpinnerModule],
  bootstrap: [],
})
export class CoreModule {
}
