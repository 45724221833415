import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { map, Subject, takeUntil } from 'rxjs';
import { BillingAccountService } from '../services/billing-account.service';

export const myBillingAccountResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot
) => {
  const billingAccountService = inject(BillingAccountService);
  const options = route.data['myBillingAccountOptions'];
  const dataShape = options?.['dataShape'];

  return billingAccountService
    .getMyBillingAccount(dataShape)
    .pipe(map((v) => v.data['myBillingAccount']));
};
