import {AppInfoStateInterface, AppStateInterface} from "src/app/core/root-store/root.state";
import {createSelector} from "@ngrx/store";

export const selectAppInfoState = (state: AppStateInterface) => state.appInfo ;

/**
 * Returns The Application Connection status.
 */
export const selectAppConnectionStatus = createSelector(
  selectAppInfoState,
  (info: AppInfoStateInterface) => info.connected
);

/**
 * Returns The Application Version.
 */
export const selectAppVersions = createSelector(
  selectAppInfoState,
  (info: AppInfoStateInterface) => info.version
)
