/**
 * Omit a specific key from an object, including nested objects
 * @param obj
 * @param key
 */
export function omitDeep(obj: any, key: string) {
  if (obj[key]) {
    delete obj[key];
  }
  // Filter the keys of the object to find nested objects
  Object.keys(obj)
    .filter((_key) => obj[_key] && typeof obj[_key] === "object")
    .forEach((_key) => {
      // Recursively call omitDeep on nested objects
      obj[_key] = omitDeep(obj[_key], key);
    });
  return obj;
}

/**
 * Create a deep clone of an object using JSON serialization
 * @param obj
 */
export function cloneDeep(obj: object | any) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Access a deeply nested property in an object using dot notation
 * @param obj
 * @param key
 */
export function accessDeep<T>(obj: any, key: string): T {
  return key.split(".").reduce((acc, key) => acc && acc[key], obj);
}

/**
 * Compare two objects/Anything deeply by converting them to strings
 * @param obj
 * @param obj2
 */
export function compareDeep(obj: any, obj2: any) {
  return !obj && !obj2 ? true :JSON.stringify(obj) === JSON.stringify(obj2);
}

/**
 * Construct a new object by extracting specific keys from a source object,
 * including nested keys using dot notation
 * @param obj
 * @param keys
 */
export function constructDeep<T extends object>(
  obj: any,
  keys: string[],
): T | undefined {
  if (!obj || !keys.length || typeof obj !== "object") {
    return obj as T;
  }
  const constructed: any = {};
  keys.forEach((key) => {
    const nestedKeys = key.split(".");
    const currentKey = nestedKeys.shift() ?? "";
    constructed[currentKey] = constructDeep(
      obj[currentKey],
      nestedKeys.length ? [nestedKeys.join(".")] : [],
    );
  });
  return constructed as T;
}

export function removeNulls(obj: Record<string, any>): any | null {
  if (typeof obj !== 'object' || obj === null) {
      return obj;
  }

  if (Array.isArray(obj)) {
      const newArray: any[] = obj.map(removeNulls).filter(item => item !== null);
      return newArray.length === 0 ? null : newArray;
  } else {
      const newObj: any = {};
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              const value = removeNulls(obj[key]);
              if (value !== null) {
                  newObj[key] = value;
              }
          }
      }
      return Object.keys(newObj).length === 0 ? null : newObj;
  }
}
