import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EPaymentMethodType } from 'src/app/chatperk-core/enums/EPaymentMethodType';
import { IPaymentMethod } from 'src/app/chatperk-core/interfaces';

@Component({
  selector: 'app-payment-method-item[paymentMethod]',
  templateUrl: './payment-method-item.component.html',
  styleUrls: ['./payment-method-item.component.scss'],
})
export class PaymentMethodItemComponent {
  public readonly ePaymentMethodType = EPaymentMethodType;
  @Input() paymentMethod!: IPaymentMethod;
  @Input() showViewButton?: boolean;
  @Input() viewButtonIcon: string = 'pi pi-pencil';
  @Output() onViewPaymentMethod = new EventEmitter<IPaymentMethod>();

  viewPaymentMethod(paymentMethod: IPaymentMethod) {
    this.onViewPaymentMethod.emit(paymentMethod);
  }
}
