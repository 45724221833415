<div class="credentials-box_text">
  <div class="brand-img-container">
    <img
      class="brand-img"
      *ngIf="
        paymentMethod.type === ePaymentMethodType.Card;
        else paypalIconTemp
      "
      [src]="'assets/images/payments/' + paymentMethod.card?.brand + '.webp'"
      width="27"
    />
    <ng-template #paypalIconTemp>
      <img
        class="brand-img"
        src="assets/images/payments/paypal.png"
        width="27"
      />
    </ng-template>
  </div>
  <div>
    <h4>
      <span
        class="main-info"
        *ngIf="
          paymentMethod.type === ePaymentMethodType.Card &&
          paymentMethod.card?.brand
        "
      >
        <ng-container *ngIf="paymentMethod.type === ePaymentMethodType.Card">
          {{ paymentMethod.card?.brand }}
        </ng-container>
        <ng-container>
          ({{
            paymentMethod.billingInfo.firstName +
              " " +
              paymentMethod.billingInfo.lastName
          }})
        </ng-container>
      </span>
      <span class="primary-label" *ngIf="paymentMethod.isPrimary">{{
        "paymentMethod.primary" | translate
      }}</span>
    </h4>
    <p>
      <ng-container
        *ngIf="
          paymentMethod.type === ePaymentMethodType.Card;
          else paypalInfoTemp
        "
      >
        {{ paymentMethod.card?.last4 }} &nbsp;
        {{ paymentMethod.card?.expMonth }}/{{ paymentMethod.card?.expYear }}
      </ng-container>
      <ng-template #paypalInfoTemp>
        {{ paymentMethod.paypal?.payerEmail }} &
      </ng-template>
    </p>
  </div>
</div>
<div class="d-flex gap-10px">
  <a
    *ngIf="showViewButton"
    (click)="viewPaymentMethod(paymentMethod)"
    pButton
    class="gray-style viewButton"
  >
    <i [class]="viewButtonIcon" class="icon-style"></i>
  </a>
</div>
