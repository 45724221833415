<div class="{{theme}}">
  <label *ngFor="let item of items" [class]="itemClass">
    <p-radioButton
      [name]="groupName"
      [value]="getValueOf(item)"
      [disabled]="readonly || disabled"
      [(ngModel)]="value"
      (ngModelChange)="updateValue($event, item)"
      [ngClass]="imageMode ? 'hidden-radio' : ''"
    ></p-radioButton>
    <ng-container
      *ngTemplateOutlet="
        imageMode ? imageTemplate : labelTemplate || defaultLabelTemplate;
        context: {
          $implicit: item,
          value: getValueOf(item),
          label: item[labelKey],
          isSelected: radioSelection?.item === item
        }
      "
    ></ng-container>
  </label>
</div>

<ng-template #defaultLabelTemplate let-label="label">
  <span class="default-label">
    {{ label }}
  </span>
</ng-template>

<ng-template #imageTemplate let-item>
  <div class="radio-image-container">
    <img
      [src]="item?.imageUrl"
      alt="{{ item?.value }}"
      class="radio-image"
      (click)="updateValue(item.value, item)"
      [class.selected]="value === item?.value"
    />
    <label>{{ item?.label }}</label>
  </div>
</ng-template>
