<div class="create-space-container">
    <div class="create-space-card">
        <h2>{{"general.title" | translate}}</h2>
        <p>{{"general.description" | translate}}</p>
        <sxw-form  [formGroup]="formModel.formGroup" [mutationConfig]="mutationConfig" (onMutationComplete)="handleMutationComplete($event)" (onMutationError)="handleMutationError" #formElement>
            <div class="input-group">
                <label class="input-label">{{"general.name" | translate}}</label>
                <sxw-text-input id="name" placeHolder="{{'general.namePlaceholder' | translate}}" formControlName="name" theme="input-theme"
                    [validations]="{isNotEmpty:{}}" (ngModelChange)="onNameChange($event)"
                    ></sxw-text-input>
            </div>
            <div class="input-group">
                <label class="input-label">{{"general.url" | translate}}</label>
                <sxw-text-input id="name" placeHolder="{{'general.urlPlaceholder' | translate}}" formControlName="code" theme="input-theme"
                    [validations]="{isNotEmpty:{}, matches:{options:'^[a-zA-Z0-9]+$'}}"
                    [asyncValidations]="{
                        isUnique: {
                          options: {
                            query: 'validateSpace',
                            match: 'code'
                          }
                        }
                      }"
                    ></sxw-text-input>
            </div>

            <sxw-button
                label="{{'actions.create' | translate}}"
                class="submit-button"
                (onClick)="formElement.submit()"
                theme="btn primary" >
            </sxw-button>
        </sxw-form>
    </div>
</div>
