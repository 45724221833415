import {createActionGroup, emptyProps, props} from "@ngrx/store";

export enum CoreActionTypes {
  APP_STARTED = 'App Started',
  APP_CONNECTED = 'App Connected',
  APP_CONNECTION_FAILED = 'App Connection Failed'
}

/**
 * The Application Started.
 */
export const appInitialActions = createActionGroup({
  source: '[Application]',
  events: {
      [CoreActionTypes.APP_STARTED]: props<{ version: string }>(),
      [CoreActionTypes.APP_CONNECTED]: emptyProps(),
      [CoreActionTypes.APP_CONNECTION_FAILED]: emptyProps(),
  }
});
