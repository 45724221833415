import { Injectable, NgZone } from "@angular/core";
import { State, IState } from "country-state-city";

@Injectable({
  providedIn: "root",
})
export class StateLockupService {
  constructor(private ngZone: NgZone) {}

  getOneByCode(stateCode: string) {
    return this.ngZone.runOutsideAngular(() => State.getStateByCode(stateCode));
  }

  getOneByCodeAndCountry(stateCode: string, countryCode: string) {
    return this.ngZone.runOutsideAngular(() =>
      State.getStateByCodeAndCountry(stateCode, countryCode)
    );
  }

  getAll() {
    return this.ngZone.runOutsideAngular(() => State.getAllStates());
  }

  getAllByCountry(countryCode: string) {
    return this.ngZone.runOutsideAngular(
      () => State.getStatesOfCountry(countryCode) ?? []
    );
  }

  sortByStateAndName(states: IState[]) {
    return this.ngZone.runOutsideAngular(
      () => State.sortByIsoCode(states) ?? []
    );
  }
}
