import { Injectable } from '@angular/core';
import {GraphService} from "src/app/kernel/graphql/services/graph.service";
import {BehaviorSubject, first, map, of, tap} from "rxjs";
import {UsageOverviewInterface} from "src/app/shared/metrics-shared/interfaces/usage-overview-interface";
import {AuthService} from "src/app/chatperk-core/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class UsageOverviewService {

  usage$ = new BehaviorSubject<UsageOverviewInterface | undefined>(undefined);

  constructor(protected graphService: GraphService) {}

  queryUsageOverview() {
    return this.graphService.constructQuery(['assistants', 'messages', 'spaceMembers'], 'usageOverview');
  }

  getUsage(): UsageOverviewInterface | undefined{
    return this.usage$.value;
  }

  loadUsageOverview(){
    return this.queryUsageOverview().pipe(
      first(),
      tap((result: any) => this.usage$.next(result?.data?.usageOverview as UsageOverviewInterface | undefined))
    )
  }

}
