import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ISideDialog } from "../../types/side-dialog.interface";
import { SIDE_DIALOG_TOKEN } from "../../tokens/side-dialog.token";

@Component({
  selector: "sxw-side-dialog",
  templateUrl: "./side-dialog.component.html",
  styleUrls: ["./side-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: SIDE_DIALOG_TOKEN,
      useExisting: forwardRef(() => SideDialogComponent),
    },
  ],
})
export class SideDialogComponent implements ISideDialog {
  @Input() visible = true;
  /**
   * "center", "top", "bottom", "left", "right", "top-left", "top-right", "bottom-left" or "bottom-right".
   */
  @Input() position = "right";
  @Input() dialogClass = "";
  @Input() dismissible = true;
  @Input() showCloseIcon = false;
  @Input() closeOnEscape = true;
  @Input() outletName = "popups";
  @Input() routeName = "";
  @Input() backOnClose = true;
  @Input() theme = "";
  @Input() autoZIndex: boolean = true;
  @Input() baseZIndex: number = 0;
  @Input() appendTo: string | HTMLElement | null = null;

  @Output() onShowDialog = new EventEmitter<any>();
  @Output() onHideDialog = new EventEmitter<any>();
  @Output() visibleChange = new EventEmitter<boolean>();

  @ContentChild("header", { static: true })
  headerTemplateRef!: TemplateRef<any>;

  @ContentChild("actions", { static: true })
  actionsTemplateRef?: TemplateRef<any>;

  @ContentChild("content", { static: true })
  contentTemplateRef!: TemplateRef<any>;

  @ContentChild("cards", { static: true })
  cardsTemplateRef?: TemplateRef<any>;

  @ContentChild("footer", { static: true })
  footerTemplateRef!: TemplateRef<any>;

  constructor(private router: Router, private route: ActivatedRoute) {}

  onShow(event: any) {
    this.onShowDialog.emit(event);
  }

  async hideSidebar() {
    this.onHideDialog.emit(true);
    if (this.backOnClose) {
      this.goBack();
    }
  }

  onHide(event: any) {
    this.onHideDialog.emit(event);
    if (this.backOnClose) {
      this.goBack();
    }
  }

  goBack() {
    return this.router.navigate(
      [
        this.routeName || this.router.url.split("(").shift(),
        { outlets: { [this.outletName]: null } },
      ],
      { queryParams: this.route.snapshot.queryParams }
    );
  }

  handleVisibleChange(visible: boolean) {
    this.visible = visible;
    this.visibleChange.emit(visible);
  }
}
