import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { accessTokenGuard } from 'src/app/chatperk-core/guards/access-token.guard';
import { InviteSpaceComponent } from './modules/space/pages/invite-space/invite-space.component';
import {EAccessType, EUserType} from "src/app/chatperk-core/enums";
import { myBillingAccountResolver } from './modules/space/resolver/my-billing-account.resolver';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: 'space',
    loadChildren: () =>
      import('./modules/space/space.module').then((m) => m.SpaceModule),
      canActivate:[accessTokenGuard],
      resolve: {
        myBillingAccount: myBillingAccountResolver,
      },
      runGuardsAndResolvers:"always",
      data: {
        allowedAccessToken: [EAccessType.FAT],
        allowedTypes: [EUserType.Customer],
      }
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account-settings/account-settings.module')
      .then(({AccountSettingsModule}) => AccountSettingsModule),
    canActivate:[accessTokenGuard],
    data: {
      allowedAccessToken: [EAccessType.FAT],
      allowedTypes: [EUserType.Customer],
    }
  },
  {
    path: 'invite-to-space/:id',
    component: InviteSpaceComponent,
    data: {
      allowedAccessToken: [EAccessType.FAT],
      allowedTypes: [EUserType.Customer],
    },
  },
  {
    path: ':space/assistants',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/assistant/assistant.module').then(({AssistantModule}) => AssistantModule),
      },
      {
        path: 'view/:id',
        loadChildren: () => import('./modules/assistant-playground/assistant-playground.module').then(
          ({AssistantPlaygroundModule}) => AssistantPlaygroundModule
        ),
      },
    ],
    canActivate:[accessTokenGuard],
    data: {
      allowedAccessToken: [EAccessType.FAT],
      allowedTypes: [EUserType.Customer],
      requireSpace: true
    }
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/assistant/assistant.module').then(({AssistantModule}) => AssistantModule),
      },
    ],
    canActivate:[accessTokenGuard],
    data: {
      allowedAccessToken: [EAccessType.FAT],
      allowedTypes: [EUserType.Customer],
      requireSpace: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
