import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfirmPaymentData,
  PaymentIntentResult,
  PaymentRequestOptions,
  SetupIntentResult,
  StripeAddressElementOptions,
  StripeElements,
  StripeElementsOptions,
  StripeExpressCheckoutElement,
  StripeExpressCheckoutElementOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { LocalStorageService } from 'src/app/kernel/tools/services/local-storage.service';
import {
  IAddPaymentMethodFormControls,
  IAfterSubmissionEmitEvent,
} from '../../interfaces/payment-method.interfaces';
import { BillingAccountFormModel } from 'src/app/shared/billing-account/models/billing-account-form.model';
import { IPaymentMethodBillingInfo } from 'src/app/chatperk-core/interfaces';

@Component({
  selector: 'app-add-payment-method-form',
  templateUrl: './add-payment-method-form.component.html',
  styleUrls: ['./add-payment-method-form.component.scss'],
})
export class AddPaymentMethodFormComponent implements OnInit {
  @Input() clientSecret!: string;
  @Input() mode: 'setup' | 'payment' = 'setup';
  @Input() submitLabel = 'paymentMethod.buttons.save';
  @Input() defaultBillingInfo?: Partial<IPaymentMethodBillingInfo>;
  @Input() billingAccountModel = new BillingAccountFormModel();

  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;
  isLoading = false;

  @Output() afterSubmitSuccess: EventEmitter<IAfterSubmissionEmitEvent> =
    new EventEmitter<IAfterSubmissionEmitEvent>();

  expressCheckoutElement!: StripeExpressCheckoutElement;

  addPaymentFormGroup!: FormGroup<IAddPaymentMethodFormControls>;

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
    },
    business: { name: 'ChatPerk' },
  };

  addressElementOptions: StripeAddressElementOptions = {
    mode: 'billing',
    display: { name: 'full' },
    fields: { phone: 'never' },
  };

  expressCheckoutOptions: StripeExpressCheckoutElementOptions = {
    buttonType: {
      applePay: 'buy',
      googlePay: 'buy',
    },
    wallets: {
      applePay: 'always',
      googlePay: 'always',
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'flat',
      rules: {
        '.Input': {
          backgroundColor: '#FFFFFF',
          border: '1px solid #D6D6D6',
        },
        '.Tab--selected': {
          backgroundColor: '#0A052B',
        },
      },
      variables: {},
    },
    loader: 'always',
  };

  constructor(
    public stripe: StripeService,
    private messageService: MessageService,
    private translationService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private localStorage: LocalStorageService
  ) {
    this.elementsOptions.locale = this.localStorage.get('lang') ?? 'en';
  }

  ngOnInit() {
    this.initForm();
    this.handleRedirectResponse();
    this.elementsOptions.clientSecret = this.clientSecret;
  }

  initForm() {
    this.billingAccountModel.patchValue(this.defaultBillingInfo ?? {});
    this.addPaymentFormGroup = new FormGroup<IAddPaymentMethodFormControls>({
      billingInfo: this.billingAccountModel.formGroup,
      isPrimary: new FormControl(),
    });
  }

  handleRedirectResponse() {
    const redirectStatus =
      this.route.snapshot.queryParamMap.get('redirect_status');
    const setupIntentClientSecret = this.route.snapshot.queryParamMap.get(
      'setup_intent_client_secret'
    );
    const isPrimary = this.route.snapshot.queryParamMap.get('isPrimary');
    const orgName = this.route.snapshot.queryParamMap.get('orgName');
    const taxId = this.route.snapshot.queryParamMap.get('taxId');

    if (orgName) {
      this.addPaymentFormGroup.controls.billingInfo?.controls.orgName.setValue(
        orgName
      );
    }

    if (taxId) {
      this.addPaymentFormGroup.controls.billingInfo?.controls.taxId.setValue(
        taxId
      );
    }

    if (isPrimary) {
      this.addPaymentFormGroup.controls.isPrimary.setValue(isPrimary == '1');
    }

    if (!(redirectStatus && setupIntentClientSecret)) {
      return;
    }

    if (redirectStatus === 'succeeded' && setupIntentClientSecret) {
      this.stripe
        .retrieveSetupIntent(setupIntentClientSecret)
        .subscribe((res) => this.emitAfterSubmitSuccessEvent(res));
    } else if (redirectStatus === 'failed') {
      const msg = this.translationService.instant(
        'errors.something_went_wrong'
      );
      const summary = this.translationService.instant('errors.summary');
      this.messageService.error(msg, summary);
    }

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        redirect_status: null,
        setup_intent_client_secret: null,
        setup_intent: null,
        isPrimary: null,
        orgName: null,
        taxId: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  async confirmProcess() {
    this.addPaymentFormGroup.markAllAsTouched();
    const res = await this.paymentElement.elements.submit();
    this.isLoading = true;

    if (this.addPaymentFormGroup.invalid || res.error) {
      this.isLoading = false;
      if (res.error) this.showErrorMessage(res.error);
      return;
    }

    const { billingInfo, isPrimary } = this.addPaymentFormGroup.value;
    const returnUrl = new URL(
      window.location.origin + this.location.prepareExternalUrl(this.router.url)
    );

    returnUrl.searchParams.append('isPrimary', isPrimary ? '1' : '0');

    if (billingInfo?.orgName)
      returnUrl.searchParams.append('orgName', billingInfo?.orgName);

    if (billingInfo?.taxId)
      returnUrl.searchParams.append('taxId', billingInfo?.taxId);

    const options: {
      elements?: StripeElements;
      clientSecret: string;
      confirmParams?: Partial<ConfirmPaymentData>;
      redirect: 'if_required';
    } = {
      clientSecret: this.clientSecret,
      elements: this.paymentElement.elements,
      confirmParams: {
        receipt_email:
          (this.mode === 'payment' && billingInfo?.email) || undefined,
        payment_method_data: {
          allow_redisplay: 'always',
          billing_details: {
            name: billingInfo?.firstName + ' ' + billingInfo?.lastName,
            phone: billingInfo?.mobile,
            email: billingInfo?.email,
            address: billingInfo?.address?.country
              ? {
                  country: billingInfo?.address?.country,
                  city: billingInfo?.address?.city,
                  state: billingInfo?.address?.state,
                  line1: billingInfo?.address?.line1,
                  line2: billingInfo?.address?.line2,
                  postal_code: billingInfo?.address?.zip,
                }
              : undefined,
          },
        },
        return_url: returnUrl.toString(),
      },
      redirect: 'if_required',
    } as const;

    if (this.mode === 'setup') {
      return this.stripe.confirmSetup(options).subscribe({
        next: (result) => this.emitAfterSubmitSuccessEvent(result, void 0),
      });
    } else {
      return this.stripe.confirmPayment(options).subscribe({
        next: (result) => this.emitAfterSubmitSuccessEvent(void 0, result),
      });
    }
  }

  private showErrorMessage(error: SetupIntentResult['error']) {
    const translatedValue = this.translationService.instant(error!.code ?? '');
    const msg =
      error?.code !== translatedValue ? translatedValue : error?.message;
    const summary = this.translationService.instant('errors.summary');
    return this.messageService.error(msg, summary);
  }

  private emitAfterSubmitSuccessEvent(
    setupIntentRes?: SetupIntentResult,
    paymentIntentRes?: PaymentIntentResult
  ) {
    const res = (setupIntentRes || paymentIntentRes)!;

    this.isLoading = false;
    if (res.error) {
      return this.showErrorMessage(res.error);
    }
    this.afterSubmitSuccess.emit({
      setupIntentRes,
      paymentIntentRes,
      isPrimary: !!this.addPaymentFormGroup.value.isPrimary,
      billingInfo: this.addPaymentFormGroup.value
        .billingInfo as IPaymentMethodBillingInfo,
    });
  }
}
