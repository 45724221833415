import { Injectable, NgZone } from "@angular/core";
import { Country, ICountry } from "country-state-city";

@Injectable({
  providedIn: "root",
})
export class CountryLockupService {
  constructor(private ngZone: NgZone) {}

  getOneByCode(countryCode: string) {
    return this.ngZone.runOutsideAngular(() =>
      Country.getCountryByCode(countryCode)
    );
  }

  getAll() {
    return this.ngZone.runOutsideAngular(() => Country.getAllCountries());
  }

  sortByIsoCode(countries: ICountry[]) {
    return this.ngZone.runOutsideAngular(() =>
      Country.sortByIsoCode(countries)
    );
  }
}
