<div class="wrapper">
  <div class="header">
    <h2>
      {{
        "subscriptions.paymentStatus.title"
          | translate : { invoiceNo: invoice.invoiceNo }
      }}
    </h2>
  </div>

  <div class="status-content">
    <div class="container">
      <div
        [class]="['icon-container', status]"
        [style.background-color]="color"
      >
        <i
          [class]="['icon', 'fas', icon]"
          fillColor="white"
          width="50"
          height="50"
        ></i>
      </div>

      <b class="sub-title">
        {{ "subscriptions.paymentStatus." + status + ".title" | translate }}
      </b>
      <span class="hint">
        {{ "subscriptions.paymentStatus." + status + ".hint" | translate }}
      </span>
    </div>

    <div class="details">
      <app-payment-method-item
        *ngIf="paymentMethod"
        [paymentMethod]="paymentMethod"
        [showViewButton]="isRequiredPayment"
        (onViewPaymentMethod)="openSelectPaymentMethod()"
      ></app-payment-method-item>

      <div class="item">
        <span class="total-amount">{{
          "subscriptions.paymentStatus.totalAmount" | translate
        }}</span>
        {{ amount.amount | currency : amount.currency : "symbol" : "1.0-2" }}
      </div>

      <div class="actions">
        <sxw-button
          class="action"
          theme="sxw-theme"
          *ngIf="!paymentMethod && isRequiredPayment && paymentMethods?.length"
          label="subscriptions.actions.selectPaymentMethod"
          (onClick)="openSelectPaymentMethod()"
        ></sxw-button>

        <sxw-button
          *ngIf="!paymentMethod && isRequiredPayment"
          theme="sxw-theme"
          class="action"
          (onClick)="openPaymentForm()"
          label="subscriptions.actions.pay"
          [isLoading]="isPayLoading"
        ></sxw-button>

        <sxw-button
          *ngIf="paymentMethod && isRequiredPayment"
          theme="sxw-theme"
          class="action"
          (onClick)="
            confirmPayment(
              status === ePaymentIntentStatus.RequiresConfirmation &&
                originalPaymentMethod?.id === paymentMethod.id
            )
          "
          [label]="
            ePaymentIntentStatus.RequiresConfirmation &&
            originalPaymentMethod?.id === paymentMethod.id
              ? 'subscriptions.actions.confirm'
              : 'subscriptions.actions.pay'
          "
          [isLoading]="isPayLoading"
        ></sxw-button>

        <a class="link" routerLink="/" [replaceUrl]="true">
          {{ "subscriptions.actions.goToDashboard" | translate }}
        </a>
      </div>
    </div>
  </div>

  <app-select-payment-method-popup
    [visible]="!!isRequiredPayment && selectPaymentMethodIsOpened"
    (visibleChange)="selectPaymentMethodIsOpened = $event"
    [defaultBillingInfo]="myBillingAccount"
    [(addPaymentMethodView)]="addPaymentMethodIsOpened"
    [(setupClientSecret)]="setupClientSecret"
    [(ngModel)]="paymentMethod"
    [paymentMethods]="paymentMethods"
    [submitLabel]="'paymentMethod.buttons.selectPaymentMethod' | translate"
    [setupClientSecretObservable]="setupClientSecretObservable"
    (onAddPaymentMethod)="savePaymentMethod($event)"
    (onSubmit)="closeSelectPaymentMethod()"
  >
  </app-select-payment-method-popup>
  <sxw-side-dialog
    [(visible)]="paymentFormIsOpened"
    outletName="popups"
    theme="sxw-popup-cards"
  >
    <h2>
      {{ "subscriptions.paymentTitle" | translate }}
    </h2>
    <app-add-payment-method-form
      *ngIf="paymentClientSecret"
      [clientSecret]="paymentClientSecret"
      (afterSubmitSuccess)="handleAfterPaymentSubmission($event)"
      mode="payment"
      submitLabel="subscriptions.actions.completePayment"
      [defaultBillingInfo]="myBillingAccount"
    ></app-add-payment-method-form>
  </sxw-side-dialog>
</div>
