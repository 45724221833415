import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import validatorJS from 'validator';
import * as customValidators from '../helpers/custom-sync-validators';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor() {
    this.initExtraValidators();
  }
  /**
   * Add isNotEmpty Validator
   */
  initExtraValidators() {
    (validatorJS as any).isNotEmpty = (value: string, options: any) =>
      !validatorJS.isEmpty(value, options);
  }
  /**
   * Use with Reactive Form to add custom validations
   * @param control
   * @param validations
   * @returns
   */
  validate(
    control: AbstractControl | string,
    validations: any
  ): ValidationErrors | null {
    return this.validatorHandler(control, validations);
  }

  /**
   * Handle Validation with validator package
   * @param control
   * @param validations
   */
  validatorHandler(
    control: AbstractControl | string,
    validations: any
  ): ValidationErrors | null {
    let error: ValidationErrors | null = null;
    for (let v in validations) {
      if(v === "$or" && Array.isArray(validations[v])) {
        return this.handleOrRule(control,validations[v]);
      }

      if (validations[v]?.active === false) {
        continue;
      }
      // if (!(['isEmpty', 'isNotEmpty'].includes(v)) && !this.prepareValue(control)) { continue; }
      if ((customValidators as any)[v]) {
        error = (customValidators as any)[v](
          this.prepareValue(control),
          validations[v].options,
          validations[v]?.errorOptions
        );
        if (!error) {
          continue;
        }
        return error;
      } else {
        if (
          !(validatorJS as any)[v](
            this.getValue(
              typeof control === 'string' ? control : control?.value
            ),
            validations[v].options
          )
        ) {
          if (
            ['isNotEmpty', 'isEmpty'].indexOf(v) === -1 &&
            !this.prepareValue(control)
          ) {
            return null;
          }
          return { [v]: validations[v].msg || v };
        }
      }
    }
    return error;
  }

  private prepareValue(control: any) {
    return control instanceof AbstractControl ? control.value : control;
  }

  /**
   * Transform value of control
   * @param value
   */
  private getValue(value: any) {
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === "boolean") {
      return String(value);
    }
    if (typeof value === 'number' || value instanceof Date) {
      return value.toString();
    }
    if (value === null || undefined) {
      return '';
    }
    if (typeof value === 'object' || Array.isArray(value)) {
      return Object.keys(value).length || value.length
        ? JSON.stringify(value)
        : '';
    }
    return '';
  }

  /**
   * Handle array of validations using the "OR logic".
   * @param control
   * @param validations
   */
  private handleOrRule(control: AbstractControl | string, validations: unknown[]){
    let error: ValidationErrors | null = null;
    for (const validation of validations){
      const validationError = error = this.validatorHandler(control, validation);
      if(!validationError){
        break;
      }
    }
    return error;
  }
}
