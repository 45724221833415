import {SortGraphqlInput} from "./sort.graphql.input";
import {FiltersGraphqlInput} from "./filters.graphql.input";
import { SearchGraphqlInput } from "./search.graphql.input";
import {PaginationGraphqlInput} from "src/app/kernel/graphql/models/pagination.graphql.input";
import {GraphqlBaseInput} from "src/app/kernel/graphql/models/graphql-base.input";
import {FilterDataInterface} from "src/app/kernel/listing/interfaces/filters.interface";

export interface ListingGraphqlInputInterface {
  sort?: SortGraphqlInput | null;
  filters?: FiltersGraphqlInput | null;
  pagination?: PaginationGraphqlInput | null;
  search?: SearchGraphqlInput | null;
}
/**
 * Represents Graphql Listing Input
 */
export class ListingGraphqlInput extends GraphqlBaseInput {

  // init the default listing data
  listing: ListingGraphqlInputInterface = { sort: null,  filters: null, pagination: null, search: null }

  /**
   * @param filters
   * @param pagination
   * @param sort
   * @param search
   */
  constructor(
    filters?: FiltersGraphqlInput | null,
    pagination?: PaginationGraphqlInput | null,
    sort?: SortGraphqlInput | null,
    search?: SearchGraphqlInput | null
  ) {
    super();
    this.listing.filters = filters ;
    this.listing.sort = sort ;
    this.listing.pagination = pagination;
    this.listing.search = search;
  }


  /**
   * wraps the listing params inside {listing} object ot fit the backend requirements.
   * @param filters
   * @param pagination
   * @param sort
   * @param search
   */
  static wrap(
    filters: FiltersGraphqlInput | null = null,
    pagination: PaginationGraphqlInput | null = null,
    sort: SortGraphqlInput | null = null,
    search: SearchGraphqlInput | null = null,
  ): ListingGraphqlInput {
    // create the listing input object.
    const obj = new ListingGraphqlInput(
      filters?.filters ? filters : null,
      pagination?.page ? pagination : null,
      sort?.field ? sort : null,
      search?.fields.length ? search : null
    );
    // add the params def
    obj.addParamsDef({listing: 'ListingInput'});
    return obj;
  }

}
