  import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxHttpLoaderModule } from 'ngx-http-loader';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './chatperk-core/interceptors/JwtInterceptor';
// ===================== SUB APPLICATIONS { =====================
import { CoreModule } from 'src/app/core/core.module';
import { MessageModule } from 'src/app/kernel/tools/message/message.module';
import { DashboardModule } from "src/app/applications/dashboard/dashboard.module";
import { AuthenticationModule } from "src/app/applications/authentication/authentication.module";
import { AuthService } from './chatperk-core/services/auth.service';
import { AuthService as BaseAuthService } from './kernel/tools/services/auth.service';
import { IntegrationRedirectModule } from './applications/integration-redirect/integration-redirect.module';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { CurrencyPipe } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
// ===================== } SUB APPLICATIONS =====================

@NgModule({
  declarations: [AppComponent],
  imports: [
    // ================== Angular Defaults { ====================
    BrowserModule,
    AppRoutingModule,
    // ================== } Angular Defaults ====================

    // ===================== CORE MODULE { ======================
    MessageModule,
    CoreModule,
    // ===================== } CORE MODULE ======================

    // =================== SUB APPLICATIONS { ===================
    DashboardModule,
    AuthenticationModule,
    IntegrationRedirectModule,
    // =================== } SUB APPLICATIONS ===================

    NgxHttpLoaderModule.forRoot(),
    NgxStripeModule.forRoot(environment.STRIP_PUBLISHABLE_KEY),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: BaseAuthService,
      useClass: AuthService
    },
    CurrencyPipe,
    DialogService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
