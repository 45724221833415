<div class="{{ theme }} input-control">
  <label *ngIf="label" class="input-control__label">{{
    label | translate
  }}</label>
  <span class="p-input-icon-right full-width-input">
    <i class="pi pi-spin pi-spinner" *ngIf="control?.status === 'PENDING'"></i>
    <input
      type="text"
      [sxwRestrictInput]="constrains"
      [class]="'full-width-input ' + inputClasses"
      pInputText
      placeholder="{{ placeHolder | translate }}"
      [(ngModel)]="value"
      (blur)="onTouched()"
      (input)="onTouched()"
      (ngModelChange)="onInputChange()"
      [disabled]="disabled"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [ngClass]="{
        'invalid-input':
          control &&
          control.errors &&
          (control.touched || sxwForm?.submitted)
      }"
      [readOnly]="readonly"
    />
  </span>
  <sxw-error-message
    *ngIf="
      control &&
      control.errors &&
      (control.touched || sxwForm?.submitted)
    "
    [message]="control ? control.errors : null"
  ></sxw-error-message>
</div>
