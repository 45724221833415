import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
} from "@angular/core";
import {FormArrayName, FormControlName, FormGroupName, NgModel} from "@angular/forms";
import { FormLabelDirective } from "../../directives/form-label/form-label.directive";
import { Subject, takeUntil } from "rxjs";
import { FormStepperComponent } from "src/app/kernel/form/modules/tools/form-stepper/components/form-stepper/form-stepper.component";

@Component({
  selector: "sxw-step",
  templateUrl: "./sxw-step.component.html",
  styleUrls: ["./sxw-step.component.scss"],
})
export class SxwStepComponent
  implements AfterContentInit, OnDestroy, OnChanges
{
  /* ------------ Controls ---------------- */
  @ContentChildren(FormControlName, {
    read: FormControlName,
    descendants: true,
  })
  private formControls?: QueryList<FormControlName>;

  @ContentChildren(FormArrayName, {
    read: FormArrayName,
    descendants: true,
  })
  private formArrayControls?: QueryList<FormArrayName>;

  @ContentChildren(NgModel, {
    descendants: true,
  })
  private ngModels!: QueryList<NgModel>;

  /* ------------ -------- ---------------- */

  @ContentChildren(FormLabelDirective, {
    read: FormLabelDirective,
    descendants: true,
  })
  private labels?: QueryList<FormLabelDirective>;

  public stepSummaryData: FormLabelDirective[] | undefined = undefined;

  @Input() public theme = "sxw-theme";
  @Input() public stepName = "";
  @Input() public stepCustomName = "";
  @Input() public stepIcon = "";
  @Input() public hidden = false;
  // Used to show or hide the step in summary regardless of the hidden value or not show the step immediately when it's active
  @Input() public hideInSummary = false;

  active = false;
  done = false;
  moveEnabled = false;
  visited: boolean = false;
  parent?: FormStepperComponent;

  private _unSubscribe = new Subject<void>();

  get element(){
    return this.elementRef.nativeElement;
  }

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit() {
    this.getStepSummaryData();
  }

  getStepSummaryData() {
    this.stepSummaryData = this.labels?.toArray();
    this.labels?.changes
      .pipe(takeUntil(this._unSubscribe))
      .subscribe((labels) => {
        this.stepSummaryData = labels?.toArray();
      });
  }

  /**
   * check validation for every control
   * @private
   */
  public valid(touch = true): boolean {
    // Gather all form elements in a unified array
    const formElements: any[] = [
      ...(this.formControls?.toArray() ?? []),...(this.formArrayControls?.toArray() ?? []), ...(this.ngModels?.toArray() ?? [])
    ];

    // Return validity flag
    return formElements.reduce((acc, control) => {
      // Mark the control and all its child controls as touched if touch is true
      if (touch) {
        this.markControlAsTouched(control)
      }
      // Update the valid flag based on the control's validity
      return acc && !control.invalid && !control.pending;
    }, true);
  }

  /**
   * Marks a control and all its child controls as touched
   * @param control
   */
  markControlAsTouched(control: any) {
    control.control?.markAllAsTouched();
    // Check if it's a nested FormGroup and mark its controls as touched
    if (control.valueAccessor?.formGroup) {
      Object.values(control.valueAccessor.formGroup.controls).forEach((c: any) => c.markAllAsTouched());
    }
  }

  setParent(parent: FormStepperComponent) {
    this.parent = parent;
  }

  setActive(state: boolean) {
    this.active = state;
  }

  setDone(state: boolean) {
    this.done = state;
  }

  setMoveEnabled(state: boolean){
    this.moveEnabled = state;
  }

  setVisited(state = true) {
    this.visited = state;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["hidden"]) {
      this.parent?.uploadLastStepIndex();
    }
    if (changes["hideInSummary"]) {
      this.done = !this.hideInSummary
      this.visited = !this.hideInSummary
    }
  }

  ngOnDestroy() {
    this._unSubscribe.next();
    this._unSubscribe.complete();
  }
}
