import { Injectable } from '@angular/core';
import { GraphService } from 'src/app/kernel/graphql/services/graph.service';
import { GqlResponseInterface } from 'src/app/kernel/graphql/interfaces/gql-response.interface';
import { BILLING_ACCOUNT_FORM_DATA_SHAPE } from '../pages/update-space/datashape/billing-account.datashape';
import { IBillingAccount } from 'src/app/chatperk-core/interfaces';

@Injectable()
export class BillingAccountService {
  constructor(private graphService: GraphService) {}

  getMyBillingAccount(dataShape: string = BILLING_ACCOUNT_FORM_DATA_SHAPE) {
    return this.graphService.constructQuery<
      GqlResponseInterface<IBillingAccount>
    >([dataShape], 'myBillingAccount');
  }
}
