import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupWrapperComponent } from './components/popup-wrapper/popup-wrapper.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [PopupWrapperComponent],
  imports: [ CommonModule, RouterModule ],
  exports: [PopupWrapperComponent]
})
export class PopupWrapperModule { }
