import { Injectable } from '@angular/core';
import { DialogService } from "primeng/dynamicdialog";
import {
  ConfirmationDialogComponent
} from "../../components/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationPopupInterface } from "../../interfaces/confirmation-popup";
import { Subscription } from "rxjs";
import { DynamicDialogConfig } from "primeng/dynamicdialog/dynamicdialog-config";
import { AlertPopupInterface } from '../../interfaces/alert-popup';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private subscription!: Subscription;
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService
  ) { }

  /**
   * @param confirmationOptions
   * */
  confirm(confirmationOptions?: ConfirmationPopupInterface): Promise<boolean> {
    // Default Options
    const options = <ConfirmationPopupInterface>{
      header: 'areYouSure?',
      message: '',
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel',
      width: '450px',
      height: 'auto',
      ...confirmationOptions
    }
    return this.open<boolean>({
      data: {
        message: options.message,
        acceptLabel: options.acceptLabel,
        rejectLabel: options.rejectLabel,
        acceptLabelColor: options.acceptLabelColor,
      },
      header: options.header,
      width: options.width,
      height: options.height,
    }, ConfirmationDialogComponent)
  }

  /**
   * @param dialogOptions options for the selected component
   * @param dialogComponent The dialog component to be called
   */
  open<T>(dialogOptions: DynamicDialogConfig, dialogComponent: any): Promise<T> {
    return new Promise((resolve, reject) => {
      if (dialogOptions.header) { dialogOptions.header = this.translateService.instant(dialogOptions.header); }
      this.subscription = this.dialogService.open(dialogComponent, dialogOptions).onClose.subscribe({
        next: (value) => {
          this.subscription.unsubscribe();
          return resolve(value);
        },
        error: (err: any) => {
          this.subscription.unsubscribe();
          return reject(err);
        },
        complete: () => {
          this.subscription.unsubscribe();
        }
      })
    })
  }


  alert(alertOptions?: AlertPopupInterface): Promise<boolean> {
    // Default Options
    const options = <AlertPopupInterface>{
      header: 'ok',
      width: '50%',
      height: 'auto',
      label: 'ok',
      ...alertOptions
    }
    return this.open({
      data: {
        label: options.label,
        message: options?.message
      },
      header: options.header,
      width: options.width,
      height: options.height
    }, AlertDialogComponent)
  }
}
