export const ADDON_BASE_DATA_SHAPE = `
  id
  isActive
  name
  basePrice {
    amount
    currency
  }
  description
  feature {
    key
    unlimitedUsage
    baseUsageLimit
    isAvailable
  }
`;
