<sxw-side-dialog
  outletName="popups"
  routeName="/account"
  theme="sxw-popup-cards"
>

<ng-container *ngIf="!isVerifyView" [ngTemplateOutlet]="emailView"></ng-container>

<ng-container *ngIf="isVerifyView" [ngTemplateOutlet]="verifyEmailView"></ng-container>

</sxw-side-dialog>

<ng-template #emailView>
  <h3 class="side-title">{{ "emailSide.emailAddress" | translate }}</h3>
  <sxw-text-input [formControl]="formControl" theme="sxw-theme"></sxw-text-input>
  <button class="save-button mt-20px" (click)="updateEmail()">{{"actions.saveChanges" | translate}}</button>
</ng-template>

<ng-template #verifyEmailView>
  <h3 *ngIf="isVerifyView">{{ "emailSide.emailCheck" | translate }}</h3>
  <p>{{'emailSide.verifiedSent' | translate}} <b>{{formControl.value}}</b></p>
  <app-otp-input class="mt-50px d-block" [formControl]="otpControl" [validations]="{isNotEmpty: {}}" [size]="6"></app-otp-input>

  <sxw-button class="mt-20px d-block" [label]="resendLabel" theme="secondary-button" [disabled]="disabled" [isLoading]="resendLoading"
    (onClick)="resendOtp()">
  </sxw-button>

  <div class="actions mt-10px">
    <sxw-button label="emailSide.ok" class="d-block" theme="sxw-theme" [disabled]="otpControl.invalid" [isLoading]="verifyLoading"
    (onClick)="verifyOtp()"></sxw-button>
  </div>
</ng-template>
