import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EUserType } from 'src/app/chatperk-core/enums';
import { AuthUserModel } from 'src/app/chatperk-core/models/AuthUser.model';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';
import { FormMutationInterface } from 'src/app/kernel/form/interfaces/form-mutation.interface';
import { UpdatePersonalInfoFormModel } from '../../models/update-personal-info-form.model';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { IUser } from 'src/app/chatperk-core/interfaces';
import { USER_DATASHAPE } from 'src/app/chatperk-core/data-shape';
import { SIDE_DIALOG_TOKEN } from 'src/app/kernel/popups/side-popup/tokens/side-dialog.token';
import { ISideDialog } from 'src/app/kernel/popups/side-popup/types/side-dialog.interface';
import { MutationResult } from 'apollo-angular';
import { RadioInputComponent } from 'src/app/kernel/form/modules/inputs/simple-inputs/components/radio-input/radio-input.component';
import { PopupService } from 'src/app/kernel/popups/popup/modules/confirmation-dialog/services/confirmation-popup/popup.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent {
  
  user! : AuthUserModel
  formModel! : UpdatePersonalInfoFormModel
  isLoading: boolean = false;
  userType = EUserType.Customer;
  @ViewChild(SIDE_DIALOG_TOKEN) sideDialog?: ISideDialog;
  @ViewChild(RadioInputComponent) switch2FA!: RadioInputComponent;


  
  constructor(
    private authService: AuthService,
    private translateService : TranslateService,
    private messageService: MessageService,
    private router: Router,
    private popupService: PopupService
  ){
    this.user = authService.getUser()!
    this.formModel = new UpdatePersonalInfoFormModel(this.user);
  }

  mutationConfig: FormMutationInterface = {
    name: 'updateMyProfile',
    paramName: 'profileUpdates',
    paramType: 'UpdateUserProfileInput!',
    select:[USER_DATASHAPE]
  }

  handleMutationComplete(event: any) {
    const updatedUser = event.data.updateMyProfile as IUser
    this.authService.updateUser(updatedUser)
    this.messageService.success("messages.updateAccountSuccess");
  }

  hundleMutatuonError(event: any){
    const msg = this.translateService.instant('errors.something_went_wrong');
    const summary = this.translateService.instant('errors.summary');
    this.messageService.error(msg, summary);
  }

  formatFormData(value: any): any {
    return {
      personalInfo: value
    }
  }

  toggle2FA(event: boolean){
    if (event === true) {
      this.enable2FA();
    }
    else{
      this.disable2FA();
    }
    
  }

  async enable2FA(){
    this.switch2FA.writeValue(false);
    const confirmed = await this.popupService.confirm({
      header: this.translateService.instant('general.enable2FAConfirm'),
       width:'32%',
    });
    if (!confirmed) return;

    this.authService
      .enable2FA()
      .subscribe({
        next: this.handleEnable2FAResponse.bind(this),
        error: this.handleReqErrors.bind(this),
      }); 
  }

  async disable2FA(){
    this.switch2FA.writeValue(true);
    const confirmed = await this.popupService.confirm({
      header: this.translateService.instant('general.disable2FAConfirm'),
       width:'32%',
    });
    if (!confirmed) return;
    this.authService
      .disable2FA()
      .subscribe({
        next: this.handleDisable2FAResponse.bind(this),
        error: this.handleReqErrors.bind(this),
      });
  }

  handleEnable2FAResponse({ data }: MutationResult<any>) {
    this.messageService.success('The verification OTP has been sent successfully!', 'Done');
    this.router.navigate(['/account', { outlets: { popups: ['2fa'] } }]);
  }

  handleDisable2FAResponse({ data }: MutationResult<any>) {
    this.switch2FA.writeValue(false);
    this.authService.updateUser(data!);
    this.messageService.success('The 2FA disabled successfully!', 'Done');
  }

  handleReqErrors(err: any) {
    const msg = this.translateService.instant('errors.something_went_wrong');
    const summary = this.translateService.instant('errors.summary');
    this.messageService.error(msg, summary);
  }

}
