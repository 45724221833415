import { Component, OnInit } from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { MessageService } from 'src/app/kernel/tools/message/service/message.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EPaymentsProvider } from 'src/app/chatperk-core/enums/EPaymentsProvider';
import { BackEndError } from 'src/app/chatperk-core/models/backend-error.model';
import { PaymentMethodsService } from 'src/app/applications/dashboard/modules/space/services/payment-methods.service';
import { IAfterSubmissionEmitEvent } from 'src/app/shared/payment-method/interfaces/payment-method.interfaces';
import { IBillingAccount } from 'src/app/chatperk-core/interfaces';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss'],
})
export class AddPaymentMethodComponent implements OnInit {
  public clientSecret!: string;
  public myBillingAccount?: IBillingAccount;

  constructor(
    public stripe: StripeService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private paymentService: PaymentMethodsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.data.subscribe(async (res) => {
      this.clientSecret = res['clientSecret']['data'];
      this.myBillingAccount = res['myBillingAccount'];
    });
  }

  savePaymentMethod(res: IAfterSubmissionEmitEvent) {
    this.paymentService
      .add({
        externalRef: {
          refId: res.setupIntentRes!.setupIntent!.payment_method!.toString(),
          provider: EPaymentsProvider.Stripe,
        },
        billingInfo: res.billingInfo,
        isPrimary: res.isPrimary,
      })
      .subscribe({
        next: () => {
          this.messageService.success(
            this.translateService.instant('paymentMethod.addedSuccessfully'),
            this.translateService.instant('messages.done')
          );
          this.router.navigateByUrl('/space/settings/billing');
        },
        error: (error: BackEndError) => {
          const msg = this.translateService.instant(
            error.graphQLErrors[0].extensions.formatted?.data.messageKey ??
              'errors.something_went_wrong'
          );
          const summary = this.translateService.instant('errors.summary');
          return this.messageService.error(msg, summary);
        },
      });
  }
}
