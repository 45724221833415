import { ValidatorsOptionsInterface } from "../../interfaces/validators-options.interface";

export function isRange(
  value: string,
  options: any,
  errorOptions?: ValidatorsOptionsInterface | any
): Record<string, ValidatorsOptionsInterface> | null {
  if (
    (!value && typeof value !== "number") ||
    (value >= (options.min ?? -Infinity) && value <= (options.max ?? Infinity))
  ) {
    return null;
  }
  return {
    isRange: {
      message: value < options.min ? errorOptions?.min ?? "isRangeMax" :  errorOptions?.max ?? "isRangeMin",
      comparisonFieldName: value < options.min?options.min :options.max ,
    },
  };
}
