import { ValidatorsOptionsInterface } from "../../interfaces/validators-options.interface";
export function isArrayRange(
  value: any[],
  options: any,
  errorOptions?: ValidatorsOptionsInterface | any
): Record<string, ValidatorsOptionsInterface> | null {
  if (
    !value || !value.length ||
    (value.length >= (options.min ?? -Infinity) &&
      value.length <= (options.max ?? Infinity))
  ) {
    return null;
  }
  return {
    isRange: {
      message:
        value.length < options.min
          ? errorOptions?.min || "isArrayRangeMin"
          : errorOptions?.max || "isArrayRangeMax",
      comparisonFieldName: value.length < options.min ? options.min : options.max,
    },
  };
}
