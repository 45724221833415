import validator from "validator";

export function isTaxIDCompany(value: string, options: string) {
  if(!value) {
    return true;
  }

  if(value?.length === 11 && isNaN(+value)) {
    return {isNumeric: {message: 'isNumeric'}};
  }

  const validTaxID = validator.isTaxID(value, options?? 'it-IT');
  if (value?.length !== 11 && !validTaxID) {
    return {isTaxID: {message: 'isTaxID'}};
  }

  return true;
}
