export const PLAN_BASE_DATA_SHAPE = `
  id
  isActive
  isPopular
  name
  visualColor
  basePrice {
    amount
    currency
  }
  description
  features {
    key
    unlimitedUsage
    baseUsageLimit
    isAvailable
  }
`;
