import {Component, Input, OnInit} from '@angular/core';
import {ISideNavConfig, ISideNavGroupConfig} from "src/app/layout/sidenav/interfaces/ISideNavConfig";
import {ActivatedRoute} from "@angular/router";
import {UsageOverviewService} from "src/app/shared/metrics-shared/services/usage-overview.service";
import {Subject, takeUntil} from "rxjs";
import {EFeatureKey} from "src/app/chatperk-core/enums/EFeatureKey";
import {UsageOverviewInterface} from "src/app/shared/metrics-shared/interfaces/usage-overview-interface";
import {ISubscription} from "src/app/chatperk-core/interfaces";
import { SubscriptionService } from 'src/app/shared/subscription-shared/services/subscription.service';
import { ESubscriptionStatus } from 'src/app/chatperk-core/enums/ESubscriptionStatus';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() sideNavConfig: ISideNavConfig = { items: [] };
  private unsubscribeAll: Subject<void> = new Subject<void>();

  readonly eSubscriptionStatus = ESubscriptionStatus;
  totalsOverview?: UsageOverviewInterface ;
  subscription: ISubscription | null = null ;

  constructor(
    public activatedRoute: ActivatedRoute,
    public usageOverviewService: UsageOverviewService,
    public subscriptionService: SubscriptionService
  ) {
  }

  ngOnInit() {
    this.updateSubscription(null);
    this.subscriptionService.observeActiveSubscription().pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (subscription) => this.updateSubscription(subscription)
    })
  }

  updateSubscription(subscription: ISubscription | null) {
    this.subscription = subscription ;
    this.totalsOverview = {
      messages: this.subscriptionService.getActiveSubscriptionFeature(EFeatureKey.MessageCredit)?.baseUsageLimit ?? 20,
      assistants: this.subscriptionService.getActiveSubscriptionFeature(EFeatureKey.ChatAssistant)?.baseUsageLimit ?? 1,
      spaceMembers: this.subscriptionService.getActiveSubscriptionFeature(EFeatureKey.SpaceMember)?.baseUsageLimit ?? 1
    }
  }

  isNavGroup(obj: {}): obj is ISideNavGroupConfig {
    return 'title' in obj;
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
