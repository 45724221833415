<sxw-side-dialog
  outletName="popups"
  [routeName]="routeName"
  theme="sxw-popup-cards"
>

<ng-container *ngIf="!isVerifyView" [ngTemplateOutlet]="mobileView"></ng-container>

<ng-container *ngIf="isVerifyView" [ngTemplateOutlet]="verifyMobileView"></ng-container>

</sxw-side-dialog>

<ng-template #mobileView>
  <h3 class="side-title">{{ "mobileSide.mobilePhone" | translate }}</h3>
  <sxw-mobile-number
    [formControl]="formControl"
    theme="input-theme"
    [validations]="{isNotEmpty:{}}"
  ></sxw-mobile-number>

  <button class="save-button mt-20px" (click)="updateMobileNumber()">{{"actions.saveChanges" | translate}}</button>
</ng-template>

<ng-template #verifyMobileView>
  <h3 *ngIf="isVerifyView">{{ "mobileSide.mobileCheck" | translate }}</h3>
  <p>{{'mobileSide.verifiedSent' | translate}} <b>{{formControl.value}}</b></p>
  <app-otp-input class="mt-50px d-block" [formControl]="otpControl" [validations]="{isNotEmpty: {}}" [size]="6"></app-otp-input>

  <sxw-button class="mt-20px d-block" [label]="resendLabel" theme="secondary-button" [disabled]="disabled" [isLoading]="resendLoading"
    (onClick)="resendOtp()">
  </sxw-button>

  <div class="actions mt-10px">
    <sxw-button label="mobileSide.ok" class="d-block" theme="sxw-theme" [disabled]="otpControl.invalid" [isLoading]="verifyLoading"
    (onClick)="verifyOtp()"></sxw-button>
  </div>
</ng-template>

