import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Host,
  HostBinding,
  Input,
  Optional,
  Output,
  TemplateRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { CustomInput } from "src/app/kernel/form/classes/custom-input";
import { SxwFormComponent } from "src/app/kernel/form/components/sxw-form/sxw-form.component";
import { IRadioLabelTemplateContext, IRadioSelection } from "./radio.interfaces";

@Component({
  selector: "sxw-radio[items][labelKey]",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent<
  TItem extends object,
  TLabelKey extends keyof TItem
> extends CustomInput {
  @Input() @HostBinding("class") hostClass?: string | string[];
  @Input() itemClass?: string | string[];
  @Input() items!: TItem[];
  @Input() labelKey!: TLabelKey;
  @Input() valueKey?: keyof TItem;
  @Input() customSummaryFn?: TCustomSummaryFn<TItem, TLabelKey>;
  @Input() imageMode = false;
  @Input() theme = "";
  @Output() change = new EventEmitter();
  @ContentChild(TemplateRef) labelTemplate?: TemplateRef<
    IRadioLabelTemplateContext<TItem, TLabelKey>
  >;

  protected groupName = Math.random().toString(32).slice(2);
  protected radioSelection?: IRadioSelection<TItem, TLabelKey> | null;

  @HostBinding("class.customized-radio")
  get isCustomizedRadio() {
    return !!this.labelTemplate;
  }

  override get summaryValue(): null | string | string[] {
    if (!this.radioSelection) {
      return null;
    }
    if (this.customSummaryFn) {
      return this.customSummaryFn.call(this, this.radioSelection);
    }
    if (typeof this.radioSelection.label === "string") {
      return this.radioSelection.label;
    }
    return null;
  }

  constructor(@Optional() @Host() public sxwForm?: SxwFormComponent) {
    super();
  }

  override writeValue(value: unknown | undefined | null): void {
    this.updateRadioSelection(value);
    this.value = this.radioSelection?.value;
    if (value != this.value) {
      this.onChange(this.value);
    }
  }

  updateValue(value: unknown, radioSelection?: TItem | null) {
    this.updateRadioSelection(value, radioSelection);
    value = this.radioSelection?.value;
    this.value = value;
    this.onTouched();
    this.onChange(value);
    this.change.emit(value);
  }

  private updateRadioSelection(
    value: unknown | undefined | null,
    itemToSelect?: TItem | null
  ) {
    if (value == null) {
      this.radioSelection = null;
    } else if (
      itemToSelect != null &&
      this.getValueOf(itemToSelect) === value
    ) {
      this.radioSelection = this.createRadioSelection(itemToSelect);
    } else {
      const valueToMatch = JSON.stringify(value);
      const item = this.items.find(
        (item) => JSON.stringify(this.getValueOf(item)) === valueToMatch
      );
      this.radioSelection = item && this.createRadioSelection(item);
    }
  }

  private createRadioSelection(item: TItem): IRadioSelection<TItem, TLabelKey> {
    return {
      item,
      label: item[this.labelKey],
      value: this.getValueOf(item),
    };
  }

  getValueOf(item: TItem) {
    return this.valueKey ? item[this.valueKey] : item;
  }
}

type TCustomSummaryFn<TItem, TLabelKey extends keyof TItem> = (
  item: IRadioSelection<TItem, TLabelKey>
) => string | string[];
