import {
  Component,
  EventEmitter,
  forwardRef, Host,
  Input, Optional,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomInput } from 'src/app/kernel/form/classes/custom-input';
import {SxwFormComponent} from "src/app/kernel/form/components/sxw-form/sxw-form.component";

@Component({
  selector: 'sxw-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioInputComponent),
      multi: true,
    }
  ],
})
export class RadioInputComponent extends CustomInput<boolean> {
  @Input() override value!: boolean;
  @Input() theme = 'sxw-theme';
  @Output() change = new EventEmitter();

  override get summaryValue(): any {
    return this.value ? 'Yes' : 'No';
  }
  constructor(@Optional() @Host() public sxwForm?: SxwFormComponent) {
    super();
  }

  /**
   * Override the default value accessor to handle the value as a boolean
   * @param value the value
   */
  override writeValue(value: any): void {
    this.value = !!value;
  }

  /**
   * Handle click on the dummy input
   */
  clicked() {
    this.value = !this.value;
    this.onChange(this.value);
    this.change.emit(this.value);
  }
}
