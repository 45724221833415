import { toDate } from '../../../../../../shared/helpers/date-helper/date.helper';
import { ValidatorsOptionsInterface } from "../../interfaces/validators-options.interface";

export function isAfterDate(str: string, date: string, options?: ValidatorsOptionsInterface):
  Record<string, ValidatorsOptionsInterface> | null {
  if(!date || !str) { return null; }
  const comparison = toDate(date);
  const original = toDate(str);
  return (original && comparison && original > comparison) ? null :
    { isAfterDate: {
      message: options?.message || 'isAfterDate',
      comparisonFieldName: options?.comparisonFieldName || ''
    }
  };
}
