<div class="{{theme}} input-control">
  <span class="input-control__label" *ngIf="label">{{ label | translate }}</span>
  <p-password
    [placeholder]="placeHolder | translate"
    [toggleMask]="toggleMask"
    [feedback]="feedback"
    [(ngModel)]="value"
    (blur)="onTouched()"
    (input)="onTouched()"
    (ngModelChange)="onChange($event)"
    [disabled]="readonly || disabled"
    [ngClass]="{'invalid-input': control && control.errors && (control.touched || sxwForm?.submitted)}"
    styleClass="full-width-input"
  ></p-password>
  <sxw-error-message
    *ngIf="control && control.errors && (control.touched || sxwForm?.submitted)"
    [message]="control? control.errors : null"
  ></sxw-error-message>
</div>

