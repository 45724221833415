<sxw-side-dialog
  outletName="popups"
  [routeName]="routeName"
  theme="sxw-popup-cards"
>

<h3 class="side-title">{{ "mobileSide.mobileCheck" | translate }}</h3>
<p>{{'mobileSide.verifiedSent' | translate}} <b>{{user?.mobile}}</b></p>
<app-otp-input class="mt-50px d-block" [formControl]="otpControl" [validations]="{isNotEmpty: {}}" [size]="6"></app-otp-input>

<sxw-button class="mt-20px d-block" [label]="resendLabel" theme="secondary-button" [disabled]="disabled" [isLoading]="resendLoading"
  (onClick)="resendOtp()">
</sxw-button>

<div class="actions mt-10px">
  <sxw-button label="mobileSide.ok" class="d-block" theme="sxw-theme" [disabled]="otpControl.invalid" [isLoading]="verifyLoading"
  (onClick)="verifyOtp()"></sxw-button>
</div>

</sxw-side-dialog>



