import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Sentry.init({
//   dsn: 'https://fb036e33d585368c73bf0db705e6c058@o1082351.ingest.sentry.io/4506185338912768',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       /**
//        * Due to this issue: https://github.com/getsentry/sentry-javascript/issues/6946.
//        * Reply has side effects that make zone.js detects them and make angular run lifecycle hooks infinitely,
//        * Tried to upgrade the version of sentry package but, that is not help as documnted here
//        * https://github.com/getsentry/sentry-javascript/issues/6946#issuecomment-1885127282
//        * so added mutationLimit property: can check this link:
//        * https://docs.sentry.io/platforms/javascript/guides/angular/session-replay/configuration/
//        */
//       mutationLimit: 1500,
//     }),
//   ],
//   environment: environment.production ? 'production' : 'development',
//   tracePropagationTargets: ['https://rentalab-graph.storexweb.com'],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
