import { PLAN_BASE_DATA_SHAPE } from 'src/app/chatperk-core/data-shape/plan.datashape';
import { ADDON_BASE_DATA_SHAPE } from 'src/app/chatperk-core/data-shape/addon.datashape';
import { PAYMENT_METHOD_FULL_DATA_SHAPE } from 'src/app/chatperk-core/data-shape/payment-method.datashape';
import { INVOICE_PAYMENT_STATUS_DATA_SHAPE } from 'src/app/applications/dashboard/modules/space/datashape/invoice.datashape';

export const SUBSCRIBE_BASE_DATA_SHAPE = `
  id
  lastInvoice {
    ${INVOICE_PAYMENT_STATUS_DATA_SHAPE}
  }
  status
  pendingUpdates {
    paymentIntent {
      status
      clientSecret
      description
      amount {
        amount
        currency
      }
      externalRef {
        provider
        refId
      }
      lastPaymentError {
        code
        declineCode
        message
      }
      paymentMethod { 
        ${PAYMENT_METHOD_FULL_DATA_SHAPE}
      }
    }
  }
`;

export const SUBSCRIBE_RESULT_DATA_SHAPE = `
  subscription { 
    ${SUBSCRIBE_BASE_DATA_SHAPE}
  }
  checkoutUrl
`;

export const SUBSCRIPTION_BASE_DATA_SHAPE = `
  id
  planSnapshot { ${PLAN_BASE_DATA_SHAPE} }
  paymentMethod { ${PAYMENT_METHOD_FULL_DATA_SHAPE} }
  addons {
    addonSnapshot {
      ${ADDON_BASE_DATA_SHAPE}
    }
    qty
  }
  aggregatedFeatures {
    key
    unlimitedUsage
    baseUsageLimit
    isAvailable
  }
  monthsDuration
  duration
  status
  scheduled {
    planSnapshot { ${PLAN_BASE_DATA_SHAPE} }
    addons {
      addonSnapshot {
        ${ADDON_BASE_DATA_SHAPE}
      }
      qty
    }
    aggregatedFeatures {
      key
      unlimitedUsage
      baseUsageLimit
      isAvailable
    }
    monthsDuration
    duration
    applyAt
  }
`;

export const CANCEL_SUBSCRIPTION_RESULT_DATA_SHAPE = `
  subscription { 
    ${SUBSCRIPTION_BASE_DATA_SHAPE}
  }
`;
