import { Component, forwardRef, Host, Input, Optional } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { CustomInput } from "src/app/kernel/form/classes/custom-input";
import {SxwFormComponent} from "src/app/kernel/form/components/sxw-form/sxw-form.component";

@Component({
  selector: "sxw-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    }
  ],
})
export class TextInputComponent extends CustomInput<string> {
  @Input() label!: string;
  // A regex that is passed to sxwRestrictInput directive to restrict user input
  @Input() constrains!: string;
  @Input() placeHolder = "";
  @Input() inputClasses = "";
  @Input() maxlength: number | null = null;


  @Input() theme = "sxw-theme";
  @Input() override value: string | null | undefined

  constructor(@Optional() @Host() public sxwForm?: SxwFormComponent) {
    super();
  }

  onInputChange() {
    this.value = this.value?.trim() ? this.value : this.value?.trim();
    this.onTouched();
    this.onChange(this.value);
  }
  
}
