import {
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CustomInput } from "../../../../../classes/custom-input";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  getStaticTime,
  getStaticTimeWithSeconds,
} from "src/app/kernel/shared/helpers/date-helper/date.helper";
import { Icon } from "src/app/kernel/tools/svg-icon/enums/icon.enum";
import { SxwFormComponent } from "src/app/kernel/form/components/sxw-form/sxw-form.component";

@Component({
  selector: "sxw-time-picker-input",
  templateUrl: "./time-picker-input.component.html",
  styleUrls: ["./time-picker-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerInputComponent),
      multi: true,
    },
  ],
})
export class TimePickerInputComponent
  extends CustomInput<any>
  implements OnChanges
{
  @Input() readonlyInput: boolean = true;
  @Input() override readonly: boolean = false;
  @Input() placeholder: string = "";
  @Input() isFilter: boolean = true;
  @Input() showIcon: boolean = false;
  @Input() icon: string | Icon = "clock";
  @Input() theme: string = "sxw-theme";
  @Input() startHours?: number;
  @Input() startMinutes?: number;
  @Input() withSeconds: boolean = false;
  @Input() showClear = true;
  @Input() filterMatchMode: string = "contains";
  @Output() onDropDownChange = new EventEmitter<any>();

  private currentStartHours?: number;
  private currentStartMinutes?: number;
  private timeInitialized = false;

  time: Array<object> = [];

  constructor(@Optional() @Host() public sxwForm?: SxwFormComponent) {
    super();
  }

  ngOnInit() {
    if (!this.timeInitialized) {
      this.generateTimes(0, 0);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["startHours"] || changes["startMinutes"]) {
      this.generateTimes(
        changes["startHours"]?.currentValue ?? 0,
        changes["startMinutes"]?.currentValue ?? 0
      );
    }
  }

  private generateTimes(startHours: number, startMinutes: number) {
    if (
      this.currentStartHours === startHours &&
      this.currentStartMinutes === startMinutes
    ) {
      return;
    }
    this.currentStartHours = startHours;
    this.currentStartMinutes = startMinutes;
    this.time = this.withSeconds
      ? getStaticTimeWithSeconds(startHours, startMinutes)
      : getStaticTime(startHours, startMinutes);
    this.timeInitialized = true;
  }

  onValueChange($event: any) {
    this.onTouched();
    this.onChange($event);
    this.onDropDownChange.emit($event);
  }
}
