import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { IntegrationRedirectRoutingModule } from './integration-redirect-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IntegrationRedirectRoutingModule
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
})
export class IntegrationRedirectModule { }
